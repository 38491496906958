import React from "react";
import { Link } from "react-router-dom";
import { DEFAULT_CURRENCY } from "../../../constants";

function ResolutionConversationHeader({
  product,
  seller,
  status,
  handleVisitShop,
  reason,
}) {
  return (
    <div className="gx-card p-4 mb-4">
      <h6 className="mb-3">Product Details</h6>
      <div className="gx-media gx-featured-item details-card-jr">
        <div className="gx-featured-thumb">
          <img
            className="gx-rounded-lg"
            style={{ width: "80px" }}
            src={product && product.image}
            alt="..."
          />
        </div>
        <div className="gx-media-body gx-featured-content">
          <div className="gx-featured-content-left" style={{ width: "100%" }}>
            <p className=" gx-mb-1">
              <b>Seller : </b>
              <Link
                className="mb-0 crd-fnt-jr"
                onClick={() => handleVisitShop(seller.id)}
              >
                {seller && seller.name}
              </Link>
            </p>
            <p className=" gx-mb-1">
              <b>Product : </b>
              <Link className="crd-fnt-jr" to={`/product/${product.id}`}>
                {product && product.name}
              </Link>
            </p>

            <p className=" gx-mb-0">
              <b>Reason : </b>
              <span>{reason}</span>
            </p>
            <p className=" gx-mb-0">
              <b>Dispate Status : </b>
              {status === "Open" ? (
                <span className="fnshed-clr">Open</span>
              ) : status === "Closed" ? (
                <span className="deliv-clr">Closed</span>
              ) : (
                <span className="cancle-clr">Admin</span>
              )}
            </p>
          </div>
          <div className="gx-featured-content-right">
            <div className="addmgnlft " style={{ height: "100%" }}>
              <table style={{ height: "100%" }}>
                <tr>
                  <td>
                    <h2
                      className="gx-mb-1 gx-font-weight-medium"
                      style={{ color: "#ff6900" }}
                    >
                      {`${DEFAULT_CURRENCY}${product?.price}`}
                    </h2>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResolutionConversationHeader;
