import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Drawer, Layout } from "antd";
import SidebarContent from "./SidebarContent";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "../../../constants/ThemeSetting";
import { cartActions } from "../../../modules/Cart/ducks";
import { authActions } from "../../../modules/Auth/ducks";

import { sellerSidebarSettings } from "constants/Utilities";

const { Sider } = Layout;
const { themeType, navStyle } = sellerSidebarSettings;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
    };
  }

  componentDidMount() {
    this.props.cartActions.settingsUpdate({ width: window.innerWidth });
    this.setState({ width: window.innerWidth });
  }

  componentDidUpdate(prevProps, prevState) {
    const { width } = this.state;
    if (width && window.innerWidth !== prevState.width) {
      window.addEventListener("resize", () => {
        this.props.cartActions.settingsUpdate({ width: window.innerWidth });
        this.setState({ width: window.innerWidth });
        // dispatch(updateWindowWidth(window.innerWidth));
      });
    }
  }

  onToggleCollapsedNav = () => {
    // dispatch(toggleCollapsedSideNav(!navCollapsed));
    // dispatch(cartActions.sellerSidebarToggle());
    this.props.cartActions.sellerSidebarToggle();
  };
  render() {
    const { isVisibleSidebar, userJson, settingsUpdate } = this.props;
    const { width } = settingsUpdate;

    // window.addEventListener("resize", () => {
    //   this.props.cartActions.settingsUpdate({ width: window.innerWidth });

    //   // dispatch(updateWindowWidth(window.innerWidth));
    // });
    return (
      <Sider
        className={`gx-app-sidebar`}
        trigger={null}
        collapsed={
          width < TAB_SIZE
            ? false
            : navStyle === NAV_STYLE_MINI_SIDEBAR ||
              navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
        }
        theme={themeType === THEME_TYPE_LITE ? "lite" : "lite"}
        collapsible
      >
        {navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? (
          <Drawer
            className={`gx-drawer-sidebar ${
              themeType !== THEME_TYPE_LITE ? "gx-drawer-sidebar-lite" : null
            }`}
            placement="left"
            closable={false}
            onClose={this.onToggleCollapsedNav}
            // visible={navCollapsed}
            // visible={false}
            visible={isVisibleSidebar}
          >
            <SidebarContent userJson={userJson} />
          </Drawer>
        ) : (
          <SidebarContent userJson={userJson} />
        )}
      </Sider>
    );
  }
}

function mapStateToProps(state) {
  return {
    isVisibleSidebar: state.Cart.sellerSidebarToggle.isVisibleSidebar,
    settingsUpdate: state.Cart.settingsUpdate,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
