import React from "react";
import { InputNumber } from "antd";

// import "./QuantityManager.less";

export default function QuantityManager({
  quantityIncreaseHandler,
  quantityDecreaseHandler,
  selectedQuantity,
  available,
  quantityHandler,
  isEnabled,
  isCart,
}) {
  const inputQuentityHandler = (e) => {
    if (parseInt(available) > 0) {
      if (parseInt(e.target.value) > parseInt(available)) {
        e.target.value = available;
      } else if (e.target.value === "" || parseInt(e.target.value) === 0) {
        e.target.value = 1;
      }
    }
    e.preventDefault();
  };

  return (
    <div className="tr-quantity-manager">
      <form>
        <div>
          <button
            type="button"
            className="minus"
            onClick={() => quantityDecreaseHandler(isEnabled, available)}
            disabled={selectedQuantity === 1}
          >
            -
          </button>

          <InputNumber
            className="gx-mb-3 mr-0 input-style-jr"
            size="large"
            min={1}
            max={isCart ? parseInt(selectedQuantity) : parseInt(available)}
            defaultValue={1}
            value={selectedQuantity}
            onChange={(quantity) => quantityHandler(quantity, available)}
            disabled={!isEnabled}
            onPressEnter={(e) => inputQuentityHandler(e)}
          />
          <button
            type="button"
            className="plus"
            onClick={() => quantityIncreaseHandler(isEnabled, available)}
          >
            +
          </button>
        </div>
      </form>
    </div>
  );
}
