import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row, Tabs, Typography, Input, Spin, Form } from "antd";
import { orderManagementActions } from "../../../../modules/SellerDashboard/OrderManagement/ducks";
import { makeField } from "../../../Common/Controls/MakeField";
// import "./OrderTrackModel.less";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const { Paragraph } = Typography;
const { TabPane } = Tabs;

const FormItem = Form.Item;

const AInputField = makeField(Input);

class OrderTrackModelBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      rating_value: 0,
      content: "",
      order_id: props.orderID,
      select_shipping: "select your shipping method",
      shippingList: props.shippingAddressList,
      record: props.record,
      shippingAddress: props.shippingAddress,
    };
  }

  handleChange = (e) => {
    this.setState({ review: e.target.value });
  };

  handleSubmit = (values) => {
    const { record } = this.props;
    var { trackingNumber, carrier, carrierName, trackingUrl } = values;

    const orderForm = {
      tracking_code: trackingNumber,
      carrier: carrier ? carrier : "other",
      carrier_name: carrierName ? carrierName : "other",
      tracking_public_url: "https://" + trackingUrl,
    };

    this.props.orderManagementActions.orderTracking({
      orderId: record.objectID,
      orderForm: orderForm,
    });
  };

  downloadHandler = (label) => {
    window.open(label, "_blank");
    // this.download(e)
  };

  download = (e) => {
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.download = "image.jpg";
          // link.click();
        });
      })
      .catch((err) => {
        
      });
  };

  render() {
    const { isUpdate, shippingAddressList, shippingAddress, record } =
      this.props;
    const { handleSubmit, pristine, reset, submitting } = this.props;
    let shipmentId = record.shipping.shipment_id;
    let isCalculated = !(
      shipmentId === "flat_rated" || shipmentId === "free_shipping"
    );
    return (
      <React.Fragment>
        <Spin spinning={shippingAddressList.pending}>
          <div>
            <Tabs>
              {isCalculated ? (
                <TabPane tab="Get Shipping Label" key="1">
                  <React.Fragment>
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <p>
                          Download the following code and past it on your
                          parcel. Everything handled by the service provider
                          once you handover the parcel to the shipping service
                          provider.
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                        <div>Address :</div>
                      </Col>
                      <Col xl={17} lg={17} md={17} sm={24} xs={24}>
                        <div>
                          {shippingAddress &&
                            shippingAddress.address &&
                            shippingAddress.address.address1}{" "}
                          <br />
                          {shippingAddress &&
                          shippingAddress.address &&
                          shippingAddress.address.address2 !== null
                            ? shippingAddress.address.address2
                            : null}
                          <br />
                          {shippingAddress &&
                            shippingAddress.address &&
                            shippingAddress.address.city}{" "}
                          <br />
                          {shippingAddress &&
                            shippingAddress.address &&
                            shippingAddress.address.state}{" "}
                          <br />
                          {shippingAddress &&
                            shippingAddress.address &&
                            shippingAddress.address.country}{" "}
                          <br />
                          {shippingAddress &&
                            shippingAddress.address &&
                            shippingAddress.address.zip}{" "}
                          <br />
                        </div>
                      </Col>
                    </Row>

                    <div className="gx-algolia-no-results">
                      {shippingAddress &&
                      shippingAddress.tracking_url == null &&
                      !(
                        shippingAddressList.data &&
                        shippingAddressList.data.success
                      ) ? (
                        <div style={{ textAlign: "end" }}>
                          <Button
                            type="primary"
                            onClick={this.props.buttonClick}
                            className="mb-0 mr-0 mt-2"
                          >
                            {isUpdate ? (
                              <span>
                                <i className="fad fa-sync-alt mr-1"></i>
                                Update
                              </span>
                            ) : (
                              <span>
                                <i className="fad fa-barcode-alt mr-1"></i>
                                Get shipping Label
                              </span>
                            )}
                          </Button>
                        </div>
                      ) : null}

                      {shippingAddress && shippingAddress.tracking_url && (
                        <div
                          style={{
                            backgroundColor: "#f5f5f5",
                            padding: "10px",
                            borderRadius: "5px",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <div className="mb-2">
                            <b>Tracking Url :</b>
                          </div>

                          <Paragraph copyable className="inprogs-clr mb-2">
                            {this.props.shippingAddress.tracking_url}
                          </Paragraph>
                        </div>
                      )}
                    </div>
                    {shippingAddressList.data &&
                    shippingAddressList.data.success ? (
                      <React.Fragment>
                        <div
                          style={{
                            backgroundColor: "#f5f5f5",
                            padding: "10px",
                            borderRadius: "5px",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <div className="mb-2">
                            <b>Tracking Url :</b>
                          </div>

                          <Paragraph copyable className="inprogs-clr mb-2">
                            {shippingAddressList.data.data.tracking_url}
                          </Paragraph>
                        </div>
                        <div>
                          <img
                            alt="img-order-track-modal"
                            style={{ width: "100%" }}
                            src={shippingAddressList.data.data.shipment_label}
                          />
                        </div>
                        <div style={{ textAlign: "end" }}>
                          <Button
                            type="primary"
                            className="mb-0 mr-0 mt-2"
                            onClick={() =>
                              this.downloadHandler(
                                shippingAddressList.data.data.shipment_label
                              )
                            }
                          >
                            <i className="fad fa-download mr-1"></i>
                            Download
                          </Button>
                        </div>
                      </React.Fragment>
                    ) : this.props.shippingAddress &&
                      this.props.shippingAddress.shipment_label == null ? (
                      <React.Fragment>
                        <div></div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div>
                          <img
                            alt="order-tacking"
                            style={{ width: "100%" }}
                            src={this.props.shippingAddress.shipment_label}
                          />
                        </div>
                        <div style={{ textAlign: "end" }}>
                          <Button
                            type="primary"
                            className="mb-0 mr-0 mt-2"
                            onClick={() =>
                              this.downloadHandler(
                                this.props.shippingAddress.shipment_label
                              )
                            }
                          >
                            <i className="fad fa-download mr-1"></i>
                            Download
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </TabPane>
              ) : (
                <TabPane tab="Add Tracking" key="2">
                  <p>
                    If you taking shipping service on your own and that service
                    provide a tracking feature. Here you can put relevant
                    details regarding that tracking. These details will show in
                    the buyer's dashboard. So your buyer will up to date with
                    order status
                  </p>
                  <Form onSubmit={handleSubmit(this.handleSubmit)}>
                    <Field
                      formItemLayout={formItemLayout}
                      name="trackingNumber"
                      component={AInputField}
                      placeholder="Enter Tracking Number"
                      onFocus={this.handleInputFocus}
                      hasFeedback
                    />
                    {/* <Field
                      formItemLayout={formItemLayout}
                      name="carrier"
                      component={AInputField}
                      placeholder="Carrier"
                      onFocus={this.handleInputFocus}
                      hasFeedback
                    />
                    <Field
                      formItemLayout={formItemLayout}
                      name="carrierName"
                      component={AInputField}
                      placeholder="Carrier Name"
                      onFocus={this.handleInputFocus}
                      hasFeedback
                    /> */}
                    <Field
                      formItemLayout={formItemLayout}
                      name="trackingUrl"
                      component={AInputField}
                      addonBefore="https://"
                      placeholder="Enter Tracking URL"
                      onFocus={this.handleInputFocus}
                      hasFeedback
                    />

                    <FormItem style={{ marginBottom: "0" }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        onClick={this.props.onCancel}
                        disabled={pristine || submitting}
                        style={{
                          marginRight: "10px",
                          marginBottom: "0",
                          marginLeft: "10px",
                        }}
                      >
                        {isUpdate ? "Update" : "Confirm"}
                      </Button>
                      <Button
                        disabled={pristine || submitting}
                        onClick={reset}
                        style={{ marginBottom: "0" }}
                        className="float-right"
                      >
                        Clear Form
                      </Button>
                    </FormItem>
                  </Form>
                  {/* <div style={{ marginTop: "30px", textAlign: "end" }}>
                    <Button
                      type="primary"
                      className="mb-0 mr-0"
                      onClick={this.props.onCancel}
                      // disabled={pristine || submitting}
                      htmlType="submit"
                    >
                      {isUpdate ? "Update" : "Confirm"}
                    </Button>
                  </div> */}
                </TabPane>
              )}
            </Tabs>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.trackingNumber) {
    errors.trackingNumber = "Tracking Number is required";
  }
  if (!values.trackingUrl) {
    errors.trackingUrl = "Tracking URL is required";
  } else if (
    !/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$/i.test(
      values.trackingUrl
    )
  ) {
    errors.trackingUrl = "Invalid Tracking URL";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    shippingAddressList: state.OrderManagement.shippingAddressList,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    orderManagementActions: bindActionCreators(
      orderManagementActions,
      dispatch
    ),
  };
}

export default withRouter(
  reduxForm({
    form: "OrderTrackingModel",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(OrderTrackModelBody))
);
