import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Input, Form, Modal, Row, Col, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { resolutionCenterActions } from "../ducks";
import { makeField } from "../../../components/Common/Controls/MakeField";
import UploadPictures from "../../../components/Seller/ProductManagement/UploadPictures/UploadPictures";
// import { IS_ONSHOP } from "../../../constants";
import "./SingleCase.less";

const confirm = Modal.confirm;

const FormItem = Form.Item;
const { TextArea } = Input;

const ATextAreaField = makeField(TextArea);

const formItemLayout = {
  labelCol: { xs: 24, sm: 24, xl: 6, md: 8, lg: 6 },
  wrapperCol: { xs: 24, sm: 24, xl: 18, md: 16, lg: 18 },
};

const formItemLayout2 = {
  labelCol: { xs: 24, sm: 24, xl: 6, md: 8, lg: 6 },
  wrapperCol: { xs: 24, sm: 24, xl: 24, md: 34, lg: 24 },
};

class SingleCaseForm extends Component {
  constructor(props) {
    // debugger;
    super(props);
    this.state = {
      imageList: [],
      isDashboard: false,
    };
  }

  componentDidMount() {
    var id = this.props.match.params && this.props.match.params.id;
    const { pathname } = this.props.location;
    var isDashboard = false;

    if (pathname.includes("/seller/dashboard/resolution-center")) {
      isDashboard = true;
    }
    this.setState({
      caseID: id,
      isDashboard,
    });
  }

  handleCaseConfirmation = (type) => {
    let title = "";
    let content = "";
    if (type === "refund") {
      title = "Do you want to refund this dispute?";
      content = "";
    } else if (type === "to_admin") {
      title = "Do you want to get admin attention to this dispute?";
      content = (
        <p>
          Do you need to pass this dispute to{" "}
          {process.env.REACT_APP_BUSINESS_NAME}'s care center? Click OK to
          connect with our dedicated help team.
          <br />
          <br />
          ** Please note that if you pass this dispute to the care center.
          {process.env.REACT_APP_BUSINESS_NAME}'s crew will able to access your
          conversation with the seller.
        </p>
      );
    } else if (type === "close") {
      title = "Do you want to close this dispute?";
      content = "";
    }
    confirm({
      title: title,
      content: content,
      onOk: () => {
        this.handleCase(type);
      },
      onCancel() {
        
      },
    });
  };

  handleCase = (type) => {
    const { caseID, isDashboard } = this.state;
    var updateDto = {
      update_type: type,
    };
    this.props.resolutionCenterActions.updateCase({
      caseID: caseID,
      updateDto: updateDto,
      isDashboard: isDashboard,
    });
  };

  handleSubmit = (values) => {
    const { caseID, isDashboard } = this.state;
    var { image_keys } = this.props.updateCaseImages;

    var updateDto = {
      update_type: "send_message",
      message: values && values.message,
      images: [...image_keys],
    };
    this.props.resolutionCenterActions.updateCase({
      caseID: caseID,
      updateDto: updateDto,
      isDashboard: isDashboard,
    });
  };
  render() {
    const { imageList, isDashboard } = this.state;
    const { handleSubmit, getCase, updateCase } = this.props;
    return (
      <Spin spinning={updateCase.pending}>
        <div
          className="mt-3 pt-3"
          style={{
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
          }}
        >
          <Form
            onSubmit={handleSubmit(this.handleSubmit)}
            className="gx-form-row0"
          >
            <FormItem
              {...formItemLayout}
              label="Add Details : "
              hasFeedback
              className="mb-0"
            >
              <Field
                formItemLayout={formItemLayout2}
                name="message"
                component={ATextAreaField}
                rows={6}
                placeholder="Details..."
                hasFeedback
              />
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Add Image : (Optional)"
              hasFeedback
              className="mb-0"
            >
              <div className="ml-3">
                <UploadPictures imageList={imageList} isUpdateCase={true} />
              </div>
            </FormItem>
            <FormItem
              className=""
              wrapperCol={{ xs: 24, sm: 24, xl: 24, md: 24, lg: 24 }}
            >
              <Button
                className="mb-3 float-right mr-3"
                type="primary"
                htmlType="submit"
              >
                <div style={{ width: "140px" }}>Submit</div>
              </Button>
            </FormItem>
          </Form>
        </div>

        <Row>
          <Col xl={16} lg={14} md={12} sm={24} xs={24}>
            {!getCase.loading && getCase.data.data.can_send_to_admin_review && (
              <div className="">
                <span>
                  Our happiness is your satisfaction. If you feel dissatisfied
                  and need more attention{" "}
                  <Button
                    type="link"
                    // style={{ color: "red" }}
                    className="link-button-resolution-center"
                    onClick={() => this.handleCaseConfirmation("to_admin")}
                  >
                    Click Here
                  </Button>{" "}
                  to pass this dispute to {process.env.REACT_APP_BUSINESS_NAME}
                  's care center.
                </span>
              </div>
            )}
          </Col>
          <Col xl={8} lg={10} md={12} sm={24} xs={24}>
            <div className="" style={{ textAlign: "end" }}>
              {isDashboard ? (
                <Button
                  style={{ width: "140px" }}
                  className="gx-btn-success mr-1 mt-1 mb-1"
                  onClick={() => this.handleCaseConfirmation("refund")}
                >
                  Refund
                </Button>
              ) : null}
              <Button
                style={{ width: "160px" }}
                className="gx-btn-warning mr-1 mt-1 mb-1"
                onClick={() => this.handleCaseConfirmation("close")}
              >
                Close Dispute
              </Button>
            </div>
          </Col>
        </Row>
      </Spin>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.message) {
    errors.message = "Message is required";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    getCase: state.ResolutionCenter.getCase,
    updateCaseImages: state.ResolutionCenter.updateCaseImages,
    updateCase: state.ResolutionCenter.updateCase,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resolutionCenterActions: bindActionCreators(
      resolutionCenterActions,
      dispatch
    ),
  };
}

export default withRouter(
  reduxForm({
    form: "updateCaseForm",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(SingleCaseForm))
);
