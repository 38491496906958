import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { Input, Card, Select, Col, Row, Form } from "antd";
import {
  FormItemLayout,
  ItemFormItemLayout,
} from "../../../../constants/FormUtility";
import { makeField } from "../../../../components/Common/Controls/MakeField";
import {
  tradesbarnDomesticShippingTypes,
  onShopDomesticShippingTypes,
} from "../../../../constants/Utilities";
import { extraInfoDetails } from "./ExtraInfoDetails";
import ExtraInfo from "./ExtraInfo";
import {
  DEFAULT_CURRENCY,
  IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST,
  IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_TRACKING_FIELD,
  IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_ESTIMATE_SHIPPING_OPTIONS,
  TRADESBARN,
  IS_DOMESTIC_MODE,
} from "../../../../constants";

const Option = Select.Option;
const { TextArea } = Input;
const FormItem = Form.Item;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);
const ATextAreaField = makeField(TextArea);

class DomesticShipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domesticShippingOptions: [],
    };
  }

  componentDidMount() {
    const domesticShippingOptions =
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? tradesbarnDomesticShippingTypes
        : onShopDomesticShippingTypes;

    this.setState({
      domesticShippingOptions: domesticShippingOptions,
    });
  }
  render() {
    const { fieldValues } = this.props;
    const { domesticShippingOptions } = this.state;

    let isFlatRated =
      fieldValues && fieldValues.domesticShippingMethod === "flat_rated"
        ? true
        : false;
    let isNoShipping =
      fieldValues && fieldValues.domesticShippingMethod === "no_shipping"
        ? true
        : false;
    // let isFreeShipping =
    //   fieldValues && fieldValues.domesticShippingMethod === "free_shipping"
    //     ? true
    //     : false;

    let isAdditionalShippingCost =
      IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST && isFlatRated;

    return (
      <div className="mb-3">
        <h6 className="mb-3">
          {IS_DOMESTIC_MODE ? "Delivery" : "Domestic Shipping"}
        </h6>
        <Card style={{ backgroundColor: "#fafafa" }} className="jr-fld-catch ">
          <FormItem
            {...FormItemLayout}
            label={
              IS_DOMESTIC_MODE ? "Delivery Method" : "Domestic Shipping Method"
            }
            required
            id="domesticShippingMethod"
          >
            <Field
              formItemLayout={ItemFormItemLayout}
              name={"domesticShippingMethod"}
              component={ASelectField}
              style={{ width: "100%" }}
              placeholder="Select"
              hasFeedback
            >
              {domesticShippingOptions.map((domesticShippingType, i) => {
                return (
                  <Option key={i} value={domesticShippingType.value}>
                    {domesticShippingType.label}
                  </Option>
                );
              })}
            </Field>
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo 
                title={process.env.REACT_APP_BUSINESS_NAME === TRADESBARN ? extraInfoDetails.domShipngMeth : extraInfoDetails.domShipngMethOnShop} 
              />
            </span>
          </FormItem>

          <FormItem
            {...FormItemLayout}
            label={
              IS_DOMESTIC_MODE
                ? "Delivery Handling Days"
                : "Shipping Handling Days "
            }
            required
          >
            <Field
              formItemLayout={ItemFormItemLayout}
              name="shippingHandlingDays"
              component={AInputField}
              placeholder={
                IS_DOMESTIC_MODE
                  ? "Delivery Handling Days"
                  : "Shipping Handling Days"
              }
              hasFeedback
              style={{ width: "100%" }}
              normalize={(val) => (val || "").replace(/[^\d]/g, "")}
            />
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo title={extraInfoDetails.shippingHandlingDay} />
            </span>
          </FormItem>

          {IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_ESTIMATE_SHIPPING_OPTIONS &&
            !isNoShipping && (
              <React.Fragment>
                <hr />
                <div className="mb-2 fnshed-clr">
                  Estimate Shipping Duration
                </div>
                <Row>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <div className="jr-fld-catch">
                      <FormItem
                        {...FormItemLayout}
                        label="Min"
                        required={!isNoShipping}
                      >
                        <Field
                          formItemLayout={ItemFormItemLayout}
                          name="domesticShippingEstimatedDeliveryDaysMin"
                          component={AInputField}
                          type="text"
                          normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                          placeholder="Minimum Days"
                          style={{ width: "100%" }}
                          hasFeedback
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: "10px",
                            bottom: "-37px",
                          }}
                        >
                          <ExtraInfo
                            title={
                              extraInfoDetails.domesticShippingEstimatedDeliveryDaysMin
                            }
                          />
                        </span>
                      </FormItem>
                    </div>
                  </Col>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <div className="jr-fld-catch">
                      <FormItem
                        {...FormItemLayout}
                        label="Max"
                        required={!isNoShipping}
                      >
                        <Field
                          formItemLayout={ItemFormItemLayout}
                          name="domesticShippingEstimatedDeliveryDaysMax"
                          component={AInputField}
                          type="text"
                          normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                          placeholder="Maximum Days"
                          style={{ width: "100%" }}
                          hasFeedback
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: "10px",
                            bottom: "-37px",
                          }}
                        >
                          <ExtraInfo
                            title={
                              extraInfoDetails.domesticShippingEstimatedDeliveryDaysMax
                            }
                          />
                        </span>
                      </FormItem>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                    <div className="jr-fld-catch">
                      <FormItem
                        {...FormItemLayout}
                        label="Estimated Note"
                        required={false}
                      >
                        <Field
                          formItemLayout={ItemFormItemLayout}
                          name="domesticShippingEstimatedDeliveryNote"
                          component={ATextAreaField}
                          placeholder="Domestic Estimated note"
                          rows={4}
                          hasFeedback
                          // suffix={
                          //   <ExtraInfo
                          //     title={
                          //       extraInfoDetails.domesticShippingEstimatedDeliveryNote
                          //     }
                          //   />
                          // }
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: "10px",
                            bottom: "-37px",
                          }}
                        >
                          <ExtraInfo title={extraInfoDetails.estimatedNote} />
                        </span>
                      </FormItem>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}

          {isFlatRated && (
            <FormItem
              {...FormItemLayout}
              label="Domestic Shipping Price"
              required={isFlatRated}
            >
              <Field
                formItemLayout={FormItemLayout}
                name="domesticShippingPrice"
                component={AInputField}
                placeholder="Domestic Shipping Price"
                hasFeedback
                disabled={!isFlatRated}
                type="number"
                addonBefore={DEFAULT_CURRENCY}
                onWheel={(e) => e.target.blur()}
                min="0"
              />
              <span
                style={{ position: "absolute", right: "10px", bottom: "-37px" }}
              >
                <ExtraInfo title={extraInfoDetails.domesticShippingPrice} />
              </span>
            </FormItem>
          )}

          {isAdditionalShippingCost && (
            <FormItem
              {...FormItemLayout}
              label="Domestic Shipping Additional Price"
              required={isAdditionalShippingCost}
            >
              <Field
                formItemLayout={FormItemLayout}
                name="domesticShippingAdditionalPrice"
                component={AInputField}
                type="number"
                addonBefore={DEFAULT_CURRENCY}
                placeholder="Domestic Shipping Additional Price"
                hasFeedback
                disabled={!isFlatRated}
                onWheel={(e) => e.target.blur()}
                min="0"
              />
              <span
                style={{ position: "absolute", right: "10px", bottom: "-37px" }}
              >
                <ExtraInfo
                  title={extraInfoDetails.domesticShippingAdditionalPrice}
                />
              </span>
            </FormItem>
          )}
          {IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_TRACKING_FIELD &&
            !isNoShipping && (
              <FormItem
                {...FormItemLayout}
                label="Domestic Shipping Tracking"
                required={!isNoShipping}
                id="domesticShippingTrackingAvailable"
              >
                <Field
                  formItemLayout={FormItemLayout}
                  name="domesticShippingTrackingAvailable"
                  component={ASelectField}
                  style={{ width: "100%" }}
                  placeholder="Select"
                  hasFeedback
                  // suffix={
                  //   <ExtraInfo
                  //     title={extraInfoDetails.domesticShippingTracking}
                  //   />
                  // }
                >
                  <Option value="enable">Enable</Option>
                  <Option value="disable">Disable</Option>
                </Field>
                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "-37px",
                  }}
                >
                  <ExtraInfo
                    title={extraInfoDetails.domesticShippingTracking}
                  />
                </span>
              </FormItem>
            )}
        </Card>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
DomesticShipping = reduxForm({
  form: "AddPoduct_form", // a unique identifier for this form
  enableReinitialize: true,
})(DomesticShipping);

// You have to connect() to any reducers that you wish to connect to yourself
DomesticShipping = connect(
  (state) => ({
    // initialValues: state.account.data // pull initial values from account reducer
    initialValues: state.ProductManagement.variationTableDto,
    // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(DomesticShipping);

export default DomesticShipping;
