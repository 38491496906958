import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  domesticLocations: {
    ...initialStateModal,
  },
  estimatedShippingCost: {},
};

// Reducers from redux-actions
export default handleActions(
  {
    //---------------------Domestic Location-------------------------------//
    [types.GET_DOMESTIC_LOCATIONS]: (state, { payload }) => ({
      ...state,
      domesticLocations: {
        ...state.domesticLocations,
        loading: true,
        pending: true,
        hasError: false,
      },
    }),

    [types.GET_DOMESTIC_LOCATIONS_SUCCESS]: (state, { payload }) => ({
      ...state,
      domesticLocations: {
        ...state.domesticLocations,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_DOMESTIC_LOCATIONS_FAILED]: (state, { payload }) => ({
      ...state,
      domesticLocations: {
        ...state.domesticLocations,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    //---------------------Estimated Shipping Cost-------------------------------//
    [types.GET_ESTIMATED_SHIPPING_COST]: (state, { payload }) => ({
      ...state,
      estimatedShippingCost: {
        ...state.estimatedShippingCost,
        loading: true,
        pending: true,
        hasError: false,
      },
    }),

    [types.GET_ESTIMATED_SHIPPING_COST_SUCCESS]: (state, { payload }) => ({
      ...state,
      estimatedShippingCost: {
        ...state.estimatedShippingCost,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_ESTIMATED_SHIPPING_COST_FAILED]: (state, { payload }) => ({
      ...state,
      estimatedShippingCost: {
        ...state.estimatedShippingCost,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
  },
  initialState
);
