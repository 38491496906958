import React from "react";
import {
  FormItemLayout,
  feedbackFormTypes,
  FormItemLayout2,
  tradesbarnFeedbackFormContent,
  onshopFeedbackFormContent,
  onshopSinhalaFeedbackFormContent,
} from "../../../constants/Utilities";
import { makeField } from "../Controls/MakeField";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Input,
  Modal,
  Select,
  Card,
  Button,
  Form,
  Col,
  Spin,
  Rate,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { reduxForm, Field, getFormValues } from "redux-form";
import { customerServiceActions } from "../../../modules/CustomerService/ducks";
import UploadPictures from "../../../components/Seller/ProductManagement/UploadPictures/UploadPictures";
import { productManagementActions } from "../../../modules/SellerDashboard/ProductManagement/ducks";
import { G_RECAPTCHA_SITEKEY, ONSHOP, TRADESBARN } from "../../../constants";

const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;

const AInputField = makeField(Input);
const AInputAreaField = makeField(TextArea);
const ASelectField = makeField(Select);
const RateField = makeField(Rate);

const Feedback = (props) => {
  const handleSubmit = (res) => {
    const { fieldValues } = props;

    const { image_keys } = props.screenshots;
    let feedbackDto = {
      ...fieldValues,
      screenshot: image_keys.length === 0 ? undefined : [...image_keys],
      "g-recaptcha-response": res,
    };
    props.customerServiceActions.feedBackFormSubmit({
      feedbackDto,
      callback: callbackHandler,
    });
  };

  const callbackHandler = () => {
    props.customerServiceActions.clearFeedbackImages();
    props.feedBackModalHandler();
  };

  const verifyCaptcha = (res) => {
    if (res) {
      handleSubmit(res);
    }
  };

  const expireCaptcha = () => {
    
  };

  let recaptchaInstance;
  const executeCaptcha = () => {
    recaptchaInstance.execute();
  };

  return (
    <Modal
      footer={null}
      title="Feedback"
      style={{ top: 30 }}
      wrapClassName="vertical-center-modal catch-tag-model"
      visible={props.visible}
      onOk={props.feedBackModalHandler}
      onCancel={props.feedBackModalHandler}
    >
      <p>
        {process.env.REACT_APP_BUSINESS_NAME === ONSHOP &&
          onshopSinhalaFeedbackFormContent}
      </p>

      <p>
        {process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
          ? tradesbarnFeedbackFormContent
          : onshopFeedbackFormContent}
      </p>
      <Spin spinning={props.addFeedBack.pending}>
        <Form
          onSubmit={props.handleSubmit(executeCaptcha)}
          className="gx-signin-form gx-form-row0 feild-catcher-reg"
        >
          <Card
            className="crd-bdy-fd mb-0"
            style={{ backgroundColor: "#fafafa" }}
          >
            <FormItem {...FormItemLayout} label="Rate">
              <Field
                formItemLayout={FormItemLayout2}
                name="rating"
                component={RateField}
              />
            </FormItem>

            <FormItem {...FormItemLayout} label="Name">
              <Field
                formItemLayout={FormItemLayout2}
                name="name"
                component={AInputField}
                placeholder="Name"
              />
            </FormItem>

            <FormItem {...FormItemLayout} label="Email">
              <Field
                formItemLayout={FormItemLayout2}
                name="email"
                component={AInputField}
                placeholder="Email"
              />
            </FormItem>

            <FormItem {...FormItemLayout} label="Type">
              <Field
                formItemLayout={FormItemLayout2}
                name="type"
                component={ASelectField}
                style={{ width: "100%" }}
                placeholder="Select"
                hasFeedback
              >
                {feedbackFormTypes.map((feedbackFormType, i) => {
                  return (
                    <Option key={i} value={feedbackFormType.value}>
                      {feedbackFormType.label}
                    </Option>
                  );
                })}
              </Field>
            </FormItem>

            <FormItem {...FormItemLayout} label="Content" required>
              <Field
                formItemLayout={FormItemLayout2}
                name="content"
                component={AInputAreaField}
                rows={3}
                placeholder="Content"
              />
            </FormItem>

            <FormItem
              {...FormItemLayout}
              label="Screenshot(s)"
              hasFeedback
              className="mb-0"
            >
              <div className="ml-3 mt-2">
                <UploadPictures isFeedback={true} />
              </div>
            </FormItem>

            <ReCAPTCHA
              sitekey={G_RECAPTCHA_SITEKEY}
              onChange={verifyCaptcha}
              onExpired={expireCaptcha}
              size="invisible"
              ref={(e) => (recaptchaInstance = e)}
              verifyCallback={handleSubmit}
            />
          </Card>
          <Col span={24} style={{ padding: "0px" }}>
            <div className="mt-3">
              <Button block type="primary" className="mb-0" htmlType="submit">
                <i className="fad fa-vote-yea mr-2"></i>
                Submit
              </Button>
            </div>
          </Col>
        </Form>
      </Spin>
    </Modal>
  );
};

const validate = (values) => {
  let errors = {};

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email";
  }
  if (!values.content) {
    errors.content = "We Highly Appriciate Your FeedBack";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    addFeedBack: state.FeedBack.addFeedBack,
    screenshots: state.FeedBack.feedbackImages,
    fieldValues: getFormValues("feedback_form")(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    customerServiceActions: bindActionCreators(
      customerServiceActions,
      dispatch
    ),
    productManagementActions: bindActionCreators(
      productManagementActions,
      dispatch
    ),
  };
}

export default withRouter(
  reduxForm({
    form: "feedback_form",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(Feedback))
);
