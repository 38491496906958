import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Select, Input, Form, Spin } from "antd";
import { withRouter, Link } from "react-router-dom";
import { resolutionCenterActions } from "../../../modules/ResolutionCenter/ducks";
import { makeField } from "../../Common/Controls/MakeField";
import UploadPictures from "../../Seller/ProductManagement/UploadPictures/UploadPictures";
import "./ReturnItemForm.less";
import { onShopRCReasons, RCReasons } from "../../../constants/Utilities";
import {
  DEFAULT_CURRENCY_SHORT,
  IS_ONSHOP,
  IS_TRADESBARN,
} from "../../../constants";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const ATextAreaField = makeField(TextArea);
const ASelectField = makeField(Select);

class ReturnItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageList: [],
      isDashboard: false,
      appRCReasons: [],
    };
  }
  componentDidMount() {
    const { pathname } = this.props.location;
    var isDashboard = false;

    if (pathname.includes("/seller/dashboard/resolution-center")) {
      isDashboard = true;
    }
    let appRCReasons = [];
    if (IS_TRADESBARN) {
      appRCReasons = RCReasons;
    } else if (IS_ONSHOP) {
      appRCReasons = onShopRCReasons;
    }
    this.setState({
      isDashboard,
      appRCReasons: appRCReasons,
    });
  }
  handleSubmit = (values) => {
    var { data } = this.props;
    var { isDashboard } = this.state;
    var { image_keys } = this.props.reportProductImages;
    var { reason, message } = values;
    var orderID = data.objectID ? data.objectID : data.id;

    var reportItemDto = {
      order_id: orderID,
      reason: reason,
      message: message,
      images: [...image_keys],
    };
    this.props.resolutionCenterActions.openNewCase({
      reportItemDto,
      isDashboard,
    });
  };

  handleVisitShop = () => {
    var { data } = this.props;
    var sellerID = data && data.seller && data.seller.id;
    if (sellerID) {
      this.props.history.push(`/seller/${sellerID}`);
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    const { data, handleSubmit, openNewCase } = this.props;
    const { imageList, appRCReasons } = this.state;

    const formItemLayout = {
      labelCol: { xs: 24, sm: 24, xl: 6, md: 8, lg: 6 },
      wrapperCol: { xs: 24, sm: 24, xl: 18, md: 16, lg: 18 },
    };

    const formItemLayout2 = {
      labelCol: { xs: 24, sm: 24, xl: 6, md: 8, lg: 6 },
      wrapperCol: { xs: 24, sm: 24, xl: 24, md: 34, lg: 24 },
    };

    return (
      <Spin spinning={openNewCase.pending}>
        <div className="gx-card p-4 mb-4">
          <h6 className="mb-3">Product Details</h6>
          <div className="gx-media gx-featured-item details-card-jr">
            <div className="gx-featured-thumb">
              <img
                className="gx-rounded-lg"
                style={{ width: "80px" }}
                src={data && data.product && data.product.img}
                alt="..."
              />
            </div>
            <div className="gx-media-body gx-featured-content">
              <div
                className="gx-featured-content-left"
                style={{ width: "100%" }}
              >
                <p className="gx-mb-1">
                  <b>Seller : </b>
                  <Link
                    className="mb-0 crd-fnt-jr"
                    onClick={() => this.handleVisitShop()}
                  >
                    {data && data.seller && data.seller.name}
                  </Link>
                </p>
                <p className="gx-mb-1">
                  <b>Product : </b>
                  <Link
                    className="crd-fnt-jr"
                    to={`/product/${data && data.product && data.product.id}`}
                  >
                    {data && data.product && data.product.name}
                  </Link>
                </p>

                <p className="gx-mb-0">
                  <b>Overall Status : </b>
                  {data && data.status && (
                    <span
                      className={
                        data.status === "In Progress"
                          ? "inprogs-clr"
                          : data.status === "Paid"
                          ? "paid-clr"
                          : data.status === "Cancelled"
                          ? "cancle-clr"
                          : data.status === "Delivered"
                          ? "fnshed-clr"
                          : data.status === "Returned"
                          ? "retn-clr"
                          : "shpp-clr"
                      }
                    >
                      {data.status}
                    </span>
                  )}
                </p>
              </div>
              <div className="gx-featured-content-right">
                <div className="addmgnlft " style={{ height: "100%" }}>
                  <table style={{ height: "100%" }}>
                    <tr>
                      <td>
                        <h2
                          className="gx-mb-1 gx-font-weight-medium"
                          style={{ color: "#ff6900" }}
                        >
                          {`${DEFAULT_CURRENCY_SHORT} ${data?.transaction?.grand_total}`}
                        </h2>
                        <div style={{ width: "150px" }}>(Price + Shipping)</div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gx-card p-4 mb-0">
          <h6 className="mb-3">Your Dispute</h6>
          <div
            className="py-3"
            style={{
              backgroundColor: "rgb(245, 245, 245)",
              borderRadius: "5px",
            }}
          >
            <Form
              onSubmit={handleSubmit(this.handleSubmit)}
              className="gx-signin-form gx-form-row0 feild-catcher-reg"
            >
              <FormItem {...formItemLayout} label="Reason" hasFeedback>
                <Field
                  formItemLayout={formItemLayout2}
                  name="reason"
                  component={ASelectField}
                  style={{ width: "100%" }}
                  hasFeedback
                >
                  {appRCReasons.map((reason, i) => {
                    return <Option value={reason.value}>{reason.label}</Option>;
                  })}
                </Field>
              </FormItem>

              <FormItem {...formItemLayout} label="Add Details" hasFeedback>
                <Field
                  formItemLayout={formItemLayout2}
                  name="message"
                  component={ATextAreaField}
                  rows={4}
                  placeholder="Details..."
                  hasFeedback
                />
              </FormItem>

              <FormItem {...formItemLayout} label="Add Image:" hasFeedback>
                <div className="ml-3 mt-3">
                  <UploadPictures
                    imageList={imageList}
                    isResolutionCenter={true}
                  />
                </div>
              </FormItem>

              <FormItem
                className="mb-0"
                wrapperCol={{ xs: 24, sm: 24, xl: 24, md: 24, lg: 24 }}
              >
                <Button
                  className="mb-0 float-right mr-3"
                  style={{ width: "140px" }}
                  type="primary"
                  htmlType="submit"
                >
                  &nbsp;&nbsp;Submit&nbsp;&nbsp;
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.reason) {
    errors.reason = "Reason is required";
  }
  if (!values.message) {
    errors.message = "Message is required";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    resolutionCenterCases: state.ResolutionCenter.resolutionCenterCases,
    reportProductImages: state.ResolutionCenter.reportProductImages,
    openNewCase: state.ResolutionCenter.openNewCase,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resolutionCenterActions: bindActionCreators(
      resolutionCenterActions,
      dispatch
    ),
  };
}

export default withRouter(
  reduxForm({
    form: "ReturnItemForm",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(ReturnItemForm))
);
