import React, { Component } from "react";
import { Layout, Popover, Badge } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { Configure, InstantSearch } from "react-instantsearch-dom";
import { homeActions } from "./../../../modules/Home/ducks";
import SearchBox from "components/SearchBox";
import UserInfo from "../../../components/Buyer/UserInfo/UserInfo";
import CartNotify from "../../../components/Buyer/MainHeader/CartNotify/CartNotify";
import WishlistNotify from "../../../components/Buyer/MainHeader/WishlistNotify/WishlistNotify";
// import { locale } from "../../../constants/Utilities";
import "./index.less";
import { authActions } from "../../../modules/Auth/ducks";
import { auth } from "../../../firebase/firebase";
import { cartActions } from "../../../modules/Cart/ducks";
import { GetNotificationsCount } from "../../../firebase/functions";
import { withUrlSync } from "../../../util/urlSync";
import {
  ALGOLIA_PRODUCTS_INDEX,
  ALGOLIA_PRODUCTS_APIKEY,
  IS_BUY_ITEMS,
  IS_DOMESTIC_MODE,
} from "../../../constants";
import HeaderSearchBox from "./HeaderSearchBox";
import { initAlgoliaClient } from "../../../services/algolia.service";
// import { clearHTTPClient } from "../../../util/HTTPClient";
const { Header } = Layout;
const searchClient = initAlgoliaClient(ALGOLIA_PRODUCTS_APIKEY);

class HorizontalDark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      clickItem1Color: false,
      clickItem2Color: false,
      clickItem3Color: false,
      searchText: "",
      currentUserObj: null,
      user: null,
      wishlistAPIKey: null,
      wishlistIndex: null,
      notifyCount: null,
    };
  }
  componentDidMount() {
    const user = localStorage.getItem("user");
    if (!!user) {
      const userObj = JSON.parse(user);
      const wishlistAPIKey = userObj.algolia.wishlist.api_key;
      const wishlistIndex = userObj.algolia.wishlist.index;
      this.setState({
        user: user,
        wishlistAPIKey: wishlistAPIKey,
        wishlistIndex: wishlistIndex,
      });
    }

    this.getCurrentUser();
    this.getNotifyCounts();
    // getter
    var myCartString = localStorage.getItem("myCart");
    if (myCartString) {
      var myCartObj = JSON.parse(myCartString);
      // this.setState({ myCartObj });
      if (myCartObj.length !== 0) {
        this.props.cartActions.initializeReduxCart({ myCartObj });
      }
    }
    /**
     * Domestic Mode
     * Initialize Location Picker Value
     */
    if (IS_DOMESTIC_MODE) {
      var selectedLocationString = localStorage.getItem("selectedLocation");
      if (!!selectedLocationString) {
        var selectedLocation = JSON.parse(selectedLocationString);
        this.props.cartActions.initializeLocationPickerValue({
          selectedLocation,
        });
      }
    }
  }

  getNotifyCounts = () => {
    const user = localStorage.getItem("user");

    if (!!user) {
      //Need to call when login
      GetNotificationsCount((counts, NotificationsListner) => {
        this.setState({
          notifyCount: counts,
        });
        //NotificationsListner(); // Need to call when logout
      });
    }
  };
  getCurrentUser = () => {
    // debugger;
    auth.onAuthStateChanged(
      function (user) {
        var currentUserObj = null;
        if (user) {
          currentUserObj = {
            photoURL: user.photoURL,
            name: user.displayName,
            email: user.email,
            emailVerified: user.emailVerified,
            uid: user.uid,
          };
          // User is signed in.
        } else {
          // No user is signed in.
          currentUserObj = null;
        }
        this.setState({
          currentUserObj: currentUserObj,
        });
      }.bind(this)
    );
  };

  clickItemColorChnge = (item) => {
    // debugger
    if ("clickItem1" === item) {
      this.setState({
        clickItem1Color: true,
        clickItem2Color: false,
        clickItem3Color: false,
      });
    }
    if ("clickItem2" === item) {
      this.setState({
        clickItem1Color: false,
        clickItem2Color: true,
        clickItem3Color: false,
      });
    }
    if ("clickItem3" === item) {
      this.setState({
        clickItem1Color: false,
        clickItem2Color: false,
        clickItem3Color: true,
      });
    }
  };

  updateSearchChatUser = (e) => {
    this.setState({ searchText: e.target.value });
  };

  logoutHandler = () => {
    //remove the httpClient instance
    // clearHTTPClient();
    this.props.authActions.signoutUser({ isUpgrade: false });
  };

  loginHandler = () => {
    this.props.history.push("/login");
  };

  profileHandler = () => {
    this.props.history.push("/my-profile");
  };

  orderHandler = () => {
    this.props.history.push("/orders/all");
  };

  chatHandler = () => {
    this.props.history.push("/chat");
  };

  wishListHandler = () => {
    this.props.history.push("/wishlist");
  };

  handleSidebarToggle = () => {
    this.props.cartActions.sidebarToggle();
  };
  render() {
    var isLoggedIn = localStorage.getItem("user");
    const { user, wishlistAPIKey, wishlistIndex, notifyCount } = this.state;
    return (
      <InstantSearch
        className="gx-main-content"
        indexName={ALGOLIA_PRODUCTS_INDEX}
        searchState={this.props.searchState}
        createURL={this.props.createURL}
        searchClient={searchClient}
        onSearchStateChange={this.props.onSearchStateChange}
      >
        <Configure hitsPerPage={16} />

        <div
          className="bckgrnd sticky-top botm-bodr-nav"
          style={{ zIndex: "1000" }}
        >
          <Header
            className="gx-header-horizontal-main pb-3 pt-3"
            style={{ zIndex: "5px" }}
          >
            <div className="gx-container">
              <div className="gx-header-horizontal-main-flex">
                <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
                  <i
                    className="gx-icon-btn icon icon-menu"
                    onClick={() => {
                      this.handleSidebarToggle();
                    }}
                  />
                </div>
                <Link
                  to="/"
                  className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
                >
                  <img
                    alt=""
                    src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/w-logo.png`)}
                    className="imgggggg"
                  />
                </Link>
                <Link
                  to="/"
                  className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
                >
                  <img
                    alt=""
                    src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/logo.png`)}
                    className="imgggggg"
                  />
                </Link>

                <HeaderSearchBox allCategories={this.props.allCategories} />
                <ul className="gx-header-notifications ">
                  {/* Responsive Search bar */}
                  <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
                    <Popover
                      overlayClassName="gx-popover-horizantal"
                      placement="bottomRight"
                      content={
                        <div className="gx-d-flex">
                          <SearchBox
                            styleName="gx-popover-search-bar"
                            placeholder="Search in app..."
                            // onChange={this.updateSearchChatUser}
                            // value={this.state.searchText}
                          />
                          {/* <HeaderSearchBox/> */}
                        </div>
                      }
                      trigger="click"
                    >
                      <span className="gx-pointer gx-d-block">
                        <i className="icon icon-search-new" />
                      </span>
                    </Popover>
                  </li>

                  {/* Wishlist */}
                  <li>
                    <Popover
                      className="txt-whte  pt-1"
                      overlayClassName="gx-popover-horizantal"
                      placement="bottomRight"
                      content={
                        <WishlistNotify
                          user={user}
                          wishlistAPIKey={wishlistAPIKey}
                          wishlistIndex={wishlistIndex}
                        />
                      }
                      trigger="click"
                    >
                      <span className="gx-pointer gx-status-pos gx-d-block">
                        {/* <i className="icon icon-like-o" /> */}
                        <i className="fas fa-heart"></i>
                        <Badge
                          className="gx-status gx-jr-bdg"
                          count={
                            isLoggedIn
                              ? notifyCount && notifyCount.wishlist_count
                              : null
                          }
                        ></Badge>
                      </span>
                    </Popover>
                  </li>
                  {/* Shopping Cart */}
                  {IS_BUY_ITEMS && (
                    <li>
                      <Popover
                        className="txt-whte pt-1"
                        overlayClassName="gx-popover-horizantal"
                        placement="bottomRight"
                        content={<CartNotify cart={this.props.myCart} />}
                        trigger="click"
                      >
                        <span className="gx-pointer gx-status-pos gx-d-block">
                          {/* <i className="icon icon-shopping-cart" /> */}
                          <i className="fad fa-shopping-cart"></i>
                          <Badge
                            className="gx-status gx-jr-bdg"
                            count={
                              this.props.myCart
                                ? this.props.myCart.items.length
                                : 0
                            }
                          ></Badge>
                        </span>
                      </Popover>
                    </li>
                  )}
                  {/* User Profile */}
                  <li className="gx-user-nav ml-0">
                    <UserInfo
                      logoutHandler={this.logoutHandler}
                      currentUserObj={this.state.currentUserObj}
                      loginHandler={this.loginHandler}
                      profileHandler={this.profileHandler}
                      orderHandler={this.orderHandler}
                      wishListHandler={this.wishListHandler}
                      chatHandler={this.chatHandler}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </Header>
        </div>
      </InstantSearch>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.Home,
    allProducts: state.Home.allProducts,
    popularCarouselProducts: state.Home.popularCarouselProducts,
    latestCarouselProducts: state.Home.latestCarouselProducts,
    myCart: state.Cart.myCart,
    allCategories: state.Home.allCategories,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
    homeActions: bindActionCreators(homeActions, dispatch),
  };
}

export default withUrlSync(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(HorizontalDark))
);
