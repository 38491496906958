import React from "react";
import moment from "moment";

const QnACard = ({ styleName, data }) => {
  const { question, answer, created_at } = data;
  return (
    <div
      className={`gx-user-list ${styleName} mb-2 p-3`}
      style={{ borderRadius: "10px" }}
    >
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td colSpan="2">
              <p
                className="mb-1"
                style={{ fontSize: "13px", color: "rgb(109 109 109)" }}
              >
                {moment(created_at).format("llll")}
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "35px", verticalAlign: "top" }}>
              <h6 className="mb-0">
                <b>Q - </b>
              </h6>
            </td>
            <td>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "0",
                  fontWeight: "500",
                  color: "#4b505e",
                }}
              >
                {question}
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "35px", verticalAlign: "top" }}>
              <h6 className="mb-0">
                <b>A - </b>
              </h6>
            </td>
            <td>
              <p style={{ fontSize: "14px", marginBottom: "0" }}>{answer}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default QnACard;
