import React from "react";

// //seller-dashboard views
// import DashboardPage from "../modules/Dashboard/Dashboard";

//buyer-dashboard views
// import AllProducts from "./AllProducts/AllProducts";
// import Home from "./Home/Home";
import Cart from "./Cart/Cart";
// import Checkout from "./Checkout/Checkout";
// import ProfilePage from "modules/ProfilePage/ProfilePage";
// import Login from "./Auth/Login/Login";
// import Register from "./Auth/Register/Register";
// import ForgetPassword from "./Auth/ForgetPassword/ForgetPassword";
// import VerifyResetPassword from "./Auth/VerifyResetPassword/VerifyResetPassword";
// import PolicyPage from "modules/PoliciesPage/PoliciesPage";
import SingleProduct from "modules/SingleProduct/SingleProduct";
// import Wishlist from "modules/WishlistPage/WishlistPage";
// import ChatPage from "modules/ChatPage/ChatPage";
// import AccountPage from "modules/AccountPage/AccountPage";
// import SellerStore from "./SellerStore/SellerStore";
// import DeleteOrderPage from "modules/DeleteOrderPage/DeleteOrderPage";
// import NatificationPage from "../modules/NatificationPage/NotificationPage";
// import OrderTrackPage from "./ProfilePage/OrderTrack/OrderTrack";
// import MyOrders from "./MyOrders/MyOrders";
// import Mybarn from "./ProfilePage/Mybarn/Mybarn";
// import ShippingAddress from "./ProfilePage/ShippingAddress/ShippingAddress";
// import ErrorPage from "../modules/UtilitiesPage/UtilitiesPage";
// import ResolutionCenter from "./ResolutionCenter/ResolutionCenterHome/ResolutionCenterHome";
// import ReportItemPage from "./ResolutionCenter/ReportItem/ReportItemPage";
// import ReportItemConfirmPage from "./ResolutionCenter/ReportItemConfirm/ReportItemConfirm";
// import SellerHelpPage from "../modules/SellerHelpPage/SellerHelpPage";
// import BuyerHelpPage from "../modules/BuyerHelpPage/BuyerHelpPage";
// import SellerPage from "../modules/SellerPage/SellerPage";
// import SellerRegisterPage from "../modules/SellerRegisterPage/SellerRegisterPage";
// import SingleCase from "../modules/ResolutionCenter/SingleCase/SingleCase";
// import UserAgreement from "../modules/UserAgreement/UserAgreement";
// import CheckoutSuccess from "./ResultsPage/CheckoutSuccess";
// import CheckoutFail from "./ResultsPage/CheckoutFail";
// import PageNotFound from "./ResultsPage/PageNotFound";
// import MobileApp from "./StaticPages/MobileApp";
// import HelpPage from "./StaticPages/HelpPage";
// import TermAndConditions from "./StaticPages/TermAndConditions";
// import CardsAddPage from "./CardsAddPage/CardsAddPage";
// import CompanyInforAndPolicy from "./CustomerService/CompanyInforAndPolicy";
// import AcceptedPaymentsPolicy from "./CustomerService/AcceptedPaymentsPolicy";
// import IntellectualPropertyRules from "./CustomerService/IntellectualPropertyRules";
// import RealEstatePolicy from "./CustomerService/RealEstatePolicy";
// import TaxPolicy from "./CustomerService/TaxPolicy";
// import IdentityScreeningPolicy from "./CustomerService/IdentityScreeningPolicy";
// import FraudPolicy from "./CustomerService/FraudPolicy";
// import FinalValueFees from "./CustomerService/FinalValueFees";
// import OnlineAdvertisingPolicy from "./CustomerService/OnlineAdvertisingPolicy";
// import PrivacyPolicy from "./CustomerService/PrivacyPolicy";
// import ChangePassword from "../components/Buyer/BuyerProfile/ChangePassword/ChangePassword";
// import BuyerRegistration from "./SupportCenter/BuyerRegistration";
// import SellerRegistration from "./SupportCenter/SellerRegistration";
// import ProductListing from "./SupportCenter/ProductListing";
// import ResolutionCenterHelp from "./SupportCenter/ResolutionCenterHelp";
// import FAQ from "./FAQ/FAQ";

// //seller-dashboard views
// import DashboardPage from "../modules/Dashboard/Dashboard";
const DashboardPage = React.lazy(() =>
  import("../modules/Dashboard/Dashboard")
);

const AllProducts = React.lazy(() => import("./AllProducts/AllProducts"));
const Home = React.lazy(() => import("./Home/Home"));

// const Cart = React.lazy(() => import("./Cart/Cart"));
const Checkout = React.lazy(() => import("./Checkout/Checkout"));
const ProfilePage = React.lazy(() => import("modules/ProfilePage/ProfilePage"));
const Login = React.lazy(() => import("./Auth/Login/Login"));
const Register = React.lazy(() => import("./Auth/Register/Register"));

const ForgetPassword = React.lazy(() =>
  import("./Auth/ForgetPassword/ForgetPassword")
);
const VerifyResetPassword = React.lazy(() =>
  import("./Auth/VerifyResetPassword/VerifyResetPassword")
);
const PolicyPage = React.lazy(() =>
  import("modules/PoliciesPage/PoliciesPage")
);
// const SingleProduct = React.lazy(() =>
//   import("modules/SingleProduct/SingleProduct")
// );
const Wishlist = React.lazy(() => import("modules/WishlistPage/WishlistPage"));
const ChatPage = React.lazy(() => import("modules/ChatPage/ChatPage"));
const AccountPage = React.lazy(() => import("modules/AccountPage/AccountPage"));
const SellerStore = React.lazy(() => import("./SellerStore/SellerStore"));
const DeleteOrderPage = React.lazy(() =>
  import("modules/DeleteOrderPage/DeleteOrderPage")
);
const NatificationPage = React.lazy(() =>
  import("../modules/NatificationPage/NotificationPage")
);
const OrderTrackPage = React.lazy(() =>
  import("./ProfilePage/OrderTrack/OrderTrack")
);
const MyOrders = React.lazy(() => import("./MyOrders/MyOrders"));
const Mybarn = React.lazy(() => import("./ProfilePage/Mybarn/Mybarn"));
const ShippingAddress = React.lazy(() =>
  import("./ProfilePage/ShippingAddress/ShippingAddress")
);
const ErrorPage = React.lazy(() =>
  import("../modules/UtilitiesPage/UtilitiesPage")
);
const ResolutionCenter = React.lazy(() =>
  import("./ResolutionCenter/ResolutionCenterHome/ResolutionCenterHome")
);
const ReportItemPage = React.lazy(() =>
  import("./ResolutionCenter/ReportItem/ReportItemPage")
);
const ReportItemConfirmPage = React.lazy(() =>
  import("./ResolutionCenter/ReportItemConfirm/ReportItemConfirm")
);
const SellerHelpPage = React.lazy(() =>
  import("../modules/SellerHelpPage/SellerHelpPage")
);
const BuyerHelpPage = React.lazy(() =>
  import("../modules/BuyerHelpPage/BuyerHelpPage")
);
const SellerPage = React.lazy(() => import("../modules/SellerPage/SellerPage"));
const SellerRegisterPage = React.lazy(() =>
  import("../modules/SellerRegisterPage/SellerRegisterPage")
);
const SingleCase = React.lazy(() =>
  import("../modules/ResolutionCenter/SingleCase/SingleCase")
);
const UserAgreement = React.lazy(() =>
  import("../modules/UserAgreement/UserAgreement")
);
const CheckoutSuccess = React.lazy(() =>
  import("./ResultsPage/CheckoutSuccess")
);
const CheckoutFail = React.lazy(() => import("./ResultsPage/CheckoutFail"));
const PageNotFound = React.lazy(() => import("./ResultsPage/PageNotFound"));
const MobileApp = React.lazy(() => import("./StaticPages/MobileApp"));
const HelpPage = React.lazy(() => import("./StaticPages/HelpPage"));
const TermAndConditions = React.lazy(() =>
  import("./StaticPages/TermAndConditions")
);
const TermAndConditionsOnShop = React.lazy(() =>
  import("./CustomerService/TermAndConditionsOnShop")
);
const CardsAddPage = React.lazy(() => import("./CardsAddPage/CardsAddPage"));
const CompanyInforAndPolicy = React.lazy(() =>
  import("./CustomerService/CompanyInforAndPolicy")
);
const AcceptedPaymentsPolicy = React.lazy(() =>
  import("./CustomerService/AcceptedPaymentsPolicy")
);
const IntellectualPropertyRules = React.lazy(() =>
  import("./CustomerService/IntellectualPropertyRules")
);
const RealEstatePolicy = React.lazy(() =>
  import("./CustomerService/RealEstatePolicy")
);
const TaxPolicy = React.lazy(() => import("./CustomerService/TaxPolicy"));
const IdentityScreeningPolicy = React.lazy(() =>
  import("./CustomerService/IdentityScreeningPolicy")
);
const FraudPolicy = React.lazy(() => import("./CustomerService/FraudPolicy"));
const FinalValueFees = React.lazy(() =>
  import("./CustomerService/FinalValueFees")
);
const OnlineAdvertisingPolicy = React.lazy(() =>
  import("./CustomerService/OnlineAdvertisingPolicy")
);
const PrivacyPolicy = React.lazy(() =>
  import("./CustomerService/PrivacyPolicy")
);
const PrivacyPolicyOnShop = React.lazy(() =>
  import("./CustomerService/PrivacyPolicyOnShop")
);
const ChangePassword = React.lazy(() =>
  import("../components/Buyer/BuyerProfile/ChangePassword/ChangePassword")
);
const BuyerRegistration = React.lazy(() =>
  import("./SupportCenter/BuyerRegistration")
);
const SellerRegistration = React.lazy(() =>
  import("./SupportCenter/SellerRegistration")
);
const ProductListing = React.lazy(() =>
  import("./SupportCenter/ProductListing")
);
const ListingCondition = React.lazy(() =>
  import("./SupportCenter/ListingCondition")
);
const DashboardFeatures = React.lazy(() =>
  import("./SupportCenter/DashboardFeatures")
);
const ResolutionCenterHelp = React.lazy(() =>
  import("./SupportCenter/ResolutionCenterHelp")
);
const FAQ = React.lazy(() => import("./FAQ/FAQ"));

export default {
  //seller-dashboard
  DashboardView: DashboardPage,

  //seller
  SellerView: SellerPage,
  SellerRegisterView: SellerRegisterPage,
  UserAgreementView: UserAgreement,

  //buyer
  AllProductsView: AllProducts,
  HomeView: Home,
  CartView: Cart,
  CheckoutView: Checkout,
  CheckoutSuccessView: CheckoutSuccess,
  CheckoutFailView: CheckoutFail,
  ProfileView: ProfilePage,
  LoginView: Login,
  RegisterView: Register,
  ForgetPasswordView: ForgetPassword,
  VerifyResetPasswordView: VerifyResetPassword,
  PolicyView: PolicyPage,
  SellerStoreView: SellerStore,
  SingleProductView: SingleProduct,
  WishlistView: Wishlist,
  ChatView: ChatPage,
  AccountView: AccountPage,
  ChangePassword: ChangePassword,
  OrderView: MyOrders,
  MybarnView: Mybarn,
  DeleteOrderView: DeleteOrderPage,
  ShippingAddressView: ShippingAddress,
  CardAddView: CardsAddPage,
  NatificationView: NatificationPage,
  OrderTrackView: OrderTrackPage,
  ErrorView: ErrorPage,
  ResolutionCenterView: ResolutionCenter,
  SingleCaseView: SingleCase,
  ReportItemView: ReportItemPage,
  ReportItemConfirmView: ReportItemConfirmPage,
  SellerHelpView: SellerHelpPage,
  BuyerHelpView: BuyerHelpPage,
  PageNotFoundView: PageNotFound,
  MobileAppView: MobileApp,
  HelpPageView: HelpPage,
  TermAndConditionsView: TermAndConditions,
  TermAndConditionsViewOnShop: TermAndConditionsOnShop,
  CompanyInforAndPolicyView: CompanyInforAndPolicy,
  AcceptedPaymentsPolicyView: AcceptedPaymentsPolicy,
  IntellectualPropertyRulesView: IntellectualPropertyRules,
  RealEstatePolicyView: RealEstatePolicy,
  TaxPolicyView: TaxPolicy,
  IdentityScreeningPolicyView: IdentityScreeningPolicy,
  FraudPolicyView: FraudPolicy,
  FinalValueFeesView: FinalValueFees,
  OnlineAdvertisingPolicyView: OnlineAdvertisingPolicy,
  PrivacyPolicyView: PrivacyPolicy,
  PrivacyPolicyViewOnShop: PrivacyPolicyOnShop,

  // Suport-center
  BuyerRegistrationView: BuyerRegistration,
  SellerRegistrationView: SellerRegistration,
  ProductListingView: ProductListing,
  ListingConditionView: ListingCondition,
  DashboardFeaturesView: DashboardFeatures,
  ResolutionCenterHelpView: ResolutionCenterHelp,
  FAQView: FAQ,
};
