import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table, Input, Popconfirm, Form, Button } from "antd";
import { userRoles } from "../../../constants/Utilities";
import { productManagementActions } from "../../../modules/SellerDashboard/ProductManagement/ducks";
// import "./ProductQnAList.less";

const EditableContext = React.createContext();

const { TextArea } = Input;

const data = [];
for (let i = 0; i < 100; i++) {
  data.push({
    objectID: i.toString(),
    question: `Edrward ${i}`,
    age: 32,
    answer: `London Park no. ${i}`,
  });
}
class EditableCell extends React.Component {
  getInput = () => {
    return <TextArea rows={4} />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class ProductQnAList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data, editingKey: "", hits: null, isAdminRole: false };
    this.columns = [
      {
        title: "Product",
        width: "110px",
        render: (text, record) => (
          <React.Fragment>
            <img
              alt="avatar"
              src={record.product && record.product.img && record.product.img}
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "5px",
                display: "inline-block",
              }}
            />
          </React.Fragment>
        ),
      },
      {
        title: "Name",
        render: (text, record) => (
          <React.Fragment>
            {record.product && record.product.name}
          </React.Fragment>
        ),
      },
      {
        title: "Question",
        width: "20%",
        render: (text, record) => (
          <React.Fragment>
            {record.question ? record.question : "-"}
          </React.Fragment>
        ),
      },
      {
        title: "Answer",
        dataIndex: "answer",
        width: "20%",
        editable: true,
      },
      {
        title: "Operation",
        dataIndex: "operation",
        width: "135px",
        render: (text, record) => {
          const { editingKey, isAdminRole } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    block
                    className="gx-btn-outline-success mb-2 mr-0"
                    onClick={() => this.save(form, record.objectID)}
                    style={{ marginRight: 8 }}
                  >
                    <i className="fad fa-save mr-1"></i>
                    Save
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.cancel(record.objectID)}
              >
                <Button block className="gx-btn-outline-danger mb-0 mr-0">
                  <i className="fad fa-times mr-1"></i>
                  Cancel
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <React.Fragment>
              {record.answered ? (
                <Button
                  block
                  className="gx-btn-outline-secondary mb-0 mr-0"
                  disabled={editingKey !== ""}
                  onClick={() => this.edit(record.objectID)}
                >
                  <i className="fad fa-sync-alt mr-1"></i>
                  Update
                </Button>
              ) : (
                <Button
                  block
                  className="gx-btn-outline-primary mb-0 mr-0"
                  disabled={editingKey !== ""}
                  onClick={() => this.edit(record.objectID)}
                >
                  <i className="fad fa-pencil-alt mr-1"></i>
                  Answer
                </Button>
              )}

              <br />
              {isAdminRole ? (
                <React.Fragment>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => this.cancel(record.objectID)}
                  >
                    <Button className="gx-btn-danger mb-2 mr-0">
                      <i className="fad fa-trash-alt mr-1"></i>
                      Delete
                    </Button>
                  </Popconfirm>
                  <br />
                  <Popconfirm
                    title="Sure to delete reply?"
                    onConfirm={() => this.cancel(record.objectID)}
                  >
                    <Button className="gx-btn-danger mb-0 mr-0">
                      <i className="fad fa-trash-alt mr-1"></i>
                      Delete Reply
                    </Button>
                  </Popconfirm>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          );
        },
      },
    ];
  }

  componentDidMount() {
    // getter
    var user = localStorage.getItem("user");
    var userJson = JSON.parse(user);
    var role = null;
    role = userJson && userJson.role;
    var isAdminRole =
      role === userRoles.Moderator || role === userRoles.Admin ? true : false;
    this.setState({ userJson, isAdminRole });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.hits &&
      JSON.stringify(this.props.hits) !== JSON.stringify(prevProps.hits)
    ) {
      this.setState({
        hits: this.props.hits,
      });
    }
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };

  isEditing = (record) => record.objectID === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      var answerDto = {
        answer: row.answer,
      };
      const newData = [...this.state.hits];
      const index = newData.findIndex((item) => key === item.objectID);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.props.productManagementActions.replyToQnA({
          id: key,
          answerDto,
          callback: this.updateQnAHandler,
        });
        this.setState({ hits: newData, editingKey: "" });
      } else {
        newData.push(row);
        this.setState({ hits: newData, editingKey: "" });
      }
    });
  }

  updateQnAHandler = (id) => {
    const { hits } = this.state;
    let prevHits = Object.assign([], hits);
    prevHits.forEach((question, index) => {
      if (question.objectID === id && !question.answered) {
        question.answered = true;
      }
    });
    this.setState({ hits: prevHits });
  };

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    let { hits } = this.state;
    const { replyQnA } = this.props;
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <div className="gx-card p-4 mb-2">
        <EditableContext.Provider value={this.props.form}>
          <Table
            loading={replyQnA.pending}
            dataSource={hits}
            components={components}
            style={{ minWidth: "500px" }}
            columns={columns}
            onChange={this.handleChange}
            rowClassName="product-qna-editable-row"
            pagination={false}
            bordered
          />
        </EditableContext.Provider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    replyQnA: state.ProductManagement.replyQnA,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    productManagementActions: bindActionCreators(
      productManagementActions,
      dispatch
    ),
  };
}

const WrappedProductQnAList = Form.create()(ProductQnAList);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WrappedProductQnAList)
);
