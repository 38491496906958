import React from "react";
import { IS_INTERNATIONAL_MODE } from "../../../../../../constants";

const ShippingOptions = ({ product }) => {
  let data = product && product.data && product.data.data;
  return (
    <div className="gx-card p-3 mb-0">
      <div>
        <h6>Item location</h6>
        <div>
          {data && data.city && <div>City : {data.city}</div>}
          {data && data.country && <div>Country : {data.country} </div>}
        </div>
      </div>

      <div>
        {IS_INTERNATIONAL_MODE
          ? "Shipping to : World wide"
          : "Delivered to : Island wide"}
        <br />
        {data?.excluded_shipping_countries?.length !== 0 && (
          <>
            Except :
            {data &&
              data?.excluded_shipping_countries.map((country, idx, arr) => {
                const terminator = idx === arr.length - 1 ? "." : ", ";
                return <span>{country + terminator}</span>;
              })}
          </>
        )}
      </div>
    </div>
  );
};

export default ShippingOptions;
