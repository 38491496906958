import React from "react";
import { createLogic } from "redux-logic";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../util/EndPoints";
import API from "../../../util/HTTPClient";
import { reset } from "redux-form";
import IntlMessages from "util/IntlMessages";
import { NotificationManager } from "react-notifications";
import { SUCCESS_TOASTER_TIMEOUT } from "../../../constants";

const getProfileDetails = createLogic({
  type: types.GET_PROFILE_DETAILS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.GET_PROFILE)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getProfileDetailsSuccess(data));
      })
      .catch((err) => {
        let errMessage = (err.response &&
          err.response.data &&
          err.response.data.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.getProfileDetailsFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addShippingAddress = createLogic({
  type: types.ADD_SHIPPING_ADDRESS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.SHIPPING_ADDRESS, action.payload.shippingInfoDto)
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = data.message || (
          <IntlMessages id="notification.profilePage.service.addShippingAddress.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.profilePage.service.addShippingAddress.success.title" />, 
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.addShippingAddressSuccess(data));
      })
      .catch((err) => {
        var errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.profilePage.service.addShippingAddress.fail.content" />
        );
        dispatch(
          actions.addShippingAddressFail({
            title: (
              <IntlMessages id="notification.profilePage.service.addShippingAddress.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getShippingAddress = createLogic({
  type: types.GET_SHIPPING_ADDRESS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.SHIPPING_ADDRESS)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getShippingAddressSuccess(data));
      })
      .catch((err) => {
        let errMessage = (err.response &&
          err.response.data &&
          err.response.data.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );

        dispatch(
          actions.getShippingAddressFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const updateShippingAddress = createLogic({
  type: types.UPDATE_SHIPPING_ADDRESS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Put(
      endPoints.SHIPPING_ADDRESS + `/${action.payload.shipping_address_id}`,
      action.payload.shippingInfoDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = data.message || (
          <IntlMessages id="notification.profilePage.service.updateShippingAddress.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.profilePage.service.updateShippingAddress.success.title" />, 
          SUCCESS_TOASTER_TIMEOUT
        );

        dispatch(
          actions.updateShippingAddressSuccess({
            data: data,
            updatedData: action.payload.shippingInfoDto,
            updatedID: action.payload.shipping_address_id,
          })
        );
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.profilePage.service.updateShippingAddress.fail.content" />
        );
        dispatch(
          actions.updateShippingAddressFail({
            title: (
              <IntlMessages id="notification.profilePage.service.updateShippingAddress.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const deleteShippingAddress = createLogic({
  type: types.DELETE_SHIPPING_ADDRESS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Delete(
      endPoints.SHIPPING_ADDRESS + `/${action.payload.shipping_address_id}`
    )
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = data.message || (
          <IntlMessages id="notification.profilePage.service.deleteShippingAddress.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.profilePage.service.deleteShippingAddress.success.title" />, 
          SUCCESS_TOASTER_TIMEOUT
        );

        dispatch(
          actions.deleteShippingAddressSuccess({
            data: data,
            updatedData: action.payload.shippingInfoDto,
            updatedID: action.payload.shipping_address_id,
          })
        );
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.profilePage.service.deleteShippingAddress.fail.content" />
        );
        dispatch(
          actions.deleteShippingAddressFail({
            title: (
              <IntlMessages id="notification.profilePage.service.deleteShippingAddress.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addOrderReview = createLogic({
  type: types.ADD_ORDER_REVIEW,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.ORDERREVIEW, action.payload.orderReview)
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = data.message || (
          <IntlMessages id="notification.profilePage.service.review.success.title" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.profilePage.service.review.success.title" />, 
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.addOrderReviewSuccess(data));
        action.payload.callback();
        dispatch(reset("OrderReviewModalBody"));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.profilePage.service.review.fail.content" />
        );
        dispatch(
          actions.addOrderReviewFail({
            title: (
              <IntlMessages id="notification.profilePage.service.review.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getBuyerOrderCount = createLogic({
  type: types.GET_BUYER_ORDER_COUNT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.GET_BUYER_ORDER_COUNT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getBuyerOrderCountSuccess(data));
      })
      .catch((err) => {
        var { data } = err.response;
        var errMessage = (data && data.message) || "Something went wrong..!";
        dispatch(
          actions.getBuyerOrderCountFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [
  getProfileDetails,
  addShippingAddress,
  getShippingAddress,
  updateShippingAddress,
  deleteShippingAddress,
  addOrderReview,
  getBuyerOrderCount,
];
