// Reducers: combine all reducers of the app
import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
// import { connectRouter } from "connected-react-router";

// ***** Import the reducers *****
import authStore from "./modules/Auth/ducks";
// import dashboard from "./routes/Dashboard/ducks";
import home from "./modules/Home/ducks";
import singleProduct from "./modules/SingleProduct/ducks";
import sellerStore from "./modules/SellerStore/ducks";
import cart from "./modules/Cart/ducks";
import profile from "./modules/ProfilePage/ducks";
import resolutionCenter from "./modules/ResolutionCenter/ducks";
import chat from "./modules/ChatPage/ducks";
import customerService from "./modules/StaticPages/ducks"

import feedBack from "./modules/CustomerService/ducks"

//seller-dashboard
import sellerDashboard from "./modules/Dashboard/ducks";
import orderManagement from "./modules/SellerDashboard/OrderManagement/ducks";
import productManagement from "./modules/SellerDashboard/ProductManagement/ducks";
import dashboardProfile from "./modules/SellerDashboard/SellerAccount/ducks";
import reviewManagement from "./modules/SellerDashboard/ReviewManagement/ducks";
import reportManagement from "./modules/SellerDashboard/ReportManagement/ducks";
import financeManagement from "./modules/SellerDashboard/Finance/ducks";
//Moderator and admin Dashboard
import UserManagement from "./modules/SellerDashboard/UserManagement/ducks";

import ErrorManager from "./modules/ErrorManager/ducks";

//credit card detail
import credirCardDetail from "./modules/CardsAddPage/ducks";
import domesticShipping from "./modules/DomesticShipping/ducks";



export default combineReducers({
  // router: connectRouter(history),
  form: reduxFormReducer,
  AuthStore: authStore,
  Cart: cart,
  Chat: chat,
  DashboardProfile: dashboardProfile,
  ErrorManager: ErrorManager,
  Home: home,
  ProductManagement: productManagement,
  Profile: profile,
  SingleProduct: singleProduct,
  SellerStore: sellerStore,
  SellerDashboard: sellerDashboard,
  OrderManagement: orderManagement,
  ResolutionCenter: resolutionCenter,
  ReviewManagement: reviewManagement,
  ReportManagement: reportManagement,
  UserManagement: UserManagement,
  FinanceManagement: financeManagement,
  CredirCardDetail: credirCardDetail,
  CustomerService: customerService,
  FeedBack: feedBack,
  Domesticshipping: domesticShipping


});

// export default createRootReducer;
