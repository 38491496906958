import React from "react";
import { createLogic } from "redux-logic";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../util/EndPoints";
import API from "../../../util/HTTPClient";
import { NotificationManager } from "react-notifications";
import { GetAllDiscticts } from "../../../firebase/functions";
import IntlMessages from "util/IntlMessages";
import { cartActions } from "../../Cart/ducks";

//get single product
const getDomesticLocations = createLogic({
  type: types.GET_DOMESTIC_LOCATIONS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    GetAllDiscticts()
      .then((data) => {
        let allDomesticLocations = [];
        data.forEach((district, index) => {
          allDomesticLocations.push({
            label: district,
            value: district,
            isLeaf: false,
          });
        });
        dispatch(actions.getDomesticLocationsSuccess(allDomesticLocations));
      })
      .catch((err) => {
        let errMessage = err || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        NotificationManager.error(errMessage, "Get Domestic Locations Fail");
        dispatch(
          actions.getDomesticLocationsFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getEstimatedShippingCost = createLogic({
  type: types.GET_ESTIMATED_SHIPPING_COST,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(
      `${endPoints.GET_ESTIMATED_SHIPPING_COST}/country/?product_id=${action.payload.product_id}&delivery_area_id=${action.payload.deliveryAreaId}`
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(cartActions.domesticProductUpdate(data.data?.rates[0] || []));
        dispatch(actions.getEstimatedShippingCostSuccess(data));
      })
      .catch((err) => {
        let errMessage = (err.response &&
          err.response.data &&
          err.response.data.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.getEstimatedShippingCostFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

//get related products for product

export default [getDomesticLocations, getEstimatedShippingCost];
