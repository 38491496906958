import React from "react";
import { createLogic } from "redux-logic";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../util/EndPoints";
import API from "../../../util/HTTPClient";
import { NotificationManager } from "react-notifications";
import { reset } from "redux-form";
import IntlMessages from "util/IntlMessages";
import { SUCCESS_TOASTER_TIMEOUT } from "../../../constants";

const addContactDetails = createLogic({
  type: types.ADD_CONTACT_DETAILS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    // debugger;
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.ADD_CONTACT, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = data.message || (
          <IntlMessages id="notification.staticPage.service.contactus.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.staticPage.service.contactus.success.title" />, 
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.addContactDetailsSuccess(data));
        dispatch(reset("HelpAndContact"));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.staticPage.service.contactus.fail.content" />
        );

        dispatch(
          actions.addContactDetailsFail({
            title: (
              <IntlMessages id="notification.staticPage.service.contactus.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [addContactDetails];
