import React, { useState } from "react";
import history from "../../_helpers/history";
import { connectAutoComplete, Highlight } from "react-instantsearch-dom";
import { Input } from "antd";

const Search = Input.Search;

const SearchBox = ({ styleName, placeholder }) => {
  return (
    <div className={`gx-search-bar ${styleName}`}>
      <div className="gx-form-group">
        <ConnectedSearchBoxMobileView placeholder={placeholder} />
      </div>
    </div>
  );
};

const CustomSearchBox = ({
  hits,
  currentRefinement,
  refine,
  placeholder,
  onChange,
  value,
}) => {
  const [CategorySetState] = useState(null);
  const [showResults, setShowResults] = useState(false);

  const Show = () => {
    setShowResults(true);
  };

  const hide = () => {
    setShowResults(false);
  };

  return (
    <>
      <div>
        <Search
          onMouseEnter={Show}
          className="ant-input"
          // addonBefore={
          //   <CategorySelector
          //     allCategories={allCategories}
          //     onSelectCategory={handleCategory}
          //   />
          // }
          value={currentRefinement}
          onChange={(e) => {
            refine(e.target.value);
            Show();
          }}
          placeholder={placeholder}
          autoComplete="off"
          onSearch={(value) =>
            CategorySetState && CategorySetState !== "All Categories"
              ? history.push(`/search/${CategorySetState}?query=${value}`)
              : history.push(`/search?query=${value}`)
          }
        />

        {/* <input
          className="ant-input"
          type="search"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        /> */}
        {/* <span className="gx-search-icon gx-pointer">
          <i className="icon icon-search" />
        </span> */}
      </div>

      {showResults && currentRefinement && hits && hits.length !== 0 ? (
        <div
          onMouseEnter={Show}
          onMouseLeave={hide}
          style={{
            position: "absolute",
            zIndex: 100,
            width: "650px",
            background: "white",
            boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
          className="vsbl-in-web-2"
        >
          <div className="search-reslt-menu">
            <table style={{ width: "100%" }}>
              <tbody>
                {hits.map((hit, idx) => (
                  <tr
                    onClick={() => history.push(`/product/${hit.id}`)}
                    className="search-res-itm"
                  >
                    <td style={{ width: "60px", padding: "3px 4px" }}>
                      <img
                        src={hit.images[0]}
                        alt="searchBox"
                        style={{
                          height: "100%",
                          width: "60px",
                          maxHeight: "80px",
                          objectFit: "contain",
                        }}
                      />
                    </td>
                    <td style={{ padding: "3px" }}>
                      <div className="hit hit-jr">
                        <Highlight attribute="name" hit={hit} key={idx} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
};

const ConnectedSearchBoxMobileView = connectAutoComplete(CustomSearchBox);

export default SearchBox;

SearchBox.defaultProps = {
  styleName: "",
  value: "",
};
