import React from "react";
import { createLogic } from "redux-logic";
import { NotificationManager } from "react-notifications";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../../util/EndPoints";
import API from "../../../../util/HTTPClient";
import { ResetIdToken } from "../../../../services/auth.service";
import IntlMessages from "util/IntlMessages";
import { SUCCESS_TOASTER_TIMEOUT } from "../../../../constants";

// ********************************************//
const getSellerDetails = createLogic({
  type: types.GET_SELLER_DETAILS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.SELLER_DETAILS)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getSellerDetailsSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );

        dispatch(
          actions.getSellerDetailsFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});
// ********************************************//
// ********************************************//
const updateSellerBusinessInfo = createLogic({
  type: types.UPDATE_SELLER_BUSINESS_INFO,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.UPDATE_SELLER_BUSINESS_INFO, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = (data && data.message) || (
          <IntlMessages id="notification.sellerAccount.service.businessInfoUpdate.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.sellerAccount.service.businessInfoUpdate.success.title" />, 
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.updateSellerBusinessInfoSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.sellerAccount.service.businessInfoUpdate.fail.content" />
        );
        dispatch(
          actions.updateSellerBusinessInfoFail({
            title: (
              <IntlMessages id="notification.sellerAccount.service.businessInfoUpdate.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});
// ********************************************//
// ********************************************//
const updateSellerInfo = createLogic({
  type: types.UPDATE_SELLER_INFO,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.UPDATE_SELLER_INFO, action.payload)
      .then((resp) => resp.data)
      .then(async (data) => {
        await ResetIdToken();
        return data;
      })
      .then((data) => {
        const successMessage = (data && data.message) || (
          <IntlMessages id="notification.sellerAccount.service.infoUpdate.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.sellerAccount.service.infoUpdate.success.title" />, 
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.updateSellerInfoSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.sellerAccount.service.infoUpdate.fail.content" />
        );
        dispatch(
          actions.updateSellerInfoFail({
            title: (
              <IntlMessages id="notification.sellerAccount.service.infoUpdate.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});
// ********************************************//
// ********************************************//
const updateSellerPayment = createLogic({
  type: types.UPDATE_SELLER_PAYMENT_INFO,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.UPDATE_SELLER_PAYMENT_INFO, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = (data && data.message) || (
          <IntlMessages id="notification.sellerAccount.service.paymentInfoUpdate.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.sellerAccount.service.paymentInfoUpdate.success.title" />, 
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.updateSellerPaymentSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.sellerAccount.service.paymentInfoUpdate.fail.content" />
        );
        dispatch(
          actions.updateSellerPaymentFail({
            title: (
              <IntlMessages id="notification.sellerAccount.service.paymentInfoUpdate.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});
// ********************************************//

export default [
  getSellerDetails,
  updateSellerBusinessInfo,
  updateSellerInfo,
  updateSellerPayment,
];
