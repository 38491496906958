import { Col, Row } from "antd";
import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

function BannerCarouselMobile({ data }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="gx-container pr-0 pl-0">
      <Row>
        <Col span={24} className="">
          <div
            className="gx-card mb-2"
            style={{
              overflow: "hidden",
              borderTopRightRadius: "0px",
              borderTopLeftRadius: "0px",
            }}
          >
            <Carousel
              fade={false}
              activeIndex={index}
              onSelect={handleSelect}
              className="caro-mobile"
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.048)" }}
            >
              <Carousel.Item>
                <img
                  className="d-block mx-auto"
                  src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new.jpg`)}
                  alt="Moto 360"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block mx-auto"
                  src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new-2.jpg`)}
                  alt="Moto 360"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block mx-auto"
                  src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new-3.jpg`)}
                  alt="Moto 360"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block mx-auto"
                  src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new-4.jpg`)}
                  alt="Moto 360"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block mx-auto"
                  src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new-5.jpg`)}
                  alt="Moto 360"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default BannerCarouselMobile;
