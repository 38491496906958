import React from "react";
import { Modal } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { singleProductActions } from "../../../../modules/SingleProduct/ducks";
import { cartActions } from "../../../../modules/Cart/ducks";
import ShippingOptions from "./ShippingOptions";
import { byIso } from "country-code-lookup";

import "./ShippingInfoModal.less";
import {
  DEFAULT_CURRENCY,
  IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST,
  IS_ENABLED_SHIPPING_COST_API,
} from "../../../../constants";

class ShippingInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItemIndex: null,
    };
  }
  componentDidMount() {
    const { product_id, match, myCart } = this.props;
    const { path } = match;
    var cartItemIndex = null;
    if (path === "/cart") {
      cartItemIndex = myCart.items.findIndex((item) => item.id === product_id);
    }
    this.setState({ cartItemIndex });
  }
  state = {
    modal1Visible: false,
    modal2Visible: false,
  };

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });

    //get shipping cost
    const { product_id, shippingCost } = this.props;
    let deliveryAreaId =
      shippingCost?.data?.data?.shipping_address?.delivery_area_id || null;

    IS_ENABLED_SHIPPING_COST_API &&
      !shippingCost.loading &&
      !shippingCost.hasError &&
      this.props.cartActions.getShippingCost({
        id: product_id,
        isCart: true,
        deliveryAreaId: deliveryAreaId,
      });
  }

  render() {
    const {
      // selectedShippingMethod,
      shippingCost,
      data,
    } = this.props;
    const { selectedShippingMethod } = data;

    let userSelections = data?.userSelections;
    let shippingDetails =
      !shippingCost.loading && shippingCost.data && shippingCost.data.data;
    let shippingCountry =
      shippingDetails &&
      shippingDetails.shipping_address &&
      shippingDetails.shipping_address.country;

    let totalShippingCost = IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST
      ? parseFloat(selectedShippingMethod?.rate) +
        (parseFloat(userSelections?.quantity) - 1) *
          parseFloat(selectedShippingMethod?.rate_additional_item)
      : parseFloat(selectedShippingMethod?.rate) *
        parseFloat(userSelections?.quantity);

    totalShippingCost = isNaN(totalShippingCost)
      ? 0
      : parseFloat(totalShippingCost).toFixed(2);
    return (
      <React.Fragment>
        {/* Selected shipping Option */}
        <React.Fragment>
          {selectedShippingMethod && !shippingDetails ? (
            <React.Fragment>
              <div>
                Total Shipping :
                <span className="text-clr-org">{`${DEFAULT_CURRENCY} ${totalShippingCost}`}</span>
              </div>

              {+selectedShippingMethod?.rate !== 0 && (
                <div>
                  {IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST
                    ? "Shipping Cost (first unit):"
                    : "Shipping Cost (per unit):"}
                  <span className="text-clr-org">{`${DEFAULT_CURRENCY} ${selectedShippingMethod?.rate}`}</span>
                </div>
              )}
              {IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST &&
                +selectedShippingMethod?.rate_additional_item !== 0 && (
                  <div>
                    Additional Shipping Cost:
                    <span className="text-clr-org">{`${DEFAULT_CURRENCY} ${selectedShippingMethod?.rate_additional_item}`}</span>
                  </div>
                )}
              <div
                className="crd-fnt-jr"
                onClick={() => this.setModal2Visible(true)}
              >
                {`via ${selectedShippingMethod.service_readable_name} `}
                <i
                  className="fad fa-angle-right ml-1"
                  style={{ fontSize: "20px", verticalAlign: "middle" }}
                ></i>
              </div>
              <div>
                {`${
                  selectedShippingMethod.estimated_time !== "Unavailable"
                    ? "Estimated Delivery Time " +
                      selectedShippingMethod.estimated_time
                    : ""
                }`}
              </div>
            </React.Fragment>
          ) : null}
        </React.Fragment>

        {/* Selected shipping Option with address */}
        <React.Fragment>
          {selectedShippingMethod && shippingDetails ? (
            <React.Fragment>
              <div>
                Shipping :
                <span className="text-clr-org">{`${DEFAULT_CURRENCY} ${totalShippingCost}`}</span>
              </div>
              {+selectedShippingMethod?.rate !== 0 && (
                <div>
                  {IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST
                    ? "Shipping Cost (first unit):"
                    : "Shipping Cost (per unit):"}
                  <span className="text-clr-org">{`${DEFAULT_CURRENCY} ${selectedShippingMethod?.rate}`}</span>
                </div>
              )}
              {IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST &&
                +selectedShippingMethod?.rate_additional_item !== 0 && (
                  <div>
                    Additional Shipping Cost:
                    <span className="text-clr-org">{`${DEFAULT_CURRENCY} ${selectedShippingMethod?.rate_additional_item}`}</span>
                  </div>
                )}
              <div
                className="crd-fnt-jr"
                onClick={() => this.setModal2Visible(true)}
              >
                {`via ${selectedShippingMethod.service_readable_name} ${
                  shippingCountry && "to " + byIso(shippingCountry).country
                }`}
                <i
                  className="fad fa-angle-right ml-1"
                  style={{ fontSize: "20px", verticalAlign: "middle" }}
                ></i>
              </div>
              <div>
                {`${
                  selectedShippingMethod.estimated_time !== "Unavailable"
                    ? "Estimated Delivery Time " +
                      selectedShippingMethod.estimated_time
                    : ""
                }`}
              </div>
            </React.Fragment>
          ) : null}
        </React.Fragment>

        {/* when shipping is loading */}
        <React.Fragment>
          {shippingCost.pending ? <div>{" Loading..."}</div> : null}
        </React.Fragment>

        {/* if shipping is un-available -> show the message */}
        <React.Fragment>
          {shippingDetails && !shippingDetails.shipping_available ? (
            <div>
              <div>{shippingDetails && shippingDetails.message}</div>
            </div>
          ) : null}
        </React.Fragment>
        <Modal
          title="Shipping Methods"
          wrapClassName="vertical-center-modal catch-tag-model"
          visible={this.state.modal2Visible}
          onOk={() => this.setModal2Visible(false)}
          onCancel={() => this.setModal2Visible(false)}
        >
          <ShippingOptions
            product_id={this.props.product_id}
            selectedShippingMethod={selectedShippingMethod}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    shippingCost: state.Cart.shippingCost,
    product: state.Cart.product,
    myCart: state.Cart.myCart,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    singleProductActions: bindActionCreators(singleProductActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingInfoModal)
);
