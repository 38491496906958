import React from "react";
import "./Loader.less";
function Loader() {
  return (
    <div id="overlay" style={{ zIndex: 9999 }}>
      <div className="center-loader">
        <div className="seven dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
