import { IS_INTERNATIONAL_MODE, IS_INTERNATIONAL_SHIPPING } from "../constants";

export const FormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 6 },
    xl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 18 },
    xl: { span: 18 },
  },
};

export const flJr = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 6 },
    xl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 18 },
    xl: { span: 18 },
  },
};

export const ItemFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 16 },
    xl: { span: 16 },
  },
};

export const initializeObj = {
  imageEnabledVariation: "default",
  isProductVariable: "disable",
  internationalShippingTrackingAvailable: "disable",
  domesticShippingTrackingAvailable: "disable",
  excludeShippingLocations: [],
  internationalShippingCarriers: [],
  domesticShippingMethod: "free_shipping",
  internationalShippingMethod: IS_INTERNATIONAL_SHIPPING
    ? "calculated"
    : "no_shipping",
  returnAcceptedMethod: "paid_by_seller",
  packageType: IS_INTERNATIONAL_MODE ? "letter" : "small_package",
  warranty: "no_warranty",
  productCondition: "New",
  dangerousGoods: [],
  packageWieghtMeasurement: process.env.REACT_APP_DEFAULT_WEIGHT_MEASUREMENT,
  packageLengthMeasurement: process.env.REACT_APP_DEFAULT_LENGTH_MEASUREMENT,
};

export const dangerousGoods = [
  {
    value: "battery",
    label: "Battery",
  },
  {
    value: "dry_ice",
    label: "Dry ice",
  },
  {
    value: "alcohol",
    label: "Alcohol",
  },
  {
    value: "liquid",
    label: "Liquid",
  },
  {
    value: "flammable",
    label: "Flammable",
  },
];
