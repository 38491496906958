import React from "react";
import { createLogic } from "redux-logic";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../util/EndPoints";
import API from "../../../util/HTTPClient";
import { NotificationManager } from "react-notifications";
import history from "../../../_helpers/history";
import { reset } from "redux-form";
import IntlMessages from "util/IntlMessages";
import { SUCCESS_TOASTER_TIMEOUT } from "../../../constants";

const openNewCase = createLogic({
  type: types.OPEN_NEW_CASE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.RESOLUTION, action.payload.reportItemDto)
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = data.message || (
          <IntlMessages id="notification.resolutionCenter.service.openCase.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.resolutionCenter.service.openCase.success.title" />,
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.openNewCaseSuccess(data));
        //  wait 2 seconds
        setTimeout(function () {
          localStorage.removeItem("rcr_item");
          if (action.payload.isDashboard) {
            history.push("/seller/dashboard/resolution-center/all");
          } else {
            history.push("/resolution-center/all");
          }
          dispatch(reset("ReturnItemForm"));
        }, 2000);
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.resolutionCenter.service.openCase.fail.content" />
        );
        dispatch(
          actions.openNewCaseFail({
            title: (
              <IntlMessages id="notification.resolutionCenter.service.openCase.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getCase = createLogic({
  type: types.GET_CASE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.RESOLUTION + `/${action.payload.id}`)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getCaseSuccess(data));
      })
      .catch((err) => {
        let errMessage = (err.response &&
          err.response.data &&
          err.response.data.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.getCaseFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const updateCase = createLogic({
  type: types.UPDATE_CASE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }

    HTTPClient.Put(
      endPoints.RESOLUTION + `/${action.payload.caseID}`,
      action.payload.updateDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = data.message || (
          <IntlMessages id="notification.resolutionCenter.service.updatecase.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.resolutionCenter.service.updatecase.success.title" />,
          SUCCESS_TOASTER_TIMEOUT
        );
        setTimeout(function () {
          localStorage.removeItem("rcr_item");
          if (action.payload.isDashboard) {
            history.push("/seller/dashboard/resolution-center/all");
          } else {
            history.push("/resolution-center/all");
          }
          dispatch(reset("updateCaseForm"));
        }, 2000);
        dispatch(actions.updateCaseSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.resolutionCenter.service.updatecase.fail.content" />
        );
        if (err && (err.status === 500 || err.status === 524)) {
          errMessage = err.message;
        }
        dispatch(
          actions.updateCaseFail({
            title: (
              <IntlMessages id="notification.resolutionCenter.service.updatecase.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getResolutionCenterCases = createLogic({
  type: types.GET_RESOLUTION_CENTER_CASES,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.RESOLUTION_ORDERS)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getResolutionCenterCasesSuccess(data));
      })
      .catch((err) => {
        let errMessage = (err.response &&
          err.response.data &&
          err.response.data.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.getResolutionCenterCasesFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [openNewCase, getCase, updateCase, getResolutionCenterCases];
