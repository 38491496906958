import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table, Input, Popconfirm, Form, Button } from "antd";
import StarRatingComponent from "react-star-rating-component";
import { reviewActions } from "../../../../modules/SellerDashboard/ReviewManagement/ducks";
// import "./ProductReviewList.less";
import { userRoles } from "../../../../constants/Utilities";
import AlgoliaSearch from "../../../Common/Algolia/AlgoliaSearch";
const EditableContext = React.createContext();

const { TextArea } = Input;

const data = [];
for (let i = 0; i < 100; i++) {
  data.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
class EditableCell extends React.Component {
  getInput = () => {
    return <TextArea rows={4} />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class ProductReviewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data, editingKey: "", hits: null, isAdminRole: false };
    this.columns = [
      {
        title: "Product",
        width: "30%",
        render: (text, record) => (
          <React.Fragment>
            <img
              alt="avatar"
              src={record.product && record.product.img && record.product.img}
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "5px",
                marginRight: "10px",
                display: "inline-block",
              }}
            />
            <hr />
            {record.product && record.product.name}
          </React.Fragment>
        ),
      },
      {
        title: "Product Review",
        width: "40%",
        className: "pdct-rw-colm",

        render: (text, record) => (
          <React.Fragment>
            <div className="ant-row-flex">
              <StarRatingComponent
                name=""
                value={record.value}
                starCount={5}
                editing={false}
                emptyStarColor={"#4b505e"}
              />
              <strong className="gx-d-inline-block gx-ml-2">
                {record.value}
              </strong>
            </div>
            {record.content} <br />
            <br />
            <div>
              Reviewer Name : {record.reviewer_name} <br />
              Reviewer Country : {record.reviewer_country} <br />
              Logistics : {record.logistics} <br />
            </div>
          </React.Fragment>
        ),
      },
      {
        title: "Reply",
        dataIndex: "reply",
        className: "pdct-rw-colm",
        editable: true,
      },
      {
        title: "Operation",
        dataIndex: "operation",
        width: "10%",
        render: (text, record) => {
          const { editingKey, isAdminRole } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    block
                    className="gx-btn-outline-success"
                    onClick={() => this.save(form, record.objectID)}
                    style={{ marginRight: 8 }}
                  >
                    <i className="fad fa-save mr-1"></i>
                    Save
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.cancel(record.objectID)}
              >
                <Button block className="gx-btn-outline-danger">
                  <i className="fad fa-times mr-1"></i>
                  Cancel
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <React.Fragment>
              {record.reply ? (
                <Button
                  block
                  className="gx-btn-outline-secondary"
                  disabled={editingKey !== ""}
                  onClick={() => this.edit(record.objectID)}
                >
                  <i className="fad fa-sync-alt mr-1"></i>
                  Update
                </Button>
              ) : (
                <Button
                  block
                  className="gx-btn-outline-primary"
                  disabled={editingKey !== ""}
                  onClick={() => this.edit(record.objectID)}
                >
                  <i className="fad fa-pencil-alt mr-1"></i>
                  Answer
                </Button>
              )}

              <br />
              {isAdminRole ? (
                <React.Fragment>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => this.cancel(record.objectID)}
                  >
                    <Button className="gx-btn-danger">
                      <i className="fad fa-trash-alt mr-1"></i>
                      Delete
                    </Button>
                  </Popconfirm>
                  <br />
                  <Popconfirm
                    title="Sure to delete reply?"
                    onConfirm={() => this.cancel(record.objectID)}
                  >
                    <Button className="gx-btn-danger">
                      <i className="fad fa-trash-alt mr-1"></i>
                      Delete Reply
                    </Button>
                  </Popconfirm>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          );
        },
      },
    ];
  }

  componentDidMount() {
    // getter
    var user = localStorage.getItem("user");
    var userJson = JSON.parse(user);
    var role = null;
    role = userJson && userJson.role;
    var isAdminRole =
      role === userRoles.Moderator || role === userRoles.Admin ? true : false;
    this.setState({ userJson, isAdminRole });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.hits &&
      JSON.stringify(this.props.hits) !== JSON.stringify(prevState.hits)
    ) {
      this.setState({
        hits: this.props.hits,
      });
    }
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };

  exportHandler = () => {
    alert("Downloading...");
  };

  isEditing = (record) => record.objectID === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      var replyDto = {
        reply: row.reply,
      };
      // const newData = [...this.state.data];
      const newData = [...this.state.hits];
      const index = newData.findIndex((item) => key === item.objectID);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.props.reviewActions.replyToReview({ id: key, replyDto });
        this.setState({ hits: newData, editingKey: "" });
      } else {
        newData.push(row);
        this.setState({ hits: newData, editingKey: "" });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    let { hits } = this.state;
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <div className="gx-card p-4 mb-2">
        <AlgoliaSearch />
        <EditableContext.Provider value={this.props.form}>
          <Table
            dataSource={hits}
            components={components}
            style={{ minWidth: "600px" }}
            columns={columns}
            onChange={this.handleChange}
            pagination={false}
            rowClassName="editable-row"
            bordered
          />
        </EditableContext.Provider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportItem: state.Report,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    reviewActions: bindActionCreators(reviewActions, dispatch),
  };
}

const WrappedProductReviewList = Form.create()(ProductReviewList);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WrappedProductReviewList)
);
