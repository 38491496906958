import React from "react";
import { createLogic } from "redux-logic";
import { reset } from "redux-form";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../util/EndPoints";
import API from "../../../util/HTTPClient";
import { NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import { SUCCESS_TOASTER_TIMEOUT } from "../../../constants";

const addFeedback = createLogic({
  type: types.FEEDBACK_FORM_SUBMIT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.ADD_FEEDBACK, action.payload.feedbackDto)
      .then((resp) => resp.data)
      .then((data) => {
        NotificationManager.success(
          <IntlMessages id="notification.customerService.feedback.success.title" />,
          <IntlMessages id="notification.customerService.feedback.success.content" />,
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.feedBackFormSubmitSuccess(data));
        action.payload.callback();
        dispatch(reset("feedback_form"));
      })
      .catch((err) => {
        //debugger
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.customerService.feedback.fail.content" />
        );

        dispatch(
          actions.feedBackFormSubmitFail({
            title: (
              <IntlMessages id="notification.customerService.feedback.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [addFeedback];
