import React from "react";
import { createLogic } from "redux-logic";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../util/EndPoints";
import API from "../../../util/HTTPClient";
import {
  auth,
  facebookAuthProvider,
  appleAuthProvider,
  // githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from "../../../firebase/firebase";
import history from "../../../_helpers/history";
import IntlMessages from "util/IntlMessages";
import { NotificationManager } from "react-notifications";
import { reset } from "redux-form";
import { verifyResetPassword, signOut } from "../../../services/auth.service";
import {
  AUTH_POPUP_CLOSED_BY_USER,
  ERROR_TOASTER_TIMEOUT,
  SUCCESS_TOASTER_TIMEOUT,
} from "../../../constants";

// sign in via google
const signinGoogleUser = createLogic({
  type: types.SIGNIN_GOOGLE_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    auth
      .signInWithPopup(googleAuthProvider)
      .then((authUser) => {
        dispatch(
          actions.getCurrentUser({
            redirect_to: action.payload.redirect_to,
          })
        );
        dispatch(actions.signinUserSuccess(authUser));
      })
      .catch((error) => {
        let errorCode = error?.code;
        let errorMessage = (error && error.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        errorCode !== AUTH_POPUP_CLOSED_BY_USER &&
          NotificationManager.error(
            errorMessage,
            <IntlMessages id="notification.auth.service.error.loginFailed" />,
            ERROR_TOASTER_TIMEOUT
          );

        dispatch(
          actions.signinUserFail({
            title: (
              <IntlMessages id="notification.auth.service.error.loginFailed" />
            ),
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

// sign in via facebook
const signinFacebookUser = createLogic({
  type: types.SIGNIN_FACEBOOK_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    auth
      .signInWithPopup(facebookAuthProvider)
      .then((authUser) => {
        dispatch(
          actions.getCurrentUser({
            redirect_to: action.payload.redirect_to,
          })
        );
        dispatch(actions.signinUserSuccess(authUser));
      })
      .catch((error) => {
        let errorMessage = (error && error.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        NotificationManager.error(
          errorMessage,
          <IntlMessages id="notification.auth.service.error.loginFailed" />,
          ERROR_TOASTER_TIMEOUT
        );

        dispatch(
          actions.signinUserFail({
            title: (
              <IntlMessages id="notification.auth.service.error.loginFailed" />
            ),
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

// sign in via apple
const signinAppleUser = createLogic({
  type: types.SIGNIN_APPLE_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    auth
      .signInWithPopup(appleAuthProvider)
      .then((authUser) => {
        dispatch(
          actions.getCurrentUser({
            redirect_to: action.payload.redirect_to,
          })
        );
        dispatch(actions.signinUserSuccess(authUser));
      })
      .catch((error) => {
        let errorMessage = (error && error.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        NotificationManager.error(
          errorMessage,
          <IntlMessages id="notification.auth.service.error.loginFailed" />,
          ERROR_TOASTER_TIMEOUT
        );

        dispatch(
          actions.signinUserFail({
            title: (
              <IntlMessages id="notification.auth.service.error.loginFailed" />
            ),
            message: error,
          })
        );
      })
      .then(() => done());
  },
});

// sign in via twitter
const signinTwitter = createLogic({
  type: types.SIGNIN_TWITTER_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    auth
      .signInWithPopup(twitterAuthProvider)
      .then((authUser) => {
        dispatch(
          actions.getCurrentUser({
            redirect_to: action.payload.redirect_to,
          })
        );
        dispatch(actions.signinUserSuccess(authUser));
      })
      .catch((error) => {
        let errorMessage = (error && error.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        NotificationManager.error(
          errorMessage,
          <IntlMessages id="notification.auth.service.error.loginFailed" />,
          ERROR_TOASTER_TIMEOUT
        );
        dispatch(
          actions.signinUserFail({
            title: (
              <IntlMessages id="notification.auth.service.error.loginFailed" />
            ),
            message: error,
          })
        );
      })
      .then(() => done());
  },
});

//sign in user via email and password
const signinUser = createLogic({
  type: types.SIGNIN_EMAIL_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    auth
      .signInWithEmailAndPassword(action.payload.email, action.payload.password)
      .then((authUser) => {
        dispatch(
          actions.getCurrentUser({
            redirect_to: action.payload.redirect_to,
          })
        );
        dispatch(actions.signinUserSuccess(authUser));
      })
      .catch((error) => {
        let errorMessage = (
          <IntlMessages id="notification.auth.service.error.usernameOrPasswordWrong" />
        );
        NotificationManager.error(
          errorMessage,
          <IntlMessages id="notification.auth.service.error.loginFailed" />,
          ERROR_TOASTER_TIMEOUT
        );

        dispatch(
          actions.signinUserFail({
            title: "Error!",
            message: error,
          })
        );
      })
      .then(() => done());
  },
});

// logout user
const signoutUser = createLogic({
  type: types.SIGNOUT_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    signOut()
      .then((authUser) => {
        // debugger;
        dispatch(actions.signoutUserSuccess(authUser));
        localStorage.removeItem("user_id");
        localStorage.removeItem("user");

        // debugger;
        if (action.payload.isUpgrade) {
          //navigate to the login
          history.push("/login");
        } else {
          //navigate to the homepage
          history.push("/");
        }
      })
      .catch((error) => {
        dispatch(
          actions.signoutUserFail({
            title: "Error!",
            message: error,
          })
        );
      })
      .then(() => done());
  },
});

//get current user
const getCurrentUser = createLogic({
  type: types.GET_CURRENT_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    // debugger;
    HTTPClient.Get(endPoints.GET_CURRENT_USER)
      .then((resp) => resp.data)
      .then((data) => {
        //user obj -> string
        var userObjString = JSON.stringify(data.data);
        // set current user details
        localStorage.setItem("user", userObjString);
        dispatch(
          actions.getCurrentUserSuccess({
            data: data.data,
          })
        );
        dispatch(reset("Login_form"));

        var redirect_to = action.payload.redirect_to;
        history.push(redirect_to || "/");
      })
      .catch((err) => {
        var data = err.response && err.response.data;
        var errMessage = (data && data.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );

        /**
         * SHOULD BE REFACTOR
         * should be change
         * This is patch solution
         * sometimes this api get unathoticate -400
         * bacause of the not pass accessKey
         */
        //  wait 2 seconds
        // setTimeout(function () {
        //   window.location.reload();
        // }, 2000);

        dispatch(
          actions.getCurrentUserFail({
            title: (
              <IntlMessages id="notification.auth.service.error.loginFailed" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

//sign-up user
const signupUser = createLogic({
  type: types.SIGNUP_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    // debugger;
    HTTPClient.Post(endPoints.SIGN_UP, action.payload.registerDto)
      .then((resp) => resp.data)
      .then((data) => {
        const successMsg = data.message || (
          <IntlMessages id="notification.auth.service.signup.success.content" />
        );
        NotificationManager.success(
          successMsg,
          <IntlMessages id="notification.auth.service.signup.success.title" />,
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.signupUserSuccess(data.data));
        dispatch(reset("Register_form"));
        setTimeout(function () {
          // manually refresh the page naviage home
          window.location.replace("/login");
        }, 1000);
      })
      .catch((err) => {
        if (typeof err === "string" || err instanceof String) {
          NotificationManager.error(
            err,
            <IntlMessages id="notification.auth.service.signup.loginFailed" />,
            ERROR_TOASTER_TIMEOUT
          );
        } else {
          var errMessage = err?.response?.data?.message || (
            <IntlMessages id="notification.auth.service.signup.fail.content" />
          );
        }
        dispatch(
          actions.signupUserFail({
            title: (
              <IntlMessages id="notification.auth.service.signup.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

//upgrade buyer account
const upgradeBuyerAccount = createLogic({
  type: types.UPGRADE_BUYER_ACCOUNT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.UPGRADE_ACCOUNT, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        const successMsg = data.message || (
          <IntlMessages id="notification.auth.service.upgrade.success.content" />
        );
        NotificationManager.success(
          successMsg,
          <IntlMessages id="notification.auth.service.upgrade.success.title" />,
          SUCCESS_TOASTER_TIMEOUT
        );
        NotificationManager.success(
          "Please re-login to the System",
          "Re-Login",
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.upgradeBuyerAccountSuccess(data));
        dispatch(reset("SellerForm"));
        dispatch(actions.signoutUser({ isUpgrade: true }));
      })
      .catch((err) => {
        var errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.auth.service.upgrade.fail.content" />
        );

        if (typeof err === "string" || err instanceof String) {
          NotificationManager.error(
            err,
            <IntlMessages id="notification.auth.service.upgrade.fail.title" />,
            ERROR_TOASTER_TIMEOUT
          );
        }
        dispatch(
          actions.upgradeBuyerAccountFail({
            title: (
              <IntlMessages id="notification.auth.service.upgrade.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

//user register
const sellerRegister = createLogic({
  type: types.SELLER_REGISTER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.REGISTER, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.sellerRegisterSuccess(data));
        const successMsg = data.message || (
          <IntlMessages id="notification.auth.service.seller.register.success.content" />
        );
        NotificationManager.success(
          successMsg,
          <IntlMessages id="notification.auth.service.seller.register.success.title" />,
          SUCCESS_TOASTER_TIMEOUT
        );
        setTimeout(function () {
          // NotificationManager.success(
          //   successMsg,
          //   <IntlMessages id="notification.auth.service.seller.register.success.title" />,
          //   SUCCESS_TOASTER_TIMEOUT
          // );
          history.push("/login");
        }, 3000);
      })
      .catch((err) => {
        var errMsg = err?.response?.data?.message || (
          <IntlMessages id="notification.auth.service.seller.register.fail.content" />
        );
        dispatch(
          actions.sellerRegisterFail({
            title: (
              <IntlMessages id="notification.auth.service.seller.register.fail.title" />
            ),
            message: errMsg,
          })
        );
      })
      .then(() => done());
  },
});

//forgot-password
const forgetPassword = createLogic({
  type: types.FORGET_PASSWORD,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.FORGET_PASSWORD, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        const successMsg = data.message || (
          <IntlMessages id="notification.auth.service.forgotPassword.success.content" />
        );
        NotificationManager.success(
          successMsg,
          <IntlMessages id="notification.auth.service.forgotPassword.success.title" />,
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.forgetPasswordSuccess(data.data));
        setTimeout(function () {
          history.push("/login");
        }, 1000);
      })
      .catch((err) => {
        var errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.auth.service.forgotPassword.fail.content" />
        );
        dispatch(
          actions.forgetPasswordFail({
            title: (
              <IntlMessages id="notification.auth.service.forgotPassword.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

//verify-reset-password
const verifyPassword = createLogic({
  type: types.VERIFY_RESET_PASSWORD,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    verifyResetPassword(action.payload.token, action.payload.password)
      .then((data) => {
        const successMsg = data || (
          <IntlMessages id="notification.auth.service.verifyPassword.success.content" />
        );
        NotificationManager.success(
          successMsg,
          <IntlMessages id="notification.auth.service.verifyPassword.success.title" />,
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.verifyResetPasswordSuccess(data));
        setTimeout(function () {
          history.push("/login");
        }, 1000);
      })
      .catch((err) => {
        var errorMessage = err || (
          <IntlMessages id="notification.auth.service.verifyPassword.fail.content" />
        );
        NotificationManager.error(
          errorMessage,
          <IntlMessages id="notification.auth.service.verifyPassword.fail.title" />,
          ERROR_TOASTER_TIMEOUT
        );
        dispatch(
          actions.verifyResetPasswordFail({
            title: (
              <IntlMessages id="notification.auth.service.verifyPassword.fail.title" />
            ),
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [
  signinGoogleUser,
  signinFacebookUser,
  signinTwitter,
  signinAppleUser,
  signinUser,
  signoutUser,
  getCurrentUser,
  signupUser,
  upgradeBuyerAccount,
  sellerRegister,
  forgetPassword,
  verifyPassword,
];
