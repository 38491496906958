const action_header = "domesticShipping/";
// Types
export default {
  GET_DOMESTIC_LOCATIONS: action_header + "GET_DOMESTIC_LOCATIONS",
  GET_DOMESTIC_LOCATIONS_SUCCESS:
    action_header + "GET_DOMESTIC_LOCATIONS_SUCCESS",
  GET_DOMESTIC_LOCATIONS_FAILED:
    action_header + "GET_DOMESTIC_LOCATIONS_FAILED",

  GET_ESTIMATED_SHIPPING_COST: action_header + "GET_ESTIMATED_SHIPPING_COST",
  GET_ESTIMATED_SHIPPING_COST_SUCCESS:
    action_header + "GET_ESTIMATED_SHIPPING_COST_SUCCESS",
  GET_ESTIMATED_SHIPPING_COST_FAILED:
    action_header + "GET_ESTIMATED_SHIPPING_COST_FAILED",
};
