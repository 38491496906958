// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getDomesticLocations: createAction(types.GET_DOMESTIC_LOCATIONS),
  getDomesticLocationsSuccess: createAction(
    types.GET_DOMESTIC_LOCATIONS_SUCCESS
  ),
  getDomesticLocationsFail: createAction(types.GET_DOMESTIC_LOCATIONS_FAILED),

  getEstimatedShippingCost: createAction(types.GET_ESTIMATED_SHIPPING_COST),
  getEstimatedShippingCostSuccess: createAction(
    types.GET_ESTIMATED_SHIPPING_COST_SUCCESS
  ),
  getEstimatedShippingCostFail: createAction(
    types.GET_ESTIMATED_SHIPPING_COST_FAILED
  ),
};
