export const SLAllCitiesDetails = [
  {
    label: "Ampara",
    value: "Ampara",
    children: [
      {
        label: "Addalaichenai",
        value: "32350",
      },
      {
        label: "Akkaraipattu",
        value: "32400",
      },
      {
        label: "Ampara",
        value: "32400",
      },
      {
        label: "Bakmitiyawa",
        value: "32024",
      },
      {
        label: "Central Camp",
        value: "32050",
      },
      {
        label: "Dadayamtalawa",
        value: "32046",
      },
      {
        label: "Damana",
        value: "32014",
      },
      {
        label: "Damanewela",
        value: "32126",
      },
      {
        label: "Deegawapiya",
        value: "32006",
      },
      {
        label: "Dehiattakandiya",
        value: "32150",
      },
      {
        label: "Devalahinda",
        value: "32038",
      },
      {
        label: "Digamadulla Weeragoda",
        value: "32008",
      },
      {
        label: "Dorakumbura",
        value: "32104",
      },
      {
        label: "Galapitagala",
        value: "32066",
      },
      {
        label: "Gonagolla",
        value: "32064",
      },
      {
        label: "Hingurana",
        value: "32010",
      },
      {
        label: "Hulannuge",
        value: "32514",
      },
      {
        label: "Kalmunai",
        value: "32300",
      },
      {
        label: "Kannakipuram",
        value: "32405",
      },
      {
        label: "Karativu",
        value: "32250",
      },
      {
        label: "Kekirihena",
        value: "32074",
      },
      {
        label: "Koknahara",
        value: "32035",
      },
      {
        label: "Kolamanthalawa",
        value: "32102",
      },
      {
        label: "Komari",
        value: "32418",
      },
      {
        label: "Lahugala",
        value: "32512",
      },
      {
        label: "lmkkamam",
        value: "32450",
      },
      {
        label: "Madawalalanda",
        value: "32016",
      },
      {
        label: "Mahanagapura",
        value: "32018",
      },
      {
        label: "Mahaoya",
        value: "32070",
      },
      {
        label: "Malwatta",
        value: "32198",
      },
      {
        label: "Mangalagama",
        value: "32069",
      },
      {
        label: "Marathamune",
        value: "32314",
      },
      {
        label: "Mawanagama",
        value: "32158",
      },
      {
        label: "Moragahapallama",
        value: "32012",
      },
      {
        label: "Namaloya",
        value: "32037",
      },
      {
        label: "Navithanveli",
        value: "32308",
      },
      {
        label: "Nawamedagama",
        value: "32120",
      },
      {
        label: "Nintavur",
        value: "32340",
      },
      {
        label: "Oluvil",
        value: "32360",
      },
      {
        label: "Padiyatalawa",
        value: "32100",
      },
      {
        label: "Pahalalanda",
        value: "32034",
      },
      {
        label: "Palamunai",
        value: "32354",
      },
      {
        label: "Panama",
        value: "32508",
      },
      {
        label: "Pannalagama",
        value: "32022",
      },
      {
        label: "Paragahakele",
        value: "32031",
      },
      {
        label: "Periyaneelavanai",
        value: "32316",
      },
      {
        label: "Polwaga Janapadaya",
        value: "32032",
      },
      {
        label: "Pottuvil",
        value: "32500",
      },
      {
        label: "Rajagalatenna",
        value: "32068",
      },
      {
        label: "Sainthamaruthu",
        value: "32280",
      },
      {
        label: "Samanthurai",
        value: "32200",
      },
      {
        label: "Serankada",
        value: "32101",
      },
      {
        label: "Siripura",
        value: "32155",
      },
      {
        label: "Siyambalawewa",
        value: "32048",
      },
      {
        label: "Tempitiya",
        value: "32072",
      },
      {
        label: "Thambiluvil",
        value: "32415",
      },
      {
        label: "Tirukovil",
        value: "32420",
      },
      {
        label: "Uhana",
        value: "32060",
      },
      {
        label: "Wadinagala",
        value: "32039",
      },
      {
        label: "Wanagamuwa",
        value: "32454",
      },
      {
        label: "Werunketagoda",
        value: "32062",
      },
    ],
  },
  {
    label: "Anuradhapura",
    value: "Anuradhapura",

    children: [
      {
        label: "Andiyagala",
        value: "50112",
      },
      {
        label: "Angamuwa",
        value: "50248",
      },
      {
        label: "Anuradhapura",
        value: "50000",
      },
      {
        label: "Awukana",
        value: "50169",
      },
      {
        label: "Bogahawewa",
        value: "50566",
      },
      {
        label: "Dematawewa",
        value: "50356",
      },
      {
        label: "Dunumadalawa",
        value: "50214",
      },
      {
        label: "Dutuwewa",
        value: "50393",
      },
      {
        label: "Elayapattuwa",
        value: "50014",
      },
      {
        label: "Eppawala",
        value: "50260",
      },
      {
        label: "Etawatunuwewa",
        value: "50584",
      },
      {
        label: "Etaweeragollewa",
        value: "50518",
      },
      {
        label: "Galadivulwewa",
        value: "50210",
      },
      {
        label: "Galenbindunuwewa",
        value: "50390",
      },
      {
        label: "Galkadawala",
        value: "50006",
      },
      {
        label: "Galkiriyagama",
        value: "50120",
      },
      {
        label: "Galkulama",
        value: "50064",
      },
      {
        label: "Galnewa",
        value: "50170",
      },
      {
        label: "Gambirigaswewa",
        value: "50057",
      },
      {
        label: "Ganewalpola",
        value: "50142",
      },
      {
        label: "Gemunupura",
        value: "50224",
      },
      {
        label: "Getalawa",
        value: "50392",
      },
      {
        label: "Gnanikulama",
        value: "50036",
      },
      {
        label: "Gonahaddenawa",
        value: "50554",
      },
      {
        label: "Habarana",
        value: "50150",
      },
      {
        label: "Halmillawa Dambulla",
        value: "50124",
      },
      {
        label: "Halmillawetiya",
        value: "50552",
      },
      {
        label: "Hidogama",
        value: "50044",
      },
      {
        label: "Horawpatana",
        value: "50350",
      },
      {
        label: "Horiwila",
        value: "50222",
      },
      {
        label: "Hurigaswewa",
        value: "50176",
      },
      {
        label: "Hurulunikawewa",
        value: "50394",
      },
      {
        label: "Kagama",
        value: "50282",
      },
      {
        label: "Kahatagasdigiliya",
        value: "50320",
      },
      {
        label: "Kahatagollewa",
        value: "50562",
      },
      {
        label: "Kalakarambewa",
        value: "50288",
      },
      {
        label: "Kalankuttiya",
        value: "50174",
      },
      {
        label: "Kalaoya",
        value: "50226",
      },
      {
        label: "Kalawedi Ulpotha",
        value: "50556",
      },
      {
        label: "Kallanchiya",
        value: "50454",
      },
      {
        label: "Kapugallawa",
        value: "50370",
      },
      {
        label: "Karagahawewa",
        value: "50232",
      },
      {
        label: "Katiyawa",
        value: "50261",
      },
      {
        label: "Kebithigollewa",
        value: "50550",
      },
      {
        label: "Kekirawa",
        value: "50100",
      },
      {
        label: "Kendewa",
        value: "50452",
      },
      {
        label: "Kiralogama",
        value: "50259",
      },
      {
        label: "Kirigalwewa",
        value: "50511",
      },
      {
        label: "Kitulhitiyawa",
        value: "50132",
      },
      {
        label: "Kurundankulama",
        value: "50062",
      },
      {
        label: "Labunoruwa",
        value: "50088",
      },
      {
        label: "lhala Halmillewa",
        value: "50262",
      },
      {
        label: "lhalagama",
        value: "50304",
      },
      {
        label: "lpologama",
        value: "50280",
      },
      {
        label: "Madatugama",
        value: "50130",
      },
      {
        label: "Maha Elagamuwa",
        value: "50126",
      },
      {
        label: "Mahabulankulama",
        value: "50196",
      },
      {
        label: "Mahailluppallama",
        value: "50270",
      },
      {
        label: "Mahakanadarawa",
        value: "50306",
      },
      {
        label: "Mahapothana",
        value: "50327",
      },
      {
        label: "Mahasenpura",
        value: "50574",
      },
      {
        label: "Mahawilachchiya",
        value: "50022",
      },
      {
        label: "Mailagaswewa",
        value: "50384",
      },
      {
        label: "Malwanagama",
        value: "50236",
      },
      {
        label: "Maneruwa",
        value: "50182",
      },
      {
        label: "Maradankadawala",
        value: "50080",
      },
      {
        label: "Maradankalla",
        value: "50308",
      },
      {
        label: "Medawachchiya",
        value: "50500",
      },
      {
        label: "Megodawewa",
        value: "50334",
      },
      {
        label: "Mihintale",
        value: "50300",
      },
      {
        label: "Morakewa",
        value: "50349",
      },
      {
        label: "Mulkiriyawa",
        value: "50324",
      },
      {
        label: "Muriyakadawala",
        value: "50344",
      },
      {
        label: "Nachchaduwa",
        value: "50046",
      },
      {
        label: "Namalpura",
        value: "50339",
      },
      {
        label: "Negampaha",
        value: "50180",
      },
      {
        label: "Nochchiyagama",
        value: "50200",
      },
      {
        label: "Padavi Maithripura",
        value: "50572",
      },
      {
        label: "Padavi Parakramapura",
        value: "50582",
      },
      {
        label: "Padavi Sripura",
        value: "50587",
      },
      {
        label: "Padavi Sritissapura",
        value: "50588",
      },
      {
        label: "Padaviya",
        value: "50570",
      },
      {
        label: "Padikaramaduwa",
        value: "50338",
      },
      {
        label: "Pahala Halmillewa",
        value: "50206",
      },
      {
        label: "Pahala Maragahawe",
        value: "50220",
      },
      {
        label: "Pahalagama",
        value: "50244",
      },
      {
        label: "Palagala",
        value: "50111",
      },
      {
        label: "Palugaswewa",
        value: "50144",
      },
      {
        label: "Pandukabayapura",
        value: "50448",
      },
      {
        label: "Pandulagama",
        value: "50029",
      },
      {
        label: "Parakumpura",
        value: "50326",
      },
      {
        label: "Parangiyawadiya",
        value: "50354",
      },
      {
        label: "Parasangahawewa",
        value: "50055",
      },
      {
        label: "Pemaduwa",
        value: "50020",
      },
      {
        label: "Perimiyankulama",
        value: "50004",
      },
      {
        label: "Pihimbiyagolewa",
        value: "50512",
      },
      {
        label: "Pubbogama",
        value: "50122",
      },
      {
        label: "Pulmoddai",
        value: "50567",
      },
      {
        label: "Punewa",
        value: "50506",
      },
      {
        label: "Rajanganaya",
        value: "50246",
      },
      {
        label: "Rambewa",
        value: "50450",
      },
      {
        label: "Rampathwila",
        value: "50386",
      },
      {
        label: "Ranorawa",
        value: "50212",
      },
      {
        label: "Rathmalgahawewa",
        value: "50514",
      },
      {
        label: "Saliyapura",
        value: "50008",
      },
      {
        label: "Seeppukulama",
        value: "50380",
      },
      {
        label: "Senapura",
        value: "50284",
      },
      {
        label: "Sivalakulama",
        value: "50068",
      },
      {
        label: "Siyambalewa",
        value: "50184",
      },
      {
        label: "Sravasthipura",
        value: "50042",
      },
      {
        label: "Talawa",
        value: "50230",
      },
      {
        label: "Tambuttegama",
        value: "50240",
      },
      {
        label: "Tammennawa",
        value: "50104",
      },
      {
        label: "Tantirimale",
        value: "50016",
      },
      {
        label: "Telhiriyawa",
        value: "50242",
      },
      {
        label: "Tennamarawadiya",
        value: "*",
      },
      {
        label: "Tirappane",
        value: "50072",
      },
      {
        label: "Tittagonewa",
        value: "50558",
      },
      {
        label: "Udunuwara Colony",
        value: "50207",
      },
      {
        label: "Upuldeniya",
        value: "50382",
      },
      {
        label: "Uttimaduwa",
        value: "50067",
      },
      {
        label: "Viharapalugama",
        value: "50012",
      },
      {
        label: "Vijithapura",
        value: "50110",
      },
      {
        label: "Wahalkada",
        value: "50564",
      },
      {
        label: "Wahamalgollewa",
        value: "50492",
      },
      {
        label: "Walagambahuwa",
        value: "50086",
      },
      {
        label: "Walahaviddawewa",
        value: "50516",
      },
      {
        label: "Welimuwapotana",
        value: "50358",
      },
      {
        label: "Welioya Project",
        value: "50586",
      },
    ],
  },
  {
    label: "Badulla",
    value: "Badulla",

    children: [
      {
        label: "Akkarasiyaya",
        value: "90166",
      },
      {
        label: "Aluketiyawa",
        value: "90736",
      },
      {
        label: "Aluttaramma",
        value: "90722",
      },
      {
        label: "Ambadandegama",
        value: "90108",
      },
      {
        label: "Ambagahawatta",
        value: "90326",
      },
      {
        label: "Ambagasdowa",
        value: "90300",
      },
      {
        label: "Amunumulla",
        value: "90204",
      },
      {
        label: "Arawa",
        value: "90017",
      },
      {
        label: "Arawakumbura",
        value: "90532",
      },
      {
        label: "Arawatta",
        value: "90712",
      },
      {
        label: "Atakiriya",
        value: "90542",
      },
      {
        label: "Badulla",
        value: "90000",
      },
      {
        label: "Baduluoya",
        value: "90019",
      },
      {
        label: "Ballaketuwa",
        value: "90092",
      },
      {
        label: "Bambarapana",
        value: "90322",
      },
      {
        label: "Bandarawela",
        value: "90100",
      },
      {
        label: "Beramada",
        value: "90066",
      },
      {
        label: "Bibilegama",
        value: "90502",
      },
      {
        label: "Bogahakumbura",
        value: "90354",
      },
      {
        label: "Boragas",
        value: "90362",
      },
      {
        label: "Boralanda",
        value: "90170",
      },
      {
        label: "Bowela",
        value: "90302",
      },
      {
        label: "Dambana",
        value: "90714",
      },
      {
        label: "Demodara",
        value: "90080",
      },
      {
        label: "Diganatenna",
        value: "90132",
      },
      {
        label: "Dikkapitiya",
        value: "90214",
      },
      {
        label: "Dimbulana",
        value: "90324",
      },
      {
        label: "Divulapelessa",
        value: "90726",
      },
      {
        label: "Diyatalawa",
        value: "90150",
      },
      {
        label: "Dulgolla",
        value: "90104",
      },
      {
        label: "Egodawela",
        value: "90013",
      },
      {
        label: "Ella",
        value: "90090",
      },
      {
        label: "Ettampitiya",
        value: "90140",
      },
      {
        label: "Galauda",
        value: "90065",
      },
      {
        label: "Galedanda",
        value: "90206",
      },
      {
        label: "Galporuyaya",
        value: "90752",
      },
      {
        label: "Gamewela",
        value: "90512",
      },
      {
        label: "Gawarawela",
        value: "90082",
      },
      {
        label: "Girandurukotte",
        value: "90750",
      },
      {
        label: "Godunna",
        value: "90067",
      },
      {
        label: "Gurutalawa",
        value: "90208",
      },
      {
        label: "Haldummulla",
        value: "90180",
      },
      {
        label: "Hali Ela",
        value: " 90060",
      },

      {
        label: "Hangunnawa",
        value: "90224",
      },
      {
        label: "Haputale",
        value: "90160",
      },
      {
        label: "Hebarawa",
        value: "90724",
      },
      {
        label: "Heeloya",
        value: "90112",
      },
      {
        label: "Helahalpe",
        value: "90122",
      },
      {
        label: "Helapupula",
        value: "90094",
      },
      {
        label: "Hewanakumbura",
        value: "90358",
      },
      {
        label: "Hingurukaduwa",
        value: "90508",
      },
      {
        label: "Hopton",
        value: "90524",
      },
      {
        label: "Idalgashinna",
        value: "90167",
      },
      {
        label: "Jangulla",
        value: "90063",
      },
      {
        label: "Kahataruppa",
        value: "90052",
      },
      {
        label: "Kalubululanda",
        value: "90352",
      },
      {
        label: "Kalugahakandura",
        value: "90546",
      },
      {
        label: "Kalupahana",
        value: "90186",
      },
      {
        label: "Kandaketya",
        value: "90020",
      },
      {
        label: "Kandegedara",
        value: "90070",
      },
      {
        label: "Kandepuhulpola",
        value: "90356",
      },
      {
        label: "Kebillawela",
        value: "90102",
      },
      {
        label: "Kendagolla",
        value: "90048",
      },
      {
        label: "Keppetipola",
        value: "90350",
      },
      {
        label: "Keselpotha",
        value: "90738",
      },
      {
        label: "Ketawatta",
        value: "90016",
      },
      {
        label: "Kiriwanagama",
        value: "90184",
      },
      {
        label: "Koslanda",
        value: "90190",
      },
      {
        label: "Kotamuduna",
        value: "90506",
      },
      {
        label: "Kuruwitenna",
        value: "90728",
      },
      {
        label: "Kuttiyagolla",
        value: "90046",
      },
      {
        label: "Landewela",
        value: "90068",
      },
      {
        label: "Liyangahawela",
        value: "90106",
      },
      {
        label: "Lunugala",
        value: "90530",
      },
      {
        label: "Lunuwatta",
        value: "90310",
      },
      {
        label: "Madulsima",
        value: "90535",
      },
      {
        label: "Mahiyanganaya",
        value: "90700",
      },
      {
        label: "Makulella",
        value: "90114",
      },
      {
        label: "Malgoda",
        value: "90754",
      },
      {
        label: "Maliyadda",
        value: "90022",
      },
      {
        label: "Mapakadawewa",
        value: "90730",
      },
      {
        label: "Maspanna",
        value: "90328",
      },
      {
        label: "Maussagolla",
        value: "90582",
      },
      {
        label: "Medawela Udukinda",
        value: "90218",
      },
      {
        label: "Medawelagama",
        value: "90518",
      },
      {
        label: "Meegahakiula",
        value: "90015",
      },
      {
        label: "Metigahatenna",
        value: "90540",
      },
      {
        label: "Mirahawatta",
        value: "90134",
      },
      {
        label: "Miriyabedda",
        value: "90504",
      },
      {
        label: "Miyanakandura",
        value: "90584",
      },
      {
        label: "Namunukula",
        value: "90580",
      },
      {
        label: "Narangala",
        value: "90064",
      },
      {
        label: "Nelumgama",
        value: "90042",
      },
      {
        label: "Nikapotha",
        value: "90165",
      },
      {
        label: "Nugatalawa",
        value: "90216",
      },
      {
        label: "Ohiya",
        value: "90168",
      },
      {
        label: "Pahalarathkinda",
        value: "90756",
      },
      {
        label: "Pallekiruwa",
        value: "90534",
      },
      {
        label: "Passara",
        value: "90500",
      },
      {
        label: "Pathanewatta",
        value: "90071",
      },
      {
        label: "Pattiyagedara",
        value: "90138",
      },
      {
        label: "Pelagahatenna",
        value: "90522",
      },
      {
        label: "Perawella",
        value: "90222",
      },
      {
        label: "Pitamaruwa",
        value: "90544",
      },
      {
        label: "Pitapola",
        value: "90171",
      },
      {
        label: "Puhulpola",
        value: "90212",
      },
      {
        label: "Ratkarawwa",
        value: "90164",
      },
      {
        label: "Ridimaliyadda",
        value: "90704",
      },
      {
        label: "Rilpola",
        value: "90026",
      },
      {
        label: "Silmiyapura",
        value: "90364",
      },
      {
        label: "Sirimalgoda",
        value: "90044",
      },
      {
        label: "Sorabora Colony",
        value: "90718",
      },
      {
        label: "Soragune",
        value: "90183",
      },
      {
        label: "Soranatota",
        value: "90008",
      },
      {
        label: "Spring Valley",
        value: "90028",
      },
      {
        label: "Taldena",
        value: "90014",
      },
      {
        label: "Tennepanguwa",
        value: "90072",
      },
      {
        label: "Timbirigaspitiya",
        value: "90012",
      },
      {
        label: "Uduhawara",
        value: "90226",
      },
      {
        label: "Uraniya",
        value: "90702",
      },
      {
        label: "Uva Deegalla",
        value: "90062",
      },
      {
        label: "Uva Karandagolla",
        value: "90091",
      },
      {
        label: "Uva Mawelagama",
        value: "90192",
      },
      {
        label: "Uva Tenna",
        value: "90188",
      },
      {
        label: "Uva Tissapura",
        value: "90734",
      },
      {
        label: "Uva Uduwara",
        value: "90061",
      },
      {
        label: "Uvaparanagama",
        value: "90230",
      },
      {
        label: "Welimada",
        value: "90200",
      },
      {
        label: "Wewatta",
        value: "90716",
      },
      {
        label: "Wineethagama",
        value: "90034",
      },
      {
        label: "Yalagamuwa",
        value: "90329",
      },
      {
        label: "Yalwela",
        value: "90706",
      },
    ],
  },
  {
    label: "Batticaloa",
    value: "Batticaloa",

    children: [
      {
        label: "Ampilanthurai",
        value: "30162",
      },
      {
        label: "Araipattai",
        value: "30150",
      },
      {
        label: "Ayithiyamalai",
        value: "30362",
      },
      {
        label: "Bakiella",
        value: "30206",
      },
      {
        label: "Batticaloa",
        value: "30000",
      },
      {
        label: "Cheddipalayam",
        value: "30194",
      },
      {
        label: "Chenkaladi",
        value: "30350",
      },
      {
        label: "Eravur",
        value: "30300",
      },
      {
        label: "Kalkudah",
        value: "30410",
      },
      {
        label: "Kallar",
        value: "30250",
      },
      {
        label: "Kaluwanchikudi",
        value: "30200",
      },
      {
        label: "Kaluwankemy",
        value: "30372",
      },
      {
        label: "Kannankudah",
        value: "30016",
      },
      {
        label: "Karadiyanaru",
        value: "30354",
      },
      {
        label: "Kathiraveli",
        value: "30456",
      },
      {
        label: "Kattankudi",
        value: "30100",
      },
      {
        label: "Kiran",
        value: "30394",
      },
      {
        label: "Kirankulam",
        value: "30159",
      },
      {
        label: "Koddaikallar",
        value: "30249",
      },
      {
        label: "Kokkaddichcholai",
        value: "30160",
      },
      {
        label: "Kurukkalmadam",
        value: "30192",
      },
      {
        label: "Mandur",
        value: "30220",
      },
      {
        label: "Mankemi",
        value: "30442",
      },
      {
        label: "Miravodai",
        value: "30426",
      },
      {
        label: "Murakottanchanai",
        value: "30392",
      },
      {
        label: "Navagirinagar",
        value: "30238",
      },
      {
        label: "Navatkadu",
        value: "30018",
      },
      {
        label: "Oddamavadi",
        value: "30420",
      },
      {
        label: "Panichankemi",
        value: "30444",
      },
      {
        label: "Pankudavely",
        value: "30352",
      },
      {
        label: "Periyaporativu",
        value: "30230",
      },
      {
        label: "Periyapullumalai",
        value: "30358",
      },
      {
        label: "Pillaiyaradi",
        value: "30022",
      },
      {
        label: "Punanai",
        value: "30428",
      },
      {
        label: "Puthukudiyiruppu",
        value: "30158",
      },
      {
        label: "Thannamunai",
        value: "30024",
      },
      {
        label: "Thettativu",
        value: "30196",
      },
      {
        label: "Thikkodai",
        value: "30236",
      },
      {
        label: "Thirupalugamam",
        value: "30234",
      },
      {
        label: "Thuraineelavanai",
        value: "30254",
      },
      {
        label: "Unnichchai",
        value: "30364",
      },
      {
        label: "Vakaneri",
        value: "30424",
      },
      {
        label: "Vakarai",
        value: "30450",
      },
      {
        label: "Valaichenai",
        value: "30400",
      },
      {
        label: "Vantharumoolai",
        value: "30376",
      },
      {
        label: "Vellavely",
        value: "30204",
      },
    ],
  },
  {
    label: "Colombo",
    value: "Colombo",

    children: [
      {
        label: "Colombo 01",
        value: "00100",
      },
      {
        label: "Colombo 02",
        value: "00200",
      },
      {
        label: "Colombo 03",
        value: "00300",
      },
      {
        label: "Colombo 04",
        value: "00400",
      },
      {
        label: "Colombo 05",
        value: "00500",
      },
      {
        label: "Colombo 06",
        value: "00600",
      },
      {
        label: "Colombo 07",
        value: "00700",
      },
      {
        label: "Colombo 08",
        value: "00800",
      },
      {
        label: "Colombo 09",
        value: "00900",
      },
      {
        label: "Colombo 10",
        value: "01000",
      },
      {
        label: "Colombo 11",
        value: "01100",
      },
      {
        label: "Colombo 12",
        value: "01200",
      },
      {
        label: "Colombo 13",
        value: "01300",
      },
      {
        label: "Colombo 14",
        value: "01400",
      },
      {
        label: "Colombo 15",
        value: "01500",
      },
      {
        label: "Akarawita",
        value: "10732",
      },
      {
        label: "Angoda",
        value: "10620",
      },
      {
        label: "Arangala",
        value: "10150",
      },
      {
        label: "Athurugiriya",
        value: "10150",
      },
      {
        label: "Avissawella",
        value: "10700",
      },
      {
        label: "Bambalapitiya",
        value: "00400",
      },
      {
        label: "Batawala",
        value: "10513",
      },
      {
        label: "Battaramulla",
        value: "10120",
      },
      {
        label: "Batugampola",
        value: "10526",
      },
      {
        label: "Bope",
        value: "10522",
      },
      {
        label: "Boralesgamuwa",
        value: "10290",
      },
      {
        label: "Borella",
        value: "00800",
      },
      {
        label: "Dedigamuwa",
        value: "10656",
      },
      {
        label: "Dehiwala",
        value: "10350",
      },
      {
        label: "Deltara",
        value: "10302",
      },
      {
        label: "Embuldeniya",
        value: "10250",
      },
      {
        label: "Gongodawila",
        value: "10250",
      },
      {
        label: "Habarakada",
        value: "10204",
      },
      {
        label: "Handapangoda",
        value: "10524",
      },
      {
        label: "Hanwella",
        value: "10650",
      },
      {
        label: "Hewainna",
        value: "10714",
      },
      {
        label: "Hiripitya",
        value: "10232",
      },
      {
        label: "Hokandara",
        value: "10118",
      },
      {
        label: "Homagama",
        value: "10200",
      },
      {
        label: "Horagala",
        value: "10502",
      },
      {
        label: "Kaduwela",
        value: "10640",
      },
      {
        label: "Kahawala",
        value: "10508",
      },
      {
        label: "Kalatuwawa",
        value: "10718",
      },
      {
        label: "Kalubowila",
        value: "10350",
      },
      {
        label: "Kiriwattuduwa",
        value: "10208",
      },
      {
        label: "Kohuwala",
        value: "10250",
      },
      {
        label: "Kolonnawa",
        value: "10600",
      },
      {
        label: "Kosgama",
        value: "10730",
      },
      {
        label: "Kotahena",
        value: "01300",
      },
      {
        label: "Kotikawatta",
        value: "10120",
      },
      {
        label: "Kottawa",
        value: "10230",
      },
      {
        label: "Madapatha",
        value: "10306",
      },
      {
        label: "Maharagama",
        value: "10280",
      },
      {
        label: "Malabe",
        value: "10115",
      },
      {
        label: "Meegoda",
        value: "10504",
      },
      {
        label: "Moratuwa",
        value: "10400",
      },
      {
        label: "Mount Lavinia",
        value: "10370",
      },
      {
        label: "Mullegama",
        value: "10202",
      },
      {
        label: "Mulleriyawa",
        value: "10620",
      },
      {
        label: "Mutwal",
        value: "01500",
      },
      {
        label: "Napawela",
        value: "10704",
      },
      {
        label: "Narahenpita",
        value: "00500",
      },
      {
        label: "Nugegoda",
        value: "10250",
      },
      {
        label: "Padukka",
        value: "10500",
      },
      {
        label: "Pannipitiya",
        value: "10230",
      },
      {
        label: "Piliyandala",
        value: "10300",
      },
      {
        label: "Pita Kotte",
        value: "10100",
      },
      {
        label: "Pitipana Homagama",
        value: "10206",
      },
      {
        label: "Polgasowita",
        value: "10320",
      },
      {
        label: "Puwakpitiya",
        value: "10712",
      },
      {
        label: "Rajagiriya",
        value: "10100",
      },
      {
        label: "Ranala",
        value: "10654",
      },
      {
        label: "Ratmalana",
        value: "10390",
      },
      {
        label: "Siddamulla",
        value: "10304",
      },
      {
        label: "Sri Jayewardenepura",
        value: "10100",
      },
      {
        label: "Talawatugoda",
        value: "10116",
      },
      {
        label: "Tummodara",
        value: "10682",
      },
      {
        label: "Waga",
        value: "10680",
      },
      {
        label: "Watareka",
        value: "10511",
      },
      {
        label: "Wijerama",
        value: "10250",
      },
    ],
  },
  {
    label: "Galle",
    value: "Galle",

    children: [
      {
        label: "Agaliya",
        value: "80212",
      },
      {
        label: "Ahangama",
        value: "80650",
      },
      {
        label: "Ahungalla",
        value: "80562",
      },
      {
        label: "Akmeemana",
        value: "80090",
      },
      {
        label: "Aluthwala",
        value: "80332",
      },
      {
        label: "Ambalangoda",
        value: "80300",
      },
      {
        label: "Ampegama",
        value: "80204",
      },
      {
        label: "Amugoda",
        value: "80422",
      },
      {
        label: "Anangoda",
        value: "80044",
      },
      {
        label: "Angulugaha",
        value: "80122",
      },
      {
        label: "Ankokkawala",
        value: "80048",
      },
      {
        label: "Baddegama",
        value: "80200",
      },
      {
        label: "Balapitiya",
        value: "80550",
      },
      {
        label: "Banagala",
        value: "80143",
      },
      {
        label: "Batapola",
        value: "80320",
      },
      {
        label: "Bentota",
        value: "80500",
      },
      {
        label: "Boossa",
        value: "80270",
      },
      {
        label: "Dikkumbura",
        value: "80654",
      },
      {
        label: "Dodanduwa",
        value: "80250",
      },
      {
        label: "Ella Tanabaddegama",
        value: "80402",
      },
      {
        label: "Elpitiya",
        value: "80400",
      },
      {
        label: "Ethkandura",
        value: "80458",
      },
      {
        label: "Galle",
        value: "80000",
      },
      {
        label: "Ganegoda",
        value: "80440",
      },
      {
        label: "Ginimellagaha",
        value: "80220",
      },
      {
        label: "Gintota",
        value: "80280",
      },
      {
        label: "Godahena",
        value: "80302",
      },
      {
        label: "Gonagalpura",
        value: "80502",
      },
      {
        label: "Gonamulla Junction",
        value: "80054",
      },
      {
        label: "Gonapinuwala",
        value: "80230",
      },
      {
        label: "Habaraduwa",
        value: "80630",
      },
      {
        label: "Haburugala",
        value: "80506",
      },
      {
        label: "Halvitigala Colony",
        value: "80146",
      },
      {
        label: "Hawpe",
        value: "80132",
      },
      {
        label: "Hikkaduwa",
        value: "80240",
      },
      {
        label: "Hiniduma",
        value: "80080",
      },
      {
        label: "Hiyare",
        value: "80056",
      },
      {
        label: "Ihala Walpola",
        value: "80134",
      },
      {
        label: "Kahaduwa",
        value: "80460",
      },
      {
        label: "Kahawa",
        value: "80312",
      },
      {
        label: "Kananke Bazaar",
        value: "80136",
      },
      {
        label: "Karagoda",
        value: "80151",
      },
      {
        label: "Karandeniya",
        value: "80360",
      },
      {
        label: "Kosgoda",
        value: "80570",
      },
      {
        label: "Kottawagama",
        value: "80062",
      },
      {
        label: "Kuleegoda",
        value: "80328",
      },
      {
        label: "lhalahewessa",
        value: "80432",
      },
      {
        label: "lmaduwa",
        value: "80130",
      },
      {
        label: "lnduruwa",
        value: "80510",
      },
      {
        label: "Magedara",
        value: "80152",
      },
      {
        label: "Malgalla Talangalla",
        value: "80144",
      },
      {
        label: "Mapalagama",
        value: "80112",
      },
      {
        label: "Mapalagama Central",
        value: "80116",
      },
      {
        label: "Mattaka",
        value: "80424",
      },
      {
        label: "Meda-Keembiya",
        value: "80092",
      },
      {
        label: "Meetiyagoda",
        value: "80330",
      },
      {
        label: "Miriswatta",
        value: "80508",
      },
      {
        label: "Nagoda",
        value: "80110",
      },
      {
        label: "Nakiyadeniya",
        value: "80064",
      },
      {
        label: "Nawadagala",
        value: "80416",
      },
      {
        label: "Neluwa",
        value: "80082",
      },
      {
        label: "Nindana",
        value: "80318",
      },
      {
        label: "Opatha",
        value: "80142",
      },
      {
        label: "Panangala",
        value: "80075",
      },
      {
        label: "Pannimulla Panagoda",
        value: "80086",
      },
      {
        label: "Parana ThanaYamgoda",
        value: "80114",
      },
      {
        label: "Pitigala",
        value: "80420",
      },
      {
        label: "Poddala",
        value: "80170",
      },
      {
        label: "Porawagama",
        value: "80408",
      },
      {
        label: "Rantotuwila",
        value: "80354",
      },
      {
        label: "Ratgama",
        value: "80260",
      },
      {
        label: "Talagampola",
        value: "80058",
      },
      {
        label: "Talgaspe",
        value: "80406",
      },
      {
        label: "Talgaswela",
        value: "80470",
      },
      {
        label: "Talpe",
        value: "80615",
      },
      {
        label: "Tawalama",
        value: "80148",
      },
      {
        label: "Tiranagama",
        value: "80244",
      },
      {
        label: "Udalamatta",
        value: "80108",
      },
      {
        label: "Udugama",
        value: "80070",
      },
      {
        label: "Uluvitike",
        value: "80168",
      },
      {
        label: "Unawatuna",
        value: "80600",
      },
      {
        label: "Unenwitiya",
        value: "80214",
      },
      {
        label: "Uragaha",
        value: "80352",
      },
      {
        label: "Uragasmanhandiya",
        value: "80350",
      },
      {
        label: "Wakwella",
        value: "80042",
      },
      {
        label: "Walahanduwa",
        value: "80046",
      },
      {
        label: "Wanchawela",
        value: "80120",
      },
      {
        label: "Wanduramba",
        value: "80100",
      },
      {
        label: "Warukandeniya",
        value: "80084",
      },
      {
        label: "Watugedara",
        value: "80340",
      },
      {
        label: "Weihena",
        value: "80216",
      },
      {
        label: "Yakkalamulla",
        value: "80150",
      },
      {
        label: "Yatalamatta",
        value: "80107",
      },
    ],
  },
  {
    label: "Gampaha",
    value: "Gampaha",

    children: [
      { label: "Akaragama", value: "11536" },
      {
        label: "Alawala",
        value: "11122",
      },
      {
        label: "Ambagaspitiya",
        value: "11052",
      },
      {
        label: "Ambepussa",
        value: "11212",
      },
      {
        label: "Andiambalama",
        value: "11558",
      },
      {
        label: "Attanagalla",
        value: "11120",
      },
      {
        label: "Badalgama",
        value: "11538",
      },
      {
        label: "Banduragoda",
        value: "11244",
      },
      {
        label: "Batuwatta",
        value: "11011",
      },
      {
        label: "Bemmulla",
        value: "11040",
      },
      {
        label: "Biyagama",
        value: "11650",
      },
      {
        label: "Biyagama IPZ",
        value: "11672",
      },
      {
        label: "Bokalagama",
        value: "11216",
      },
      {
        label: "Bopagama",
        value: "11134",
      },
      {
        label: "Buthpitiya",
        value: "11720",
      },
      {
        label: "Dagonna",
        value: "11524",
      },
      {
        label: "Danowita",
        value: "11896",
      },
      {
        label: "Debahera",
        value: "11889",
      },
      {
        label: "Dekatana",
        value: "11690",
      },
      {
        label: "Delgoda",
        value: "11700",
      },
      {
        label: "Delwagura",
        value: "11228",
      },
      {
        label: "Demalagama",
        value: "11692",
      },
      {
        label: "Demanhandiya",
        value: "11270",
      },
      {
        label: "Dewalapola",
        value: "11102",
      },
      {
        label: "Divulapitiya",
        value: "11250",
      },
      {
        label: "Divuldeniya",
        value: "11208",
      },
      {
        label: "Dompe",
        value: "11680",
      },
      {
        label: "Dunagaha",
        value: "11264",
      },
      {
        label: "Ekala",
        value: "11380",
      },
      {
        label: "Ellakkala",
        value: "11116",
      },
      {
        label: "Essella",
        value: "11108",
      },
      {
        label: "Gampaha",
        value: "11000",
      },
      {
        label: "Ganemulla",
        value: "11020",
      },
      {
        label: "GonawalaWP",
        value: "11630",
      },
      {
        label: "Heiyanthuduwa",
        value: "11618",
      },
      {
        label: "Hendala",
        value: "11300",
      },
      {
        label: "Henegama",
        value: "11715",
      },
      {
        label: "Hinatiyana Madawala",
        value: "11568",
      },
      {
        label: "Hiswella",
        value: "11734",
      },
      {
        label: "Horampella",
        value: "11564",
      },
      {
        label: "Hunumulla",
        value: "11262",
      },
      {
        label: "Ihala Madampella",
        value: "11265",
      },
      {
        label: "Imbulgoda",
        value: "11856",
      },
      {
        label: "Ja-Ela",
        value: "11350",
      },
      {
        label: "Kadawatha",
        value: "11850",
      },
      {
        label: "Kahatowita",
        value: "11144",
      },
      {
        label: "Kalagedihena",
        value: "11875",
      },
      {
        label: "Kaleliya",
        value: "11160",
      },
      {
        label: "Kaluaggala",
        value: "11224",
      },
      {
        label: "Kandana",
        value: "11320",
      },
      {
        label: "Kapugoda",
        value: "10662",
      },
      {
        label: "Kapuwatta",
        value: "11350",
      },
      {
        label: "Katana",
        value: "11534",
      },
      {
        label: "Katunayake",
        value: "11450",
      },
      {
        label: "Katunayake Air Force Camp",
        value: "11440",
      },
      {
        label: "Katuwellegama",
        value: "11526",
      },
      {
        label: "Kelaniya",
        value: "11600",
      },
      {
        label: "Kimbulapitiya",
        value: "11522",
      },
      {
        label: "Kiribathgoda",
        value: "11600",
      },
      {
        label: "Kirindiwela",
        value: "11730",
      },
      {
        label: "Kitalawalana",
        value: "11206",
      },
      {
        label: "Kitulwala",
        value: "11242",
      },
      {
        label: "Kochchikade",
        value: "11540",
      },
      {
        label: "Kotadeniyawa",
        value: "11232",
      },
      {
        label: "Kotugoda",
        value: "11390",
      },
      {
        label: "Kumbaloluwa",
        value: "11105",
      },
      {
        label: "Loluwagoda",
        value: "11204",
      },
      {
        label: "Lunugama",
        value: "11062",
      },
      {
        label: "Mabodale",
        value: "11114",
      },
      {
        label: "Madelgamuwa",
        value: "11033",
      },
      {
        label: "Makewita",
        value: "11358",
      },
      {
        label: "Makola",
        value: "11640",
      },
      {
        label: "Malwana",
        value: "11670",
      },
      {
        label: "Mandawala",
        value: "11061",
      },
      {
        label: "Marandagahamula",
        value: "11260",
      },
      {
        label: "Mellawagedara",
        value: "11234",
      },
      {
        label: "Minuwangoda",
        value: "11550",
      },
      {
        label: "Mirigama",
        value: "11200",
      },
      {
        label: "Mithirigala",
        value: "11742",
      },
      {
        label: "Muddaragama",
        value: "11112",
      },
      {
        label: "Mudungoda",
        value: "11056",
      },
      {
        label: "Naranwala",
        value: "11063",
      },
      {
        label: "Nawana",
        value: "11222",
      },
      {
        label: "Nedungamuwa",
        value: "11066",
      },
      {
        label: "Negombo",
        value: "11500",
      },
      {
        label: "Nikahetikanda",
        value: "11128",
      },
      {
        label: "Nittambuwa",
        value: "11880",
      },
      {
        label: "Niwandama",
        value: "11354",
      },
      {
        label: "Pallewela",
        value: "11150",
      },
      {
        label: "Pamunugama",
        value: "11370",
      },
      {
        label: "Pamunuwatta",
        value: "11214",
      },
      {
        label: "Pasyala",
        value: "11890",
      },
      {
        label: "Peliyagoda",
        value: "11830",
      },
      {
        label: "Pepiliyawala",
        value: "11741",
      },
      {
        label: "Pethiyagoda",
        value: "11043",
      },
      {
        label: "Polpithimukulana",
        value: "11324",
      },
      {
        label: "Pugoda",
        value: "10660",
      },
      {
        label: "Radawadunna",
        value: "11892",
      },
      {
        label: "Radawana",
        value: "11725",
      },
      {
        label: "Raddolugama",
        value: "11400",
      },
      {
        label: "Ragama",
        value: "11010",
      },
      {
        label: "Ruggahawila",
        value: "11142",
      },
      {
        label: "Rukmale",
        value: "11129",
      },
      {
        label: "Seeduwa",
        value: "11410",
      },
      {
        label: "Siyambalape",
        value: "11607",
      },
      {
        label: "Talahena",
        value: "11504",
      },
      {
        label: "Thimbirigaskatuwa",
        value: "11532",
      },
      {
        label: "Tittapattara",
        value: "10664",
      },
      {
        label: "Udathuthiripitiya",
        value: "11054",
      },
      {
        label: "Udugampola",
        value: "11030",
      },
      {
        label: "Uggalboda",
        value: "11034",
      },
      {
        label: "Urapola",
        value: "11126",
      },
      {
        label: "Uswetakeiyawa",
        value: "11328",
      },
      {
        label: "Veyangoda",
        value: "11100",
      },
      {
        label: "Walgammulla",
        value: "11146",
      },
      {
        label: "Walpita",
        value: "11226",
      },
      {
        label: "Wanaluwewa",
        value: "11068",
      },
      {
        label: "Wathurugama",
        value: "11724",
      },
      {
        label: "Watinapaha",
        value: "11104",
      },
      {
        label: "Wattala",
        value: "11300",
      },
      {
        label: "Weboda",
        value: "11858",
      },
      {
        label: "Wegowwa",
        value: "11562",
      },
      {
        label: "Weliveriya",
        value: "11710",
      },
      {
        label: "Weweldeniya",
        value: "11894",
      },
      {
        label: "Yakkala",
        value: "11870",
      },
    ],
  },
  {
    label: "Hambantota",
    value: "Hambantota",

    children: [
      {
        label: "Ambalantota",
        value: "82100",
      },
      {
        label: "Angunakolapelessa",
        value: "82220",
      },
      {
        label: "Bandagiriya Colony",
        value: "82005",
      },
      {
        label: "Barawakumbuka",
        value: "82110",
      },
      {
        label: "Beliatta",
        value: "82400",
      },
      {
        label: "Beragama",
        value: "82102",
      },
      {
        label: "Beralihela",
        value: "82618",
      },
      {
        label: "Bowalagama",
        value: "82458",
      },
      {
        label: "Bundala",
        value: "82002",
      },
      {
        label: "Ellagala",
        value: "82619",
      },
      {
        label: "Gangulandeniya",
        value: "82506",
      },
      {
        label: "Getamanna",
        value: "82420",
      },
      {
        label: "Goda Koggalla",
        value: "82401",
      },
      {
        label: "Gonagamuwa Uduwila",
        value: "82602",
      },
      {
        label: "Gonnoruwa",
        value: "82006",
      },
      {
        label: "Hakuruwela",
        value: "82248",
      },
      {
        label: "Hambantota",
        value: "82000",
      },
      {
        label: "Horewelagoda",
        value: "82456",
      },
      {
        label: "Hungama",
        value: "82120",
      },
      {
        label: "Ihala Beligalla",
        value: "82412",
      },
      {
        label: "Ittademaliya",
        value: "82462",
      },
      {
        label: "Julampitiya",
        value: "82252",
      },
      {
        label: "Kahandamodara",
        value: "82126",
      },
      {
        label: "Kariyamaditta",
        value: "82274",
      },
      {
        label: "Katuwana",
        value: "82500",
      },
      {
        label: "Kawantissapura",
        value: "82622",
      },
      {
        label: "Kirama",
        value: "82550",
      },
      {
        label: "Kirinda",
        value: "82614",
      },
      {
        label: "Lunama",
        value: "82108",
      },
      {
        label: "Lunugamwehera",
        value: "82634",
      },
      {
        label: "Magama",
        value: "82608",
      },
      {
        label: "Mahagalwewa",
        value: "82016",
      },
      {
        label: "Mamadala",
        value: "82109",
      },
      {
        label: "Medamulana",
        value: "82254",
      },
      {
        label: "Middeniya",
        value: "82270",
      },
      {
        label: "Migahajandur",
        value: "82014",
      },
      {
        label: "Modarawana",
        value: "82416",
      },
      {
        label: "Mulkirigala",
        value: "82242",
      },
      {
        label: "Nakulugamuwa",
        value: "82300",
      },
      {
        label: "Netolpitiya",
        value: "82135",
      },
      {
        label: "Nihiluwa",
        value: "82414",
      },
      {
        label: "Padawkema",
        value: "82636",
      },
      {
        label: "Pahala Andarawewa",
        value: "82008",
      },
      {
        label: "Pallekanda",
        value: "82454",
      },
      {
        label: "Rammalawarapitiya",
        value: "82554",
      },
      {
        label: "Ranakeliya",
        value: "82612",
      },
      {
        label: "Ranmuduwewa",
        value: "82018",
      },
      {
        label: "Ranna",
        value: "82125",
      },
      {
        label: "Ratmalwala",
        value: "82276",
      },
      {
        label: "RU/Ridiyagama",
        value: "82106",
      },
      {
        label: "Sooriyawewa Town",
        value: "82010",
      },
      {
        label: "Tangalla",
        value: "82200",
      },
      {
        label: "Tissamaharama",
        value: "82600",
      },
      {
        label: "Uda Gomadiya",
        value: "82504",
      },
      {
        label: "Udamattala",
        value: "82638",
      },
      {
        label: "Uswewa",
        value: "82278",
      },
      {
        label: "Vitharandeniya",
        value: "82232",
      },
      {
        label: "Walasmulla",
        value: "82450",
      },
      {
        label: "Weeraketiya",
        value: "82240",
      },
      {
        label: "Weerawila",
        value: "82632",
      },
      {
        label: "Weerawila NewTown",
        value: "82615",
      },
      {
        label: "Wekandawela",
        value: "82246",
      },
      {
        label: "Weligatta",
        value: "82004",
      },
      {
        label: "Yatigala",
        value: "82418",
      },
    ],
  },
  {
    label: "Jaffna",
    value: "Jaffna",

    children: [
      {
        label: "Allaipiddi",
        value: "40000",
      },
      {
        label: "Allaveddi",
        value: "40000",
      },
      {
        label: "Alvai",
        value: "40000",
      },
      {
        label: "Anaicoddai",
        value: "40000",
      },
      {
        label: "Analaitivu",
        value: "40000",
      },
      {
        label: "Atchuveli",
        value: "40000",
      },
      {
        label: "Chankanai",
        value: "40000",
      },
      {
        label: "Chavakachcheri",
        value: "40000",
      },
      {
        label: "Chullipuram",
        value: "40000",
      },
      {
        label: "Chundikuli",
        value: "40000",
      },
      {
        label: "Chunnakam",
        value: "40000",
      },
      {
        label: "Delft",
        value: "40000",
      },
      {
        label: "DelftWest",
        value: "40000",
      },
      {
        label: "Eluvaitivu",
        value: "40000",
      },
      {
        label: "Erlalai",
        value: "40000",
      },
      {
        label: "Jaffna",
        value: "40000",
      },
      {
        label: "Kaitadi",
        value: "40000",
      },
      {
        label: "Kankesanthurai",
        value: "40000",
      },
      {
        label: "Karainagar",
        value: "40000",
      },
      {
        label: "Karaveddi",
        value: "40000",
      },
      {
        label: "Kayts",
        value: "40000",
      },
      {
        label: "Kodikamam",
        value: "40000",
      },
      {
        label: "Kokuvil",
        value: "40000",
      },
      {
        label: "Kondavil",
        value: "40000",
      },
      {
        label: "Kopay",
        value: "40000",
      },
      {
        label: "Kudatanai",
        value: "40000",
      },
      {
        label: "llavalai",
        value: "40000",
      },
      {
        label: "Mallakam",
        value: "40000",
      },
      {
        label: "Manipay",
        value: "40000",
      },
      {
        label: "Mathagal",
        value: "40000",
      },
      {
        label: "Meesalai",
        value: "40000",
      },
      {
        label: "Mirusuvil",
        value: "40000",
      },
      {
        label: "Nagar Kovil",
        value: "40000",
      },
      {
        label: "Nainathivu",
        value: "40000",
      },
      {
        label: "Neervely",
        value: "40000",
      },
      {
        label: "Pandaterippu",
        value: "40000",
      },
      {
        label: "Point Pedro",
        value: "40000",
      },
      {
        label: "Pungudutivu",
        value: "40000",
      },
      {
        label: "Putur",
        value: "40000",
      },
      {
        label: "Sandilipay",
        value: "40000",
      },
      {
        label: "Sithankemy",
        value: "40000",
      },
      {
        label: "Tellippallai",
        value: "40000",
      },
      {
        label: "Thondamanaru",
        value: "40000",
      },
      {
        label: "Urumpirai",
        value: "40000",
      },
      {
        label: "Vaddukoddai",
        value: "40000",
      },
      {
        label: "Valvettithurai",
        value: "40000",
      },
      {
        label: "Vannarponnai",
        value: "40000",
      },
      {
        label: "Varany",
        value: "40000",
      },
      {
        label: "Vasavilan",
        value: "40000",
      },
      {
        label: "Velanai",
        value: "40000",
      },
    ],
  },
  {
    label: "Kalutara",
    value: "Kalutara",

    children: [
      {
        label: "Agalawatta",
        value: "12200",
      },
      {
        label: "Alubomulla",
        value: "12524",
      },
      {
        label: "Alutgama",
        value: "12080",
      },
      {
        label: "Anguruwatota",
        value: "12320",
      },
      {
        label: "Baduraliya",
        value: "12230",
      },
      {
        label: "Bandaragama",
        value: "12530",
      },
      {
        label: "Bellana",
        value: "12224",
      },
      {
        label: "Beruwala",
        value: "12070",
      },
      {
        label: "Bolossagama",
        value: "12008",
      },
      {
        label: "Bombuwala",
        value: "12024",
      },
      {
        label: "Boralugoda",
        value: "12142",
      },
      {
        label: "Bulathsinhala",
        value: "12300",
      },
      {
        label: "Danawala Thiniyawala",
        value: "12148",
      },
      {
        label: "Delmella",
        value: "12304",
      },
      {
        label: "Dharga Town",
        value: "12090",
      },
      {
        label: "Diwalakada",
        value: "12308",
      },
      {
        label: "Dodangoda",
        value: "12020",
      },
      {
        label: "Dombagoda",
        value: "12416",
      },
      {
        label: "Galpatha",
        value: "12005",
      },
      {
        label: "Gamagoda",
        value: "12016",
      },
      {
        label: "Gonapola Junction",
        value: "12410",
      },
      {
        label: "Govinna",
        value: "12310",
      },
      {
        label: "Gurulubadda",
        value: "12236",
      },
      {
        label: "Halkandawila",
        value: "12055",
      },
      {
        label: "Haltota",
        value: "12538",
      },
      {
        label: "Halwala",
        value: "12118",
      },
      {
        label: "Halwatura",
        value: "12306",
      },
      {
        label: "Hedigalla Colony",
        value: "12234",
      },
      {
        label: "Horana",
        value: "12400",
      },
      {
        label: "Ittapana",
        value: "12116",
      },
      {
        label: "Kalawila Kiranthidiya",
        value: "12078",
      },
      {
        label: "Kalutara",
        value: "12000",
      },
      {
        label: "Kananwila",
        value: "12418",
      },
      {
        label: "Kandanagama",
        value: "12428",
      },
      {
        label: "Kehelwatta",
        value: "12550",
      },
      {
        label: "Kelinkanda",
        value: "12218",
      },
      {
        label: "Kitulgoda",
        value: "12222",
      },
      {
        label: "Koholana",
        value: "12007",
      },
      {
        label: "Kuda Uduwa",
        value: "12426",
      },
      {
        label: "lngiriya",
        value: "12440",
      },
      {
        label: "Maggona",
        value: "12060",
      },
      {
        label: "Mahagama",
        value: "12210",
      },
      {
        label: "Mahakalupahana",
        value: "12126",
      },
      {
        label: "Matugama",
        value: "12100",
      },
      {
        label: "Meegahatenna",
        value: "12130",
      },
      {
        label: "Meegama",
        value: "12094",
      },
      {
        label: "Millaniya",
        value: "12412",
      },
      {
        label: "Millewa",
        value: "12422",
      },
      {
        label: "Miwanapalana",
        value: "12424",
      },
      {
        label: "Molkawa",
        value: "12216",
      },
      {
        label: "Morapitiya",
        value: "12232",
      },
      {
        label: "Morontuduwa",
        value: "12564",
      },
      {
        label: "Nawattuduwa",
        value: "12106",
      },
      {
        label: "Neboda",
        value: "12030",
      },
      {
        label: "Padagoda",
        value: "12074",
      },
      {
        label: "Pahalahewessa",
        value: "12144",
      },
      {
        label: "Paiyagala",
        value: "12050",
      },
      {
        label: "Panadura",
        value: "12500",
      },
      {
        label: "Pannila",
        value: "12114",
      },
      {
        label: "Paragastota",
        value: "12414",
      },
      {
        label: "Paragoda",
        value: "12302",
      },
      {
        label: "Paraigama",
        value: "12122",
      },
      {
        label: "Pelanda",
        value: "12214",
      },
      {
        label: "Pelawatta",
        value: "12138",
      },
      {
        label: "Pokunuwita",
        value: "12404",
      },
      {
        label: "Polgampola",
        value: "12136",
      },
      {
        label: "Poruwedanda",
        value: "12432",
      },
      {
        label: "Remunagoda",
        value: "12009",
      },
      {
        label: "Tebuwana",
        value: "12025",
      },
      {
        label: "Uduwara",
        value: "12322",
      },
      {
        label: "Utumgama",
        value: "12127",
      },
      {
        label: "Veyangalla",
        value: "12204",
      },
      {
        label: "Wadduwa",
        value: "12560",
      },
      {
        label: "Walagedara",
        value: "12112",
      },
      {
        label: "Walallawita",
        value: "12134",
      },
      {
        label: "Waskaduwa",
        value: "12580",
      },
      {
        label: "Welipenna",
        value: "12108",
      },
      {
        label: "Welmilla Junction",
        value: "12534",
      },
      {
        label: "Yagirala",
        value: "12124",
      },
      {
        label: "Yatadolawatta",
        value: "12104",
      },
      {
        label: "Yatawara Junction",
        value: "12006",
      },
    ],
  },
  {
    label: "Kandy",
    value: "Kandy",

    children: [
      {
        label: "Akurana",
        value: "20850",
      },
      {
        label: "Alawatugoda",
        value: "20140",
      },
      {
        label: "Aludeniya",
        value: "20062",
      },
      {
        label: "Ambagahapelessa",
        value: "20986",
      },
      {
        label: "Ambatenna",
        value: "20136",
      },
      {
        label: "Ampitiya",
        value: "20160",
      },
      {
        label: "Ankumbura",
        value: "20150",
      },
      {
        label: "Atabage",
        value: "20574",
      },
      {
        label: "Balana",
        value: "20308",
      },
      {
        label: "Bambaragahaela",
        value: "20644",
      },
      {
        label: "Barawardhana Oya",
        value: "20967",
      },
      {
        label: "Batagolladeniya",
        value: "20154",
      },
      {
        label: "Batugoda",
        value: "20132",
      },
      {
        label: "Batumulla",
        value: "20966",
      },
      {
        label: "Bawlana",
        value: "20218",
      },
      {
        label: "Bopana",
        value: "20932",
      },
      {
        label: "Danture",
        value: "20465",
      },
      {
        label: "Dedunupitiya",
        value: "20068",
      },
      {
        label: "Dekinda",
        value: "20658",
      },
      {
        label: "Deltota",
        value: "20430",
      },
      {
        label: "Dolapihilla",
        value: "20126",
      },
      {
        label: "Dolosbage",
        value: "20510",
      },
      {
        label: "Doluwa",
        value: "20532",
      },
      {
        label: "Doragamuwa",
        value: "20816",
      },
      {
        label: "Dunuwila",
        value: "20824",
      },
      {
        label: "Ekiriya",
        value: "20732",
      },
      {
        label: "Elamulla",
        value: "20742",
      },
      {
        label: "Etulgama",
        value: "20202",
      },
      {
        label: "Galaboda",
        value: "20664",
      },
      {
        label: "Galagedara",
        value: "20100",
      },
      {
        label: "Galaha",
        value: "20420",
      },
      {
        label: "Galhinna",
        value: "20152",
      },
      {
        label: "Gallellagama",
        value: "20095",
      },
      {
        label: "Gampola",
        value: "20500",
      },
      {
        label: "Gelioya",
        value: "20620",
      },
      {
        label: "Godamunna",
        value: "20214",
      },
      {
        label: "Gomagoda",
        value: "20184",
      },
      {
        label: "Gonagantenna",
        value: "20712",
      },
      {
        label: "Gonawalapatana",
        value: "20656",
      },
      {
        label: "Gunnepana",
        value: "20270",
      },
      {
        label: "Gurudeniya",
        value: "20189",
      },
      {
        label: "Halloluwa",
        value: "20032",
      },
      {
        label: "Handaganawa",
        value: "20984",
      },
      {
        label: "Handawalapitiya",
        value: "20438",
      },
      {
        label: "Handessa",
        value: "20480",
      },
      {
        label: "Hanguranketha",
        value: "20710",
      },
      {
        label: "Harankahawa",
        value: "20092",
      },
      {
        label: "Hasalaka",
        value: "20960",
      },
      {
        label: "Hataraliyadda",
        value: "20060",
      },
      {
        label: "Hewaheta",
        value: "20440",
      },
      {
        label: "Hindagala",
        value: "20414",
      },
      {
        label: "Hondiyadeniya",
        value: "20524",
      },
      {
        label: "Hunnasgiriya",
        value: "20948",
      },
      {
        label: "Jambugahapitiya",
        value: "20822",
      },
      {
        label: "Kadugannawa",
        value: "20300",
      },
      {
        label: "Kahataliyadda",
        value: "20924",
      },
      {
        label: "Kalugala",
        value: "20926",
      },
      {
        label: "Kandy",
        value: "20000",
      },
      {
        label: "Kapuliyadde",
        value: "20206",
      },
      {
        label: "Karandagolla",
        value: "20738",
      },
      {
        label: "Katugastota",
        value: "20800",
      },
      {
        label: "Kengalla",
        value: "20186",
      },
      {
        label: "Ketakumbura",
        value: "20306",
      },
      {
        label: "Ketawala Leula",
        value: "20198",
      },
      {
        label: "Kiribathkumbura",
        value: "20442",
      },
      {
        label: "Kobonila",
        value: "20928",
      },
      {
        label: "Kolabissa",
        value: "20212",
      },
      {
        label: "Kolongoda",
        value: "20971",
      },
      {
        label: "Kulugammana",
        value: "20048",
      },
      {
        label: "Kumbukkandura",
        value: "20902",
      },
      {
        label: "Kumburegama",
        value: "20086",
      },
      {
        label: "Kundasale",
        value: "20168",
      },
      {
        label: "Leemagahakotuwa",
        value: "20482",
      },
      {
        label: "lhala Kobbekaduwa",
        value: "20042",
      },
      {
        label: "lllagolla",
        value: "20724",
      },
      {
        label: "Lunuketiya Maditta",
        value: "20172",
      },
      {
        label: "Madawala Bazaar",
        value: "20260",
      },
      {
        label: "Madugalla",
        value: "20938",
      },
      {
        label: "Madulkele",
        value: "20840",
      },
      {
        label: "Mahadoraliyadda",
        value: "20945",
      },
      {
        label: "Mahamedagama",
        value: "20216",
      },
      {
        label: "Mailapitiya",
        value: "20702",
      },
      {
        label: "Makkanigama",
        value: "20828",
      },
      {
        label: "Makuldeniya",
        value: "20921",
      },
      {
        label: "Mandaram Nuwara",
        value: "20744",
      },
      {
        label: "Mapakanda",
        value: "20662",
      },
      {
        label: "Marassana",
        value: "20210",
      },
      {
        label: "Marymount Colony",
        value: "20714",
      },
      {
        label: "Maturata",
        value: "20748",
      },
      {
        label: "Mawatura",
        value: "20564",
      },
      {
        label: "Medamahanuwara",
        value: "20940",
      },
      {
        label: "Medawala Harispattuwa",
        value: "20120",
      },
      {
        label: "Meetalawa",
        value: "20512",
      },
      {
        label: "Megoda Kalugamuwa",
        value: "20409",
      },
      {
        label: "Menikdiwela",
        value: "20470",
      },
      {
        label: "Menikhinna",
        value: "20170",
      },
      {
        label: "Mimure",
        value: "20923",
      },
      {
        label: "Minigamuwa",
        value: "20109",
      },
      {
        label: "Minipe",
        value: "20983",
      },
      {
        label: "Murutalawa",
        value: "20232",
      },
      {
        label: "Muruthagahamulla",
        value: "20526",
      },
      {
        label: "Naranpanawa",
        value: "20176",
      },
      {
        label: "Nattarampotha",
        value: "20194",
      },
      {
        label: "Nawalapitiya",
        value: "20650",
      },
      {
        label: "Nillambe",
        value: "20418",
      },
      {
        label: "Nugaliyadda",
        value: "20204",
      },
      {
        label: "Nugawela",
        value: "20072",
      },
      {
        label: "Pallebowala",
        value: "20734",
      },
      {
        label: "Pallekotuwa",
        value: "20084",
      },
      {
        label: "Panvila",
        value: "20830",
      },
      {
        label: "Panwilatenna",
        value: "20544",
      },
      {
        label: "Paradeka",
        value: "20578",
      },
      {
        label: "Pasbage",
        value: "20654",
      },
      {
        label: "Pattitalawa",
        value: "20511",
      },
      {
        label: "Pattiya Watta",
        value: "20118",
      },
      {
        label: "Penideniya",
        value: "NA",
      },
      {
        label: "Peradeniya",
        value: "20400",
      },
      {
        label: "Pilawala",
        value: "20196",
      },
      {
        label: "Pilimatalawa",
        value: "20450",
      },
      {
        label: "Poholiyadda",
        value: "20106",
      },
      {
        label: "Polgolla",
        value: "20250",
      },
      {
        label: "Pujapitiya",
        value: "20112",
      },
      {
        label: "Pupuressa",
        value: "20546",
      },
      {
        label: "Pussellawa",
        value: "20580",
      },
      {
        label: "Putuhapuwa",
        value: "20906",
      },
      {
        label: "Rajawella",
        value: "20180",
      },
      {
        label: "Rambukpitiya",
        value: "20676",
      },
      {
        label: "Rambukwella",
        value: "20128",
      },
      {
        label: "Rangala",
        value: "20922",
      },
      {
        label: "Rantembe",
        value: "20990",
      },
      {
        label: "Rathukohodigala",
        value: "20818",
      },
      {
        label: "Rikillagaskada",
        value: "20730",
      },
      {
        label: "Sangarajapura",
        value: "20044",
      },
      {
        label: "Senarathwela",
        value: "20904",
      },
      {
        label: "Talatuoya",
        value: "20200",
      },
      {
        label: "Tawalantenna",
        value: "20838",
      },
      {
        label: "Teldeniya",
        value: "20900",
      },
      {
        label: "Tennekumbura",
        value: "20166",
      },
      {
        label: "Uda Peradeniya",
        value: "20404",
      },
      {
        label: "Udahentenna",
        value: "20506",
      },
      {
        label: "Udahingulwala",
        value: "20094",
      },
      {
        label: "Udatalawinna",
        value: "20802",
      },
      {
        label: "Udawatta",
        value: "20722",
      },
      {
        label: "Udispattuwa",
        value: "20916",
      },
      {
        label: "Ududumbara",
        value: "20950",
      },
      {
        label: "Uduwa",
        value: "20052",
      },
      {
        label: "Uduwahinna",
        value: "20934",
      },
      {
        label: "Uduwela",
        value: "20164",
      },
      {
        label: "Ulapane",
        value: "20562",
      },
      {
        label: "Ulpothagama",
        value: "20965",
      },
      {
        label: "Unuwinna",
        value: "20708",
      },
      {
        label: "Velamboda",
        value: "20640",
      },
      {
        label: "Watagoda Harispattuwa",
        value: "20134",
      },
      {
        label: "Wattappola",
        value: "20454",
      },
      {
        label: "Wattegama",
        value: "20810",
      },
      {
        label: "Weligalla",
        value: "20610",
      },
      {
        label: "Weligampola",
        value: "20666",
      },
      {
        label: "Wendaruwa",
        value: "20914",
      },
      {
        label: "Weragantota",
        value: "20982",
      },
      {
        label: "Werapitya",
        value: "20908",
      },
      {
        label: "Werellagama",
        value: "20080",
      },
      {
        label: "Wettawa",
        value: "20108",
      },
      {
        label: "Wilanagama",
        value: "20142",
      },
      {
        label: "Yahalatenna",
        value: "20234",
      },
      {
        label: "Yatihalagala",
        value: "20034",
      },
    ],
  },
  {
    label: "Kegalle",
    value: "Kegalle",

    children: [
      {
        label: "Alawatura",
        value: "71204",
      },
      {
        label: "Algama",
        value: "71607",
      },
      {
        label: "Alutnuwara",
        value: "71508",
      },
      {
        label: "Ambalakanda",
        value: "71546",
      },
      {
        label: "Ambulugala",
        value: "71503",
      },
      {
        label: "Amitirigala",
        value: "71320",
      },
      {
        label: "Ampagala",
        value: "71232",
      },
      {
        label: "Anhettigama",
        value: "71403",
      },
      {
        label: "Aranayaka",
        value: "71540",
      },
      {
        label: "Aruggammana",
        value: "71041",
      },
      {
        label: "Atale",
        value: "71363",
      },
      {
        label: "Batuwita",
        value: "71321",
      },
      {
        label: "Berannawa",
        value: "71706",
      },
      {
        label: "Boralankada",
        value: "71418",
      },
      {
        label: "Bossella",
        value: "71208",
      },
      {
        label: "Bulathkohupitiya",
        value: "71230",
      },
      {
        label: "Damunupola",
        value: "71034",
      },
      {
        label: "Debathgama",
        value: "71037",
      },
      {
        label: "Dedugala",
        value: "71237",
      },
      {
        label: "Deewala Pallegama",
        value: "71022",
      },
      {
        label: "Dehiowita",
        value: "71400",
      },
      {
        label: "Deldeniya",
        value: "71009",
      },
      {
        label: "Deloluwa",
        value: "71401",
      },
      {
        label: "Deraniyagala",
        value: "71430",
      },
      {
        label: "Dewalegama",
        value: "71050",
      },
      {
        label: "Dewanagala",
        value: "71527",
      },
      {
        label: "Dombemada",
        value: "71115",
      },
      {
        label: "Dorawaka",
        value: "71601",
      },
      {
        label: "Dunumala",
        value: "71605",
      },
      {
        label: "Galapitamada",
        value: "71603",
      },
      {
        label: "Galatara",
        value: "71505",
      },
      {
        label: "Galigamuwa Town",
        value: "71350",
      },
      {
        label: "Gantuna",
        value: "71222",
      },
      {
        label: "Gonagala",
        value: "71318",
      },
      {
        label: "Hakahinna",
        value: "71352",
      },
      {
        label: "Hakbellawaka",
        value: "71715",
      },
      {
        label: "Helamada",
        value: "71046",
      },
      {
        label: "Hemmatagama",
        value: "71530",
      },
      {
        label: "Hettimulla",
        value: "71210",
      },
      {
        label: "Hewadiwela",
        value: "71108",
      },
      {
        label: "Hingula",
        value: "71520",
      },
      {
        label: "Hinguralakanda",
        value: "71417",
      },
      {
        label: "Hiriwadunna",
        value: "71014",
      },
      {
        label: "Imbulana",
        value: "71313",
      },
      {
        label: "Imbulgasdeniya",
        value: "71055",
      },
      {
        label: "Kabagamuwa",
        value: "71202",
      },
      {
        label: "Kannattota",
        value: "71372",
      },
      {
        label: "Kegalle",
        value: "71000",
      },
      {
        label: "Kehelpannala",
        value: "71533",
      },
      {
        label: "Kitulgala",
        value: "71720",
      },
      {
        label: "Kondeniya",
        value: "71501",
      },
      {
        label: "Kotiyakumbura",
        value: "71370",
      },
      {
        label: "Lewangama",
        value: "71315",
      },
      {
        label: "Mahabage",
        value: "71722",
      },
      {
        label: "Mahapallegama",
        value: "71063",
      },
      {
        label: "Maharangalla",
        value: "71211",
      },
      {
        label: "Makehelwala",
        value: "71507",
      },
      {
        label: "Malalpola",
        value: "71704",
      },
      {
        label: "Maliboda",
        value: "71411",
      },
      {
        label: "Malmaduwa",
        value: "71325",
      },
      {
        label: "Mawanella",
        value: "71500",
      },
      {
        label: "Migastenna Sabara",
        value: "71716",
      },
      {
        label: "Miyanawita",
        value: "71432",
      },
      {
        label: "Molagoda",
        value: "71016",
      },
      {
        label: "Morontota",
        value: "71220",
      },
      {
        label: "Nelundeniya",
        value: "71060",
      },
      {
        label: "Niyadurupola",
        value: "71602",
      },
      {
        label: "Noori",
        value: "71407",
      },
      {
        label: "Parape",
        value: "71105",
      },
      {
        label: "Pattampitiya",
        value: "71130",
      },
      {
        label: "Pitagaldeniya",
        value: "71360",
      },
      {
        label: "Pothukoladeniya",
        value: "71039",
      },
      {
        label: "Rambukkana",
        value: "71100",
      },
      {
        label: "Ruwanwella",
        value: "71300",
      },
      {
        label: "Seaforth Colony",
        value: "71708",
      },
      {
        label: "Talgaspitiya",
        value: "71541",
      },
      {
        label: "Teligama",
        value: "71724",
      },
      {
        label: "Tholangamuwa",
        value: "71619",
      },
      {
        label: "Thotawella",
        value: "71106",
      },
      {
        label: "Tulhiriya",
        value: "71610",
      },
      {
        label: "Tuntota",
        value: "71062",
      },
      {
        label: "Udagaldeniya",
        value: "71113",
      },
      {
        label: "Udapotha",
        value: "71236",
      },
      {
        label: "Udumulla",
        value: "71521",
      },
      {
        label: "Undugoda",
        value: "71200",
      },
      {
        label: "Ussapitiya",
        value: "71510",
      },
      {
        label: "Wahakula",
        value: "71303",
      },
      {
        label: "Waharaka",
        value: "71304",
      },
      {
        label: "Warakapola",
        value: "71600",
      },
      {
        label: "Watura",
        value: "71035",
      },
      {
        label: "Weeoya",
        value: "71702",
      },
      {
        label: "Wegalla",
        value: "71234",
      },
      {
        label: "Welihelatenna",
        value: "71712",
      },
      {
        label: "Weragala",
        value: "71622",
      },
      {
        label: "Yatagama",
        value: "71116",
      },
      {
        label: "Yatapana",
        value: "71326",
      },
      {
        label: "Yatiyantota",
        value: "71700",
      },
      {
        label: "Yattogoda",
        value: "71029",
      },
    ],
  },
  {
    label: "Kilinochchi",
    value: "Kilinochchi",

    children: [
      {
        label: "Kilinochchi",
        value: "44000",
      },
    ],
  },
  {
    label: "Kurunegala",
    value: "Kurunegala",

    children: [
      {
        label: "Alahengama",
        value: "60416",
      },
      {
        label: "Alahitiyawa",
        value: "60182",
      },
      {
        label: "Alawatuwala",
        value: "60047",
      },
      {
        label: "Alawwa",
        value: "60280",
      },
      {
        label: "Ambakote",
        value: "60036",
      },
      {
        label: "Ambanpola",
        value: "60650",
      },
      {
        label: "Anhandiya",
        value: "60074",
      },
      {
        label: "Anukkane",
        value: "60214",
      },
      {
        label: "Aragoda",
        value: "60308",
      },
      {
        label: "Ataragalla",
        value: "60706",
      },
      {
        label: "Awulegama",
        value: "60462",
      },
      {
        label: "Balalla",
        value: "60604",
      },
      {
        label: "Bamunukotuwa",
        value: "60347",
      },
      {
        label: "Bandara Koswatta",
        value: "60424",
      },
      {
        label: "Bingiriya",
        value: "60450",
      },
      {
        label: "Bogamulla",
        value: "60107",
      },
      {
        label: "Bopitiya",
        value: "60155",
      },
      {
        label: "Boraluwewa",
        value: "60437",
      },
      {
        label: "Boyagane",
        value: "60027",
      },
      {
        label: "Bujjomuwa",
        value: "60291",
      },
      {
        label: "Buluwala",
        value: "60076",
      },
      {
        label: "Dambadeniya",
        value: "60130",
      },
      {
        label: "Daraluwa",
        value: "60174",
      },
      {
        label: "Deegalla",
        value: "60228",
      },
      {
        label: "Delwite",
        value: "60044",
      },
      {
        label: "Demataluwa",
        value: "60024",
      },
      {
        label: "Diddeniya",
        value: "60544",
      },
      {
        label: "Digannewa",
        value: "60485",
      },
      {
        label: "Divullegoda",
        value: "60472",
      },
      {
        label: "Dodangaslanda",
        value: "60530",
      },
      {
        label: "Doratiyawa",
        value: "60013",
      },
      {
        label: "Dummalasuriya",
        value: "60260",
      },
      {
        label: "Ehetuwewa",
        value: "60716",
      },
      {
        label: "Elibichchiya",
        value: "60156",
      },
      {
        label: "Embogama",
        value: "60718",
      },
      {
        label: "Etungahakotuwa",
        value: "60266",
      },
      {
        label: "Galgamuwa",
        value: "60700",
      },
      {
        label: "Gallewa",
        value: "60712",
      },
      {
        label: "Girathalana",
        value: "60752",
      },
      {
        label: "Giriulla",
        value: "60140",
      },
      {
        label: "Gokaralla",
        value: "60522",
      },
      {
        label: "Gonawila",
        value: "60170",
      },
      {
        label: "Halmillawewa",
        value: "60441",
      },
      {
        label: "Hengamuwa",
        value: "60414",
      },
      {
        label: "Hettipola",
        value: "60430",
      },
      {
        label: "Hilogama",
        value: "60486",
      },
      {
        label: "Hindagolla",
        value: "60034",
      },
      {
        label: "Hiriyala Lenawa",
        value: "60546",
      },
      {
        label: "Hiruwalpola",
        value: "60458",
      },
      {
        label: "Horambawa",
        value: "60181",
      },
      {
        label: "Hulogedara",
        value: "60474",
      },
      {
        label: "Hulugalla",
        value: "60477",
      },
      {
        label: "Hunupola",
        value: "60582",
      },
      {
        label: "Ihala Gomugomuwa",
        value: "60211",
      },
      {
        label: "Ihala Katugampala",
        value: "60135",
      },
      {
        label: "Indulgodakanda",
        value: "60016",
      },
      {
        label: "Inguruwatta",
        value: "60064",
      },
      {
        label: "Iriyagolla",
        value: "60045",
      },
      {
        label: "Ithanawatta",
        value: "60025",
      },
      {
        label: "Kadigawa",
        value: "60492",
      },
      {
        label: "Kahapathwala",
        value: "60062",
      },
      {
        label: "Kalugamuwa",
        value: "60096",
      },
      {
        label: "Kanadeniyawala",
        value: "60054",
      },
      {
        label: "Kanattewewa",
        value: "60422",
      },
      {
        label: "Karagahagedara",
        value: "60106",
      },
      {
        label: "Karambe",
        value: "60602",
      },
      {
        label: "Katupota",
        value: "60350",
      },
      {
        label: "Kekunagolla",
        value: "60183",
      },
      {
        label: "Keppitiwalana",
        value: "60288",
      },
      {
        label: "Kimbulwanaoya",
        value: "60548",
      },
      {
        label: "Kirimetiyawa",
        value: "60184",
      },
      {
        label: "Kirindawa",
        value: "60212",
      },
      {
        label: "Kirindigalla",
        value: "60502",
      },
      {
        label: "Kithalawa",
        value: "60188",
      },
      {
        label: "Kobeigane",
        value: "60410",
      },
      {
        label: "Kohilagedara",
        value: "60028",
      },
      {
        label: "Konwewa",
        value: "60630",
      },
      {
        label: "Kosdeniya",
        value: "60356",
      },
      {
        label: "Kosgolla",
        value: "60029",
      },
      {
        label: "Kotawehera",
        value: "60483",
      },
      {
        label: "Kudagalgamuwa",
        value: "60003",
      },
      {
        label: "Kudakatnoruwa",
        value: "60754",
      },
      {
        label: "Kuliyapitiya",
        value: "60200",
      },
      {
        label: "Kumbukgeta",
        value: "60508",
      },
      {
        label: "Kumbukwewa",
        value: "60506",
      },
      {
        label: "Kuratihena",
        value: "60438",
      },
      {
        label: "Kurunegala",
        value: "60000",
      },
      {
        label: "Labbala",
        value: "60162",
      },
      {
        label: "lbbagamuwa",
        value: "60500",
      },
      {
        label: "lhala Kadigamuwa",
        value: "60238",
      },
      {
        label: "llukhena",
        value: "60232",
      },
      {
        label: "Lonahettiya",
        value: "60108",
      },
      {
        label: "Madahapola",
        value: "60552",
      },
      {
        label: "Madakumburumulla",
        value: "60209",
      },
      {
        label: "Maduragoda",
        value: "60532",
      },
      {
        label: "Maeliya",
        value: "60512",
      },
      {
        label: "Magulagama",
        value: "60221",
      },
      {
        label: "Mahagalkadawala",
        value: "60731",
      },
      {
        label: "Mahagirilla",
        value: "60479",
      },
      {
        label: "Mahamukalanyaya",
        value: "60516",
      },
      {
        label: "Mahananneriya",
        value: "60724",
      },
      {
        label: "Maharachchimulla",
        value: "60286",
      },
      {
        label: "Maho",
        value: "60600",
      },
      {
        label: "Makulewa",
        value: "60714",
      },
      {
        label: "Makulpotha",
        value: "60514",
      },
      {
        label: "Makulwewa",
        value: "60578",
      },
      {
        label: "Malagane",
        value: "60404",
      },
      {
        label: "Mandapola",
        value: "60434",
      },
      {
        label: "Maspotha",
        value: "60344",
      },
      {
        label: "Mawathagama",
        value: "60060",
      },
      {
        label: "Medivawa",
        value: "60612",
      },
      {
        label: "Meegalawa",
        value: "60750",
      },
      {
        label: "Meetanwala",
        value: "60066",
      },
      {
        label: "Meewellawa",
        value: "60484",
      },
      {
        label: "Melsiripura",
        value: "60540",
      },
      {
        label: "Metikumbura",
        value: "60304",
      },
      {
        label: "Metiyagane",
        value: "60121",
      },
      {
        label: "Minhettiya",
        value: "60004",
      },
      {
        label: "Minuwangete",
        value: "60406",
      },
      {
        label: "Mirihanagama",
        value: "60408",
      },
      {
        label: "Monnekulama",
        value: "60495",
      },
      {
        label: "Moragane",
        value: "60354",
      },
      {
        label: "Moragollagama",
        value: "60640",
      },
      {
        label: "Morathiha",
        value: "60038",
      },
      {
        label: "Munamaldeniya",
        value: "60218",
      },
      {
        label: "Muruthenge",
        value: "60122",
      },
      {
        label: "Nabadewa",
        value: "60482",
      },
      {
        label: "Nagollagama",
        value: "60590",
      },
      {
        label: "Nagollagoda",
        value: "60226",
      },
      {
        label: "Nakkawatta",
        value: "60186",
      },
      {
        label: "Narammala",
        value: "60100",
      },
      {
        label: "Narangoda",
        value: "60152",
      },
      {
        label: "Nawatalwatta",
        value: "60292",
      },
      {
        label: "Nelliya",
        value: "60549",
      },
      {
        label: "Nikadalupotha",
        value: "60580",
      },
      {
        label: "Nikaweratiya",
        value: "60470",
      },
      {
        label: "Padeniya",
        value: "60461",
      },
      {
        label: "Padiwela",
        value: "60236",
      },
      {
        label: "Pahalagiribawa",
        value: "60735",
      },
      {
        label: "Pahamune",
        value: "60112",
      },
      {
        label: "Palukadawala",
        value: "60704",
      },
      {
        label: "Panadaragama",
        value: "60348",
      },
      {
        label: "Panagamuwa",
        value: "60052",
      },
      {
        label: "Panaliya",
        value: "60312",
      },
      {
        label: "Panliyadda",
        value: "60558",
      },
      {
        label: "Pannala",
        value: "60160",
      },
      {
        label: "Pansiyagama",
        value: "60554",
      },
      {
        label: "Periyakadneluwa",
        value: "60518",
      },
      {
        label: "Pihimbiya Ratmale",
        value: "60439",
      },
      {
        label: "Pihimbuwa",
        value: "60053",
      },
      {
        label: "Pilessa",
        value: "60058",
      },
      {
        label: "Polgahawela",
        value: "60300",
      },
      {
        label: "Polpitigama",
        value: "60620",
      },
      {
        label: "Pothuhera",
        value: "60330",
      },
      {
        label: "Puswelitenna",
        value: "60072",
      },
      {
        label: "Ridibendiella",
        value: "60606",
      },
      {
        label: "Ridigama",
        value: "60040",
      },
      {
        label: "Saliya Asokapura",
        value: "60736",
      },
      {
        label: "Sandalankawa",
        value: "60176",
      },
      {
        label: "Sirisetagama",
        value: "60478",
      },
      {
        label: "Siyambalangamuwa",
        value: "60646",
      },
      {
        label: "Solepura",
        value: "60737",
      },
      {
        label: "Solewewa",
        value: "60738",
      },
      {
        label: "Sunandapura",
        value: "60436",
      },
      {
        label: "Talawattegedara",
        value: "60306",
      },
      {
        label: "Tambutta",
        value: "60734",
      },
      {
        label: "Thalahitimulla",
        value: "60208",
      },
      {
        label: "Thalakolawewa",
        value: "60624",
      },
      {
        label: "Thalwita",
        value: "60572",
      },
      {
        label: "Thambagalla",
        value: "60584",
      },
      {
        label: "Tharana Udawela",
        value: "60227",
      },
      {
        label: "Thimbiriyawa",
        value: "60476",
      },
      {
        label: "Tisogama",
        value: "60453",
      },
      {
        label: "Torayaya",
        value: "60499",
      },
      {
        label: "Tuttiripitigama",
        value: "60426",
      },
      {
        label: "Udubaddawa",
        value: "60250",
      },
      {
        label: "Uhumiya",
        value: "60094",
      },
      {
        label: "Ulpotha Pallekele",
        value: "60622",
      },
      {
        label: "Usgala Siyabmalangamuwa",
        value: "60732",
      },
      {
        label: "Wadakada",
        value: "60318",
      },
      {
        label: "Wadumunnegedara",
        value: "60204",
      },
      {
        label: "Walakumburumulla",
        value: "60198",
      },
      {
        label: "Wannigama",
        value: "60465",
      },
      {
        label: "Wannikudawewa",
        value: "60721",
      },
      {
        label: "Wannilhalagama",
        value: "60722",
      },
      {
        label: "Wannirasnayakapura",
        value: "60490",
      },
      {
        label: "Warawewa",
        value: "60739",
      },
      {
        label: "Wariyapola",
        value: "60400",
      },
      {
        label: "Watuwatta",
        value: "60262",
      },
      {
        label: "Weerapokuna",
        value: "60454",
      },
      {
        label: "Welawa Juncton",
        value: "60464",
      },
      {
        label: "Welipennagahamulla",
        value: "60240",
      },
      {
        label: "Wellagala",
        value: "60402",
      },
      {
        label: "Wellarawa",
        value: "60456",
      },
      {
        label: "Wellawa",
        value: "60570",
      },
      {
        label: "Welpalla",
        value: "60206",
      },
      {
        label: "Wennoruwa",
        value: "60284",
      },
      {
        label: "Weuda",
        value: "60080",
      },
      {
        label: "Wewagama",
        value: "60195",
      },
      {
        label: "Yakwila",
        value: "60202",
      },
      {
        label: "Yatigaloluwa",
        value: "60314",
      },
    ],
  },
  {
    label: "Mannar",
    value: "Mannar",

    children: [
      {
        label: "Adampan",
        value: "41000",
      },
      {
        label: "Arippu",
        value: "41000",
      },
      {
        label: "Athimottai",
        value: "41000",
      },
      {
        label: "Chilavathurai",
        value: "41000",
      },
      {
        label: "Erukkalampiddy",
        value: "41000",
      },
      {
        label: "llluppaikadavai",
        value: "41000",
      },
      {
        label: "Madhu Church",
        value: "41000",
      },
      {
        label: "Madhu Road",
        value: "41000",
      },
      {
        label: "Mannar",
        value: "41000",
      },
      {
        label: "Marichchi Kaddi",
        value: "41000",
      },
      {
        label: "Murunkan",
        value: "41000",
      },
      {
        label: "Nanattan",
        value: "41000",
      },
      {
        label: "P.P.Potkemy",
        value: "41000",
      },
      {
        label: "Palampiddy",
        value: "41000",
      },
      {
        label: "Periyakunchikulam",
        value: "41000",
      },
      {
        label: "Periyamadhu",
        value: "41000",
      },
      {
        label: "Pesalai",
        value: "41000",
      },
      {
        label: "Talaimannar",
        value: "41000",
      },
      {
        label: "Temple",
        value: "41000",
      },
      {
        label: "Tharapuram",
        value: "41000",
      },
      {
        label: "Thiruketheeswaram Temple",
        value: "41000",
      },
      {
        label: "Uyilankulam",
        value: "41000",
      },
      {
        label: "Vaddakandal",
        value: "41000",
      },
      {
        label: "Vankalai",
        value: "41000",
      },
      {
        label: "Vellan Kulam",
        value: "41000",
      },
      {
        label: "Vidataltivu",
        value: "41000",
      },
    ],
  },
  {
    label: "Matale",
    value: "Matale",

    children: [
      {
        label: "Akuramboda",
        value: "21142",
      },
      {
        label: "Alwatta",
        value: "21004",
      },
      {
        label: "Ambana",
        value: "21504",
      },
      {
        label: "Ataragallewa",
        value: "21512",
      },
      {
        label: "Bambaragaswewa",
        value: "21212",
      },
      {
        label: "Beligamuwa",
        value: "21214",
      },
      {
        label: "Dambulla",
        value: "21100",
      },
      {
        label: "Dankanda",
        value: "21032",
      },
      {
        label: "Devagiriya",
        value: "21552",
      },
      {
        label: "Dewahuwa",
        value: "21206",
      },
      {
        label: "Dullewa",
        value: "21054",
      },
      {
        label: "Dunkolawatta",
        value: "21046",
      },
      {
        label: "Dunuwilapitiya",
        value: "21538",
      },
      {
        label: "Elkaduwa",
        value: "21012",
      },
      {
        label: "Erawula Junction",
        value: "21108",
      },
      {
        label: "Etanawala",
        value: "21402",
      },
      {
        label: "Galewela",
        value: "21200",
      },
      {
        label: "Gammaduwa",
        value: "21068",
      },
      {
        label: "Gangala Puwakpitiya",
        value: "21404",
      },
      {
        label: "Handungamuwa",
        value: "21536",
      },
      {
        label: "Hattota Amuna",
        value: "21514",
      },
      {
        label: "Imbulgolla",
        value: "21064",
      },
      {
        label: "Inamaluwa",
        value: "21124",
      },
      {
        label: "Kaikawala",
        value: "21066",
      },
      {
        label: "Kalundawa",
        value: "21112",
      },
      {
        label: "Kandalama",
        value: "21106",
      },
      {
        label: "Karagahinna",
        value: "21014",
      },
      {
        label: "Katudeniya",
        value: "21016",
      },
      {
        label: "Kavudupelella",
        value: "21072",
      },
      {
        label: "Kibissa",
        value: "21122",
      },
      {
        label: "Kiwula",
        value: "21042",
      },
      {
        label: "Kongahawela",
        value: "21500",
      },
      {
        label: "Laggala Pallegama",
        value: "21520",
      },
      {
        label: "Leliambe",
        value: "21008",
      },
      {
        label: "Lenadora",
        value: "21094",
      },
      {
        label: "lllukkumbura",
        value: "21406",
      },
      {
        label: "Madawala Ulpotha",
        value: "21074",
      },
      {
        label: "Madipola",
        value: "21156",
      },
      {
        label: "Mahawela",
        value: "21140",
      },
      {
        label: "Mananwatta",
        value: "21144",
      },
      {
        label: "Maraka",
        value: "21554",
      },
      {
        label: "Matale",
        value: "21000",
      },
      {
        label: "Melipitiya",
        value: "21055",
      },
      {
        label: "Metihakka",
        value: "21062",
      },
      {
        label: "Millawana",
        value: "21154",
      },
      {
        label: "Muwandeniya",
        value: "21044",
      },
      {
        label: "Nalanda",
        value: "21082",
      },
      {
        label: "Naula",
        value: "21090",
      },
      {
        label: "Nugagolla",
        value: "21534",
      },
      {
        label: "Opalgala",
        value: "21076",
      },
      {
        label: "Ovilikanda",
        value: "21020",
      },
      {
        label: "Palapathwela",
        value: "21070",
      },
      {
        label: "Pallepola",
        value: "21152",
      },
      {
        label: "Perakanatta",
        value: "21532",
      },
      {
        label: "Pubbiliya",
        value: "21502",
      },
      {
        label: "Ranamuregama",
        value: "21524",
      },
      {
        label: "Rattota",
        value: "21400",
      },
      {
        label: "Selagama",
        value: "21058",
      },
      {
        label: "Sigiriya",
        value: "21120",
      },
      {
        label: "Talagoda Junction",
        value: "21506",
      },
      {
        label: "Talakiriyagama",
        value: "21116",
      },
      {
        label: "Udasgiriya",
        value: "21051",
      },
      {
        label: "Udatenna",
        value: "21006",
      },
      {
        label: "Ukuwela",
        value: "21300",
      },
      {
        label: "Wahacotte",
        value: "21160",
      },
      {
        label: "Walawela",
        value: "21048",
      },
      {
        label: "Wehigala",
        value: "21009",
      },
      {
        label: "Welangahawatte",
        value: "21408",
      },
      {
        label: "Wewalawewa",
        value: "21114",
      },
      {
        label: "Wilgamuwa",
        value: "21530",
      },
      {
        label: "Yatawatta",
        value: "21056",
      },
    ],
  },
  {
    label: "Matara",
    value: "Matara",

    children: [
      {
        label: "Akuressa",
        value: "81400",
      },
      {
        label: "Alapaladeniya",
        value: "81475",
      },
      {
        label: "Aparekka",
        value: "81032",
      },
      {
        label: "Athuraliya",
        value: "81402",
      },
      {
        label: "Bengamuwa",
        value: "81614",
      },
      {
        label: "Beralapanathara",
        value: "81541",
      },
      {
        label: "Bopagoda",
        value: "81412",
      },
      {
        label: "Dampahala",
        value: "81612",
      },
      {
        label: "Deegala Lenama",
        value: "81452",
      },
      {
        label: "Deiyandara",
        value: "81320",
      },
      {
        label: "Dellawa",
        value: "81477",
      },
      {
        label: "Denagama",
        value: "81314",
      },
      {
        label: "Denipitiya",
        value: "81730",
      },
      {
        label: "Deniyaya",
        value: "81500",
      },
      {
        label: "Derangala",
        value: "81454",
      },
      {
        label: "Dikwella",
        value: "81200",
      },
      {
        label: "Diyagaha",
        value: "81038",
      },
      {
        label: "Diyalape",
        value: "81422",
      },
      {
        label: "Gandara",
        value: "81170",
      },
      {
        label: "Godapitiya",
        value: "81408",
      },
      {
        label: "Gomilamawarala",
        value: "81072",
      },
      {
        label: "Hakmana",
        value: "81300",
      },
      {
        label: "Handugala",
        value: "81326",
      },
      {
        label: "Horapawita",
        value: "81108",
      },
      {
        label: "Kalubowitiyana",
        value: "81478",
      },
      {
        label: "Kamburugamuwa",
        value: "81750",
      },
      {
        label: "Kamburupitiya",
        value: "81100",
      },
      {
        label: "Karagoda Uyangoda",
        value: "81082",
      },
      {
        label: "Karaputugala",
        value: "81106",
      },
      {
        label: "Karatota",
        value: "81308",
      },
      {
        label: "Kekanadurra",
        value: "81020",
      },
      {
        label: "Kiriweldola",
        value: "81514",
      },
      {
        label: "Kiriwelkele",
        value: "81456",
      },
      {
        label: "Kolawenigama",
        value: "81522",
      },
      {
        label: "Kotapola",
        value: "81480",
      },
      {
        label: "Kottegoda",
        value: "81180",
      },
      {
        label: "Lankagama",
        value: "81526",
      },
      {
        label: "Makandura",
        value: "81070",
      },
      {
        label: "Maliduwa",
        value: "81424",
      },
      {
        label: "Maramba",
        value: "81416",
      },
      {
        label: "Matara",
        value: "81000",
      },
      {
        label: "Mediripitiya",
        value: "81524",
      },
      {
        label: "Miella",
        value: "81312",
      },
      {
        label: "Mirissa",
        value: "81740",
      },
      {
        label: "Moragala Kirillapone",
        value: "81532",
      },
      {
        label: "Morawaka",
        value: "81470",
      },
      {
        label: "Mulatiyana Junction",
        value: "81071",
      },
      {
        label: "Nadugala",
        value: "81092",
      },
      {
        label: "Naimana",
        value: "81017",
      },
      {
        label: "Narawelpita",
        value: "81302",
      },
      {
        label: "Pahala Millawa",
        value: "81472",
      },
      {
        label: "Palatuwa",
        value: "81050",
      },
      {
        label: "Paragala",
        value: "81474",
      },
      {
        label: "Parapamulla",
        value: "81322",
      },
      {
        label: "Pasgoda",
        value: "81615",
      },
      {
        label: "Penetiyana",
        value: "81722",
      },
      {
        label: "Pitabeddara",
        value: "81450",
      },
      {
        label: "Pothdeniya",
        value: "81538",
      },
      {
        label: "Puhulwella",
        value: "81290",
      },
      {
        label: "Radawela",
        value: "81316",
      },
      {
        label: "Ransegoda",
        value: "81064",
      },
      {
        label: "Ratmale",
        value: "81030",
      },
      {
        label: "Rotumba",
        value: "81074",
      },
      {
        label: "Siyambalagoda",
        value: "81462",
      },
      {
        label: "Sultanagoda",
        value: "81051",
      },
      {
        label: "Telijjawila",
        value: "81060",
      },
      {
        label: "Thihagoda",
        value: "81280",
      },
      {
        label: "Urubokka",
        value: "81600",
      },
      {
        label: "Urugamuwa",
        value: "81230",
      },
      {
        label: "Urumutta",
        value: "81414",
      },
      {
        label: "Viharahena",
        value: "81508",
      },
      {
        label: "Walakanda",
        value: "81294",
      },
      {
        label: "Walasgala",
        value: "81220",
      },
      {
        label: "Waralla",
        value: "81479",
      },
      {
        label: "Weligama",
        value: "81700",
      },
      {
        label: "Wilpita",
        value: "81404",
      },
      {
        label: "Yatiyana",
        value: "81034",
      },
    ],
  },
  {
    label: "Monaragala",
    value: "Monaragala",

    children: [
      {
        label: "Angunakolawewa",
        value: "91302",
      },
      {
        label: "Ayiwela",
        value: "91516",
      },
      {
        label: "Badalkumbura",
        value: "91070",
      },
      {
        label: "Baduluwela",
        value: "91058",
      },
      {
        label: "Bakinigahawela",
        value: "91554",
      },
      {
        label: "Balaharuwa",
        value: "91295",
      },
      {
        label: "Bibile",
        value: "91500",
      },
      {
        label: "Buddama",
        value: "91038",
      },
      {
        label: "Buttala",
        value: "91100",
      },
      {
        label: "Dambagalla",
        value: "91050",
      },
      {
        label: "Diyakobala",
        value: "91514",
      },
      {
        label: "Dombagahawela",
        value: "91010",
      },
      {
        label: "Ekamutugama",
        value: "70254",
      },
      {
        label: "Ekiriyankumbura",
        value: "91502",
      },
      {
        label: "Ethimalewewa",
        value: "91020",
      },
      {
        label: "Ettiliwewa",
        value: "91250",
      },
      {
        label: "Galabedda",
        value: "91008",
      },
      {
        label: "Hambegamuwa",
        value: "91308",
      },
      {
        label: "Hulandawa",
        value: "91004",
      },
      {
        label: "Inginiyagala",
        value: "91040",
      },
      {
        label: "Kandaudapanguwa",
        value: "91032",
      },
      {
        label: "Kandawinna",
        value: "91552",
      },
      {
        label: "Kataragama",
        value: "91400",
      },
      {
        label: "Kiriibbanwewa",
        value: "70252",
      },
      {
        label: "Kotagama",
        value: "91512",
      },
      {
        label: "Kotawehera Mankada",
        value: "91312",
      },
      {
        label: "Kotiyagala",
        value: "91024",
      },
      {
        label: "Kumbukkana",
        value: "91098",
      },
      {
        label: "Mahagama Colony",
        value: "70256",
      },
      {
        label: "Marawa",
        value: "91006",
      },
      {
        label: "Mariarawa",
        value: "91052",
      },
      {
        label: "Medagana",
        value: "91550",
      },
      {
        label: "Monaragala",
        value: "91000",
      },
      {
        label: "Moretuwegama",
        value: "91108",
      },
      {
        label: "Nakkala",
        value: "91003",
      },
      {
        label: "Nannapurawa",
        value: "91519",
      },
      {
        label: "Nelliyadda",
        value: "91042",
      },
      {
        label: "Nilgala",
        value: "91508",
      },
      {
        label: "Obbegoda",
        value: "91007",
      },
      {
        label: "Okkampitiya",
        value: "91060",
      },
      {
        label: "Pangura",
        value: "91002",
      },
      {
        label: "Pitakumbura",
        value: "91505",
      },
      {
        label: "Randeniya",
        value: "91204",
      },
      {
        label: "Ruwalwela",
        value: "91056",
      },
      {
        label: "Sella Kataragama",
        value: "91405",
      },
      {
        label: "Sewanagala",
        value: "70250",
      },
      {
        label: "Siyambalagune",
        value: "91202",
      },
      {
        label: "Siyambalanduwa",
        value: "91030",
      },
      {
        label: "Suriara",
        value: "91306",
      },
      {
        label: "Tanamalwila",
        value: "91300",
      },
      {
        label: "Uva Gangodagama",
        value: "91054",
      },
      {
        label: "Uva Kudaoya",
        value: "91298",
      },
      {
        label: "Uva Pelwatta",
        value: "91112",
      },
      {
        label: "Warunagama",
        value: "91198",
      },
      {
        label: "Wedikumbura",
        value: "91005",
      },
      {
        label: "Weherayaya Handapanagala",
        value: "91206",
      },
      {
        label: "Wellawaya",
        value: "91200",
      },
      {
        label: "Wilaoya",
        value: "91022",
      },
    ],
  },
  {
    label: "Mullativu",
    value: "Mullativu",

    children: [
      {
        label: "Mullativu",
        value: "42000",
      },
      {
        label: "Alampil",
        value: "42000",
      },
      {
        label: "Karuppaddamurippu",
        value: "42000",
      },
      {
        label: "Kokkilai",
        value: "42000",
      },
      {
        label: "Kokkuthuoduvai",
        value: "42000",
      },
      {
        label: "Mankulam",
        value: "42000",
      },
      {
        label: "Mullivaikkal",
        value: "42000",
      },
      {
        label: "Mulliyawalai",
        value: "42000",
      },
      {
        label: "Muthauyan Kaddakulam",
        value: "42000",
      },
      {
        label: "Naddan Kandal",
        value: "42000",
      },
      {
        label: "Odduchudan",
        value: "42000",
      },
      {
        label: "Puthuvedduvan",
        value: "42000",
      },
      {
        label: "Thunukkai",
        value: "42000",
      },
      {
        label: "Udayarkaddu",
        value: "42000",
      },
      {
        label: "Vavunakkulam",
        value: "42000",
      },
      {
        label: "Visvamadukulam",
        value: "42000",
      },
      {
        label: "Yogapuram",
        value: "42000",
      },
    ],
  },
  {
    label: "Nuwara Eliya",
    value: "Nuwara Eliya",

    children: [
      {
        label: "Agarapathana",
        value: "22094",
      },
      {
        label: "Ambagamuwa Udabulathgama",
        value: "20678",
      },
      {
        label: "Ambatalawa",
        value: "20686",
      },
      {
        label: "Ambewela",
        value: "22216",
      },
      {
        label: "Bogawantalawa",
        value: "22060",
      },
      {
        label: "Bopattalawa",
        value: "22095",
      },
      {
        label: "Dagampitiya",
        value: "20684",
      },
      {
        label: "Dayagama Bazaar",
        value: "22096",
      },
      {
        label: "Dikoya",
        value: "22050",
      },
      {
        label: "Doragala",
        value: "20567",
      },
      {
        label: "Dunukedeniya",
        value: "22002",
      },
      {
        label: "Ginigathena",
        value: "20680",
      },
      {
        label: "Gonakele",
        value: "22226",
      },
      {
        label: "Haggala",
        value: "22208",
      },
      {
        label: "Halgranoya",
        value: "22240",
      },
      {
        label: "Hangarapitiya",
        value: "22044",
      },
      {
        label: "Hapugastalawa",
        value: "20668",
      },
      {
        label: "Harangalagama",
        value: "20669",
      },
      {
        label: "Harasbedda",
        value: "22262",
      },
      {
        label: "Hatton",
        value: "22000",
      },
      {
        label: "Hedunuwewa",
        value: "22024",
      },
      {
        label: "Hitigegama",
        value: "22046",
      },
      {
        label: "Kalaganwatta",
        value: "22282",
      },
      {
        label: "Kandapola",
        value: "22220",
      },
      {
        label: "Katukitula",
        value: "20588",
      },
      {
        label: "Keerthi Bandarapura",
        value: "22274",
      },
      {
        label: "Kelanigama",
        value: "20688",
      },
      {
        label: "Ketaboola",
        value: "20660",
      },
      {
        label: "Kotagala",
        value: "22080",
      },
      {
        label: "Kotmale",
        value: "20560",
      },
      {
        label: "Kottellena",
        value: "22040",
      },
      {
        label: "Kumbalgamuwa",
        value: "22272",
      },
      {
        label: "Kumbukwela",
        value: "22246",
      },
      {
        label: "Kurupanawela",
        value: "22252",
      },
      {
        label: "Labukele",
        value: "20592",
      },
      {
        label: "Laxapana",
        value: "22034",
      },
      {
        label: "Lindula",
        value: "22090",
      },
      {
        label: "Madulla",
        value: "22256",
      },
      {
        label: "Maldeniya",
        value: "22021",
      },
      {
        label: "Maskeliya",
        value: "22070",
      },
      {
        label: "Maswela",
        value: "20566",
      },
      {
        label: "Mipanawa",
        value: "22254",
      },
      {
        label: "Mipilimana",
        value: "22214",
      },
      {
        label: "Morahenagama",
        value: "22036",
      },
      {
        label: "Munwatta",
        value: "20752",
      },
      {
        label: "Nanuoya",
        value: "22150",
      },
      {
        label: "Nawathispane",
        value: "20670",
      },
      {
        label: "Nayapana Janapadaya",
        value: "20568",
      },
      {
        label: "Nildandahinna",
        value: "22280",
      },
      {
        label: "Nissanka Uyana",
        value: "22075",
      },
      {
        label: "Norwood",
        value: "22058",
      },
      {
        label: "Nuwara Eliya",
        value: "22200",
      },
      {
        label: "Padiyapelella",
        value: "20750",
      },
      {
        label: "Patana",
        value: "22012",
      },
      {
        label: "Pitawala",
        value: "20682",
      },
      {
        label: "Pundaluoya",
        value: "22120",
      },
      {
        label: "Ramboda",
        value: "20590",
      },
      {
        label: "Rozella",
        value: "22008",
      },
      {
        label: "Rupaha",
        value: "22245",
      },
      {
        label: "Ruwaneliya",
        value: "22212",
      },
      {
        label: "Santhipura",
        value: "22202",
      },
      {
        label: "Talawakele",
        value: "22100",
      },
      {
        label: "Teripeha",
        value: "22287",
      },
      {
        label: "Udamadura",
        value: "22285",
      },
      {
        label: "Udapussallawa",
        value: "22250",
      },
      {
        label: "Walapane",
        value: "22270",
      },
      {
        label: "Watagoda",
        value: "22110",
      },
      {
        label: "Watawala",
        value: "22010",
      },
      {
        label: "Widulipura",
        value: "22032",
      },
      {
        label: "Wijebahukanda",
        value: "22018",
      },
    ],
  },
  {
    label: "Polonnaruwa",
    value: "Polonnaruwa",

    children: [
      {
        label: "Alutwewa",
        value: "51014",
      },
      {
        label: "Aralaganwila",
        value: "51100",
      },
      {
        label: "Aselapura",
        value: "51072",
      },
      {
        label: "Attanakadawala",
        value: "51235",
      },
      {
        label: "Bakamuna",
        value: "51250",
      },
      {
        label: "Dalukana",
        value: "51092",
      },
      {
        label: "Damminna",
        value: "51106",
      },
      {
        label: "Dewagala",
        value: "51094",
      },
      {
        label: "Dimbulagala",
        value: "51031",
      },
      {
        label: "Divulankadawala",
        value: "51428",
      },
      {
        label: "Divuldamana",
        value: "51104",
      },
      {
        label: "Diyabeduma",
        value: "51225",
      },
      {
        label: "Diyasenpura",
        value: "51504",
      },
      {
        label: "Elahera",
        value: "51258",
      },
      {
        label: "Ellewewa",
        value: "51034",
      },
      {
        label: "Galamuna",
        value: "51416",
      },
      {
        label: "Galoya Junction",
        value: "51375",
      },
      {
        label: "Giritale",
        value: "51026",
      },
      {
        label: "Hansayapalama",
        value: "51098",
      },
      {
        label: "Hingurakdamana",
        value: "51408",
      },
      {
        label: "Hingurakgoda",
        value: "51400",
      },
      {
        label: "Jayanthipura",
        value: "51024",
      },
      {
        label: "Jayasiripura",
        value: "51246",
      },
      {
        label: "Kalingaela",
        value: "51002",
      },
      {
        label: "Kalukele Badanagala",
        value: "51037",
      },
      {
        label: "Kashyapapura",
        value: "51032",
      },
      {
        label: "Kawudulla",
        value: "51414",
      },
      {
        label: "Kawuduluwewa Stagell",
        value: "51514",
      },
      {
        label: "Kottapitiya",
        value: "51244",
      },
      {
        label: "Kumaragama",
        value: "51412",
      },
      {
        label: "Lakshauyana",
        value: "51006",
      },
      {
        label: "Maduruoya",
        value: "51108",
      },
      {
        label: "Maha Ambagaswewa",
        value: "51518",
      },
      {
        label: "Mahatalakolawewa",
        value: "51506",
      },
      {
        label: "Mahawela Sinhapura",
        value: "51076",
      },
      {
        label: "Mampitiya",
        value: "51090",
      },
      {
        label: "Medirigiriya",
        value: "51500",
      },
      {
        label: "Meegaswewa",
        value: "51508",
      },
      {
        label: "Minneriya",
        value: "51410",
      },
      {
        label: "Mutugala",
        value: "51064",
      },
      {
        label: "Nawasenapura",
        value: "51066",
      },
      {
        label: "Nelumwewa",
        value: "51096",
      },
      {
        label: "Nuwaragala",
        value: "51039",
      },
      {
        label: "Onegama",
        value: "51004",
      },
      {
        label: "Orubendi Siyambalawa",
        value: "51256",
      },
      {
        label: "Palugasdamana",
        value: "51046",
      },
      {
        label: "Parakramasamudraya",
        value: "51016",
      },
      {
        label: "Pelatiyawa",
        value: "51033",
      },
      {
        label: "Pimburattewa",
        value: "51102",
      },
      {
        label: "Polonnaruwa",
        value: "51000",
      },
      {
        label: "Pulastigama",
        value: "51050",
      },
      {
        label: "Sevanapitiya",
        value: "51062",
      },
      {
        label: "Sinhagama",
        value: "51378",
      },
      {
        label: "Sungavila",
        value: "51052",
      },
      {
        label: "Talpotha",
        value: "51044",
      },
      {
        label: "Tamankaduwa",
        value: "51089",
      },
      {
        label: "Tambala",
        value: "51049",
      },
      {
        label: "Unagalavehera",
        value: "51008",
      },
      {
        label: "Welikanda",
        value: "51070",
      },
      {
        label: "Wijayabapura",
        value: "51042",
      },
      {
        label: "Yodaela",
        value: "51422",
      },
      {
        label: "Yudaganawa",
        value: "51424",
      },
    ],
  },
  {
    label: "Puttalam",
    value: "Puttalam",

    children: [
      {
        label: "Adippala",
        value: "61012",
      },
      {
        label: "Ambakandawila",
        value: "61024",
      },
      {
        label: "Anamaduwa",
        value: "61500",
      },
      {
        label: "Andigama",
        value: "61508",
      },
      {
        label: "Angunawila",
        value: "61264",
      },
      {
        label: "Attawilluwa",
        value: "61328",
      },
      {
        label: "Bangadeniya",
        value: "61238",
      },
      {
        label: "Baranankattuwa",
        value: "61262",
      },
      {
        label: "Battuluoya",
        value: "61246",
      },
      {
        label: "Bujjampola",
        value: "61136",
      },
      {
        label: "Chilaw",
        value: "61000",
      },
      {
        label: "Dankotuwa",
        value: "61130",
      },
      {
        label: "Dunkannawa",
        value: "61192",
      },
      {
        label: "Eluwankulama",
        value: "61308",
      },
      {
        label: "Ettale",
        value: "61343",
      },
      {
        label: "Galmuruwa",
        value: "61233",
      },
      {
        label: "Ihala Kottaramulla",
        value: "61154",
      },
      {
        label: "Ihala Puliyankulama",
        value: "61316",
      },
      {
        label: "Ilippadeniya",
        value: "61018",
      },
      {
        label: "Inginimitiya",
        value: "61514",
      },
      {
        label: "Ismailpuram",
        value: "61302",
      },
      {
        label: "Kakkapalliya",
        value: "61236",
      },
      {
        label: "Kalladiya",
        value: "61534",
      },
      {
        label: "Kalpitiya",
        value: "61360",
      },
      {
        label: "Kandakuliya",
        value: "61358",
      },
      {
        label: "Karativponparappi",
        value: "61307",
      },
      {
        label: "Karawitagara",
        value: "61022",
      },
      {
        label: "Karuwalagaswewa",
        value: "61314",
      },
      {
        label: "Katuneriya",
        value: "61180",
      },
      {
        label: "Kirimundalama",
        value: "61362",
      },
      {
        label: "Koswatta",
        value: "61158",
      },
      {
        label: "Kottantivu",
        value: "61252",
      },
      {
        label: "Kottukachchiya",
        value: "61532",
      },
      {
        label: "Kudawewa",
        value: "61226",
      },
      {
        label: "Kumarakattuwa",
        value: "61032",
      },
      {
        label: "Kurinjanpitiya",
        value: "61356",
      },
      {
        label: "Kuruketiyawa",
        value: "61516",
      },
      {
        label: "Lihiriyagama",
        value: "61138",
      },
      {
        label: "Lunuwila",
        value: "61150",
      },
      {
        label: "Madampe",
        value: "61230",
      },
      {
        label: "Madurankuliya",
        value: "61270",
      },
      {
        label: "Mahakumbukkadawala",
        value: "61272",
      },
      {
        label: "Mahauswewa",
        value: "61512",
      },
      {
        label: "Mahawewa",
        value: "61220",
      },
      {
        label: "Mampuri",
        value: "61341",
      },
      {
        label: "Mangalaeliya",
        value: "61266",
      },
      {
        label: "Marawila",
        value: "61210",
      },
      {
        label: "Mudalakkuliya",
        value: "61506",
      },
      {
        label: "Mugunuwatawana",
        value: "61014",
      },
      {
        label: "Mukkutoduwawa",
        value: "61274",
      },
      {
        label: "Mundel",
        value: "61250",
      },
      {
        label: "Muttibendiwila",
        value: "61195",
      },
      {
        label: "Nainamadama",
        value: "61120",
      },
      {
        label: "Nalladarankattuwa",
        value: "61244",
      },
      {
        label: "Nattandiya",
        value: "61190",
      },
      {
        label: "Nawagattegama",
        value: "61520",
      },
      {
        label: "Norachcholai",
        value: "61342",
      },
      {
        label: "Palaviya",
        value: "61280",
      },
      {
        label: "Pallama",
        value: "61040",
      },
      {
        label: "Palliwasalturai",
        value: "61354",
      },
      {
        label: "Panirendawa",
        value: "61234",
      },
      {
        label: "Pothuwatawana",
        value: "61162",
      },
      {
        label: "Puttalam",
        value: "61300",
      },
      {
        label: "Puttalam Cement Factory",
        value: "61326",
      },
      {
        label: "Rajakadaluwa",
        value: "61242",
      },
      {
        label: "Saliyawewa Junction",
        value: "61324",
      },
      {
        label: "Serukele",
        value: "61042",
      },
      {
        label: "Sirambiadiya",
        value: "61312",
      },
      {
        label: "Siyambalagashene",
        value: "61504",
      },
      {
        label: "Tabbowa",
        value: "61322",
      },
      {
        label: "Talawila Church",
        value: "61344",
      },
      {
        label: "Toduwawa",
        value: "61224",
      },
      {
        label: "Udappuwa",
        value: "61004",
      },
      {
        label: "Uridyawa",
        value: "61502",
      },
      {
        label: "Vanathawilluwa",
        value: "61306",
      },
      {
        label: "Waikkal",
        value: "61110",
      },
      {
        label: "Watugahamulla",
        value: "61198",
      },
      {
        label: "Wennappuwa",
        value: "61170",
      },
      {
        label: "Wijeyakatupotha",
        value: "61006",
      },
      {
        label: "Wilpotha",
        value: "61008",
      },
      {
        label: "Yogiyana",
        value: "61144",
      },
    ],
  },
  {
    label: "Ratnapura",
    value: "Ratnapura",

    children: [
      {
        label: "Akarella",
        value: "70082",
      },
      {
        label: "Atakalanpanna",
        value: "70294",
      },
      {
        label: "Ayagama",
        value: "70024",
      },
      {
        label: "Balangoda",
        value: "70100",
      },
      {
        label: "Batatota",
        value: "70504",
      },
      {
        label: "Belihuloya",
        value: "70140",
      },
      {
        label: "Bolthumbe",
        value: "70131",
      },
      {
        label: "Bomluwageaina",
        value: "70344",
      },
      {
        label: "Bulutota",
        value: "70346",
      },
      {
        label: "Dambuluwana",
        value: "70019",
      },
      {
        label: "Daugala",
        value: "70455",
      },
      {
        label: "Dela",
        value: "70042",
      },
      {
        label: "Delwala",
        value: "70046",
      },
      {
        label: "Demuwatha",
        value: "70332",
      },
      {
        label: "Dodampe",
        value: "70017",
      },
      {
        label: "Doloswalakanda",
        value: "70404",
      },
      {
        label: "Dumbara Manana",
        value: "70495",
      },
      {
        label: "Eheliyagoda",
        value: "70600",
      },
      {
        label: "Elapatha",
        value: "70032",
      },
      {
        label: "Ellagawa",
        value: "70492",
      },
      {
        label: "Ellaulla",
        value: "70552",
      },
      {
        label: "Ellawala",
        value: "70606",
      },
      {
        label: "Embilipitiya",
        value: "70200",
      },
      {
        label: "Eratna",
        value: "70506",
      },
      {
        label: "Erepola",
        value: "70602",
      },
      {
        label: "Gabbela",
        value: "70156",
      },
      {
        label: "Gallella",
        value: "70062",
      },
      {
        label: "Gangeyaya",
        value: "70195",
      },
      {
        label: "Gawaragiriya",
        value: "70026",
      },
      {
        label: "Getahetta",
        value: "70620",
      },
      {
        label: "Gillimale",
        value: "70002",
      },
      {
        label: "Godagampola",
        value: "70556",
      },
      {
        label: "Godakawela",
        value: "70160",
      },
      {
        label: "Gurubewilagama",
        value: "70136",
      },
      {
        label: "Halpe",
        value: "70145",
      },
      {
        label: "Halwinna",
        value: "70171",
      },
      {
        label: "Handagiriya",
        value: "70106",
      },
      {
        label: "Hapugastenna",
        value: "70164",
      },
      {
        label: "Hatangala",
        value: "70105",
      },
      {
        label: "Hatarabage",
        value: "70108",
      },
      {
        label: "Hidellana",
        value: "70012",
      },
      {
        label: "Hiramadagama",
        value: "70296",
      },
      {
        label: "Ihalagama",
        value: "70144",
      },
      {
        label: "Ittakanda",
        value: "70342",
      },
      {
        label: "Kahangama",
        value: "70016",
      },
      {
        label: "Kahawatta",
        value: "70150",
      },
      {
        label: "Kalawana",
        value: "70450",
      },
      {
        label: "Kaltota",
        value: "70122",
      },
      {
        label: "Karandana",
        value: "70488",
      },
      {
        label: "Karangoda",
        value: "70018",
      },
      {
        label: "Kella Junction",
        value: "70352",
      },
      {
        label: "Kiriella",
        value: "70480",
      },
      {
        label: "Kolambageara",
        value: "70180",
      },
      {
        label: "Kolombugama",
        value: "70403",
      },
      {
        label: "Kolonna",
        value: "70350",
      },
      {
        label: "Kudawa",
        value: "70005",
      },
      {
        label: "Kuruwita",
        value: "70500",
      },
      {
        label: "Lellopitiya",
        value: "70056",
      },
      {
        label: "lmbulpe",
        value: "70134",
      },
      {
        label: "Madalagama",
        value: "70158",
      },
      {
        label: "Mahawalatenna",
        value: "70112",
      },
      {
        label: "Makandura Sabara",
        value: "70298",
      },
      {
        label: "Malwala Junction",
        value: "70001",
      },
      {
        label: "Marapana",
        value: "70041",
      },
      {
        label: "Matuwagalagama",
        value: "70482",
      },
      {
        label: "Medagalatur",
        value: "70021",
      },
      {
        label: "Meddekanda",
        value: "70127",
      },
      {
        label: "Minipura Dumbara",
        value: "70494",
      },
      {
        label: "Mitipola",
        value: "70604",
      },
      {
        label: "Morahela",
        value: "70129",
      },
      {
        label: "Mulendiyawala",
        value: "70212",
      },
      {
        label: "Mulgama",
        value: "70117",
      },
      {
        label: "Nawalakanda",
        value: "70469",
      },
      {
        label: "NawinnaPinnakanda",
        value: "70165",
      },
      {
        label: "Niralagama",
        value: "70038",
      },
      {
        label: "Nivitigala",
        value: "70400",
      },
      {
        label: "Omalpe",
        value: "70215",
      },
      {
        label: "Opanayaka",
        value: "70080",
      },
      {
        label: "Padalangala",
        value: "70230",
      },
      {
        label: "Pallebedda",
        value: "70170",
      },
      {
        label: "Pambagolla",
        value: "70133",
      },
      {
        label: "Panamura",
        value: "70218",
      },
      {
        label: "Panapitiya",
        value: "70152",
      },
      {
        label: "Panapola",
        value: "70461",
      },
      {
        label: "Panawala",
        value: "70612",
      },
      {
        label: "Parakaduwa",
        value: "70550",
      },
      {
        label: "Pebotuwa",
        value: "70045",
      },
      {
        label: "Pelmadulla",
        value: "70070",
      },
      {
        label: "Pimbura",
        value: "70472",
      },
      {
        label: "Pinnawala",
        value: "70130",
      },
      {
        label: "Pothupitiya",
        value: "70338",
      },
      {
        label: "Rajawaka",
        value: "70116",
      },
      {
        label: "Rakwana",
        value: "70300",
      },
      {
        label: "Ranwala",
        value: "70162",
      },
      {
        label: "Rassagala",
        value: "70135",
      },
      {
        label: "Ratna Hangamuwa",
        value: "70036",
      },
      {
        label: "Ratnapura",
        value: "70000",
      },
      {
        label: "Samanalawewa",
        value: "70142",
      },
      {
        label: "Sri Palabaddala",
        value: "70004",
      },
      {
        label: "Sudagala",
        value: "70502",
      },
      {
        label: "Talakolahinna",
        value: "70101",
      },
      {
        label: "Tanjantenna",
        value: "70118",
      },
      {
        label: "Teppanawa",
        value: "70512",
      },
      {
        label: "Tunkama",
        value: "70205",
      },
      {
        label: "Udaha Hawupe",
        value: "70154",
      },
      {
        label: "Udakarawita",
        value: "70044",
      },
      {
        label: "Udaniriella",
        value: "70034",
      },
      {
        label: "Udawalawe",
        value: "70190",
      },
      {
        label: "Ullinduwawa",
        value: "70345",
      },
      {
        label: "Veddagala",
        value: "70459",
      },
      {
        label: "Vijeriya",
        value: "70348",
      },
      {
        label: "Waleboda",
        value: "70138",
      },
      {
        label: "Watapotha",
        value: "70408",
      },
      {
        label: "Waturawa",
        value: "70456",
      },
      {
        label: "Weligepola",
        value: "70104",
      },
      {
        label: "Welipathayaya",
        value: "70124",
      },
      {
        label: "Wewelwatta",
        value: "70066",
      },
      {
        label: "Wikiliya",
        value: "70114",
      },
    ],
  },
  {
    label: "Trincomalee",
    value: "Trincomalee",

    children: [
      {
        label: "Agbopura",
        value: "31304",
      },
      {
        label: "Buckmigama",
        value: "31028",
      },
      {
        label: "Chinabay",
        value: "31050",
      },
      {
        label: "Dehiwatte",
        value: "31226",
      },
      {
        label: "Echchilampattai",
        value: "31236",
      },
      {
        label: "Galmetiyawa",
        value: "31318",
      },
      {
        label: "Gomarankadawala",
        value: "31026",
      },
      {
        label: "Kaddaiparichchan",
        value: "31212",
      },
      {
        label: "Kanniya",
        value: "31032",
      },
      {
        label: "Kantalai",
        value: "31300",
      },
      {
        label: "Kantalai Sugar Factory",
        value: "31306",
      },
      {
        label: "Kiliveddy",
        value: "31220",
      },
      {
        label: "Kinniya",
        value: "31100",
      },
      {
        label: "Kuchchaveli",
        value: "31014",
      },
      {
        label: "Kumburupiddy",
        value: "31012",
      },
      {
        label: "Kurinchakemy",
        value: "31112",
      },
      {
        label: "Lankapatuna",
        value: "31234",
      },
      {
        label: "Mahadivulwewa",
        value: "31036",
      },
      {
        label: "Maharugiramam",
        value: "31106",
      },
      {
        label: "Mallikativu",
        value: "31224",
      },
      {
        label: "Mawadichenai",
        value: "31238",
      },
      {
        label: "Mullipothana",
        value: "31312",
      },
      {
        label: "Mutur",
        value: "31200",
      },
      {
        label: "Neelapola",
        value: "31228",
      },
      {
        label: "Nilaveli",
        value: "31010",
      },
      {
        label: "Pankulam",
        value: "31034",
      },
      {
        label: "Rottawewa",
        value: "31038",
      },
      {
        label: "Sampaltivu",
        value: "31006",
      },
      {
        label: "Sampur",
        value: "31216",
      },
      {
        label: "Serunuwara",
        value: "31232",
      },
      {
        label: "Seruwila",
        value: "31260",
      },
      {
        label: "Sirajnagar",
        value: "31314",
      },
      {
        label: "Somapura",
        value: "31222",
      },
      {
        label: "Tampalakamam",
        value: "31046",
      },
      {
        label: "Tiriyayi",
        value: "31016",
      },
      {
        label: "Toppur",
        value: "31250",
      },
      {
        label: "Trincomalee",
        value: "31000",
      },
      {
        label: "Vellamanal",
        value: "31053",
      },
      {
        label: "Wanela",
        value: "31308",
      },
    ],
  },
  {
    label: "Vavuniya",
    value: "Vavuniya",

    children: [
      {
        label: "Vavuniya",
        value: "43000",
      },
      {
        label: "Akkarayankulam",
        value: "43000",
      },
      {
        label: "Cheddikulam",
        value: "43000",
      },
      {
        label: "Chemamadukkulam",
        value: "43000",
      },
      {
        label: "Elephant Pass",
        value: "43000",
      },
      {
        label: "Eluthumadduval",
        value: "43000",
      },
      {
        label: "Iranai lluppaikulam",
        value: "43000",
      },
      {
        label: "Iranaitiv",
        value: "43000",
      },
      {
        label: "Kanagarayankulam",
        value: "43000",
      },
      {
        label: "Kanavil",
        value: "43000",
      },
      {
        label: "Kandavalai",
        value: "43000",
      },
      {
        label: "Kavutharimunai",
        value: "43000",
      },
      {
        label: "Kilinochchi",
        value: "43000",
      },
      {
        label: "lyakachchi",
        value: "43000",
      },
      {
        label: "Mahakachchakodiya",
        value: "43000",
      },
      {
        label: "Mamaduwa",
        value: "43000",
      },
      {
        label: "Maraiyadithakulam",
        value: "43000",
      },
      {
        label: "Mulliyan",
        value: "43000",
      },
      {
        label: "Murasumoddai",
        value: "43000",
      },
      {
        label: "Nedunkemy",
        value: "43000",
      },
      {
        label: "Neriyakulam",
        value: "43000",
      },
      {
        label: "Omanthai",
        value: "43000",
      },
      {
        label: "Palamoddai",
        value: "43000",
      },
      {
        label: "Pallai",
        value: "43000",
      },
      {
        label: "Pallavarayankaddu",
        value: "43000",
      },
      {
        label: "Pampaimadu",
        value: "43000",
      },
      {
        label: "Paranthan",
        value: "43000",
      },
      {
        label: "Pavaikulam",
        value: "43000",
      },
      {
        label: "Periyathambanai",
        value: "43000",
      },
      {
        label: "Periyaulukkulam",
        value: "43000",
      },
      {
        label: "Purakari Nallur",
        value: "43000",
      },
      {
        label: "Ramanathapuram",
        value: "43000",
      },
      {
        label: "Sasthrikulankulam",
        value: "43000",
      },
      {
        label: "Sivapuram",
        value: "43000",
      },
      {
        label: "Skanthapuram",
        value: "43000",
      },
      {
        label: "Thalayadi",
        value: "43000",
      },
      {
        label: "Tharmapuram",
        value: "43000",
      },
      {
        label: "Uruthirapuram",
        value: "43000",
      },
      {
        label: "Vaddakachchi",
        value: "43000",
      },
      {
        label: "Vannerikkulam",
        value: "43000",
      },
      {
        label: "Varikkuttiyoor",
        value: "43000",
      },
      {
        label: "Veravil",
        value: "43000",
      },
      {
        label: "Vinayagapuram",
        value: "43000",
      },
    ],
  },
];
