import React, { Suspense } from "react";
import { Router } from "react-router-dom";
import history from "./_helpers/history";
import AppLocale from "lngProvider";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import { NotificationContainer } from "react-notifications";
import Routes from "./Routes";
import { locale } from "./constants/Utilities";
import ErrorBoundary from "./modules/ErrorBoundary/ErrorBoundary";
import "assets/vendors/style";
import "styles/wieldy.less";
import "./firebase/firebase";
import "react-datepicker/dist/react-datepicker.css";
import FloatingFeedbackButton from "./components/Common/Floating/FloatingFeedbackButton";
import MobileAppAlert from "./components/Common/MobileAppAlert/MobileAppAlert";
// import Snowfall from "react-snowfall";
import { IS_ENABLED_MOBILE_APP_NOTIFY } from "./constants";
import Loader from "./components/Loading/Loader";

const NextApp = () => (
  <Suspense fallback={<Loader />}>
    <Router history={history}>
      <ErrorBoundary>
        <LocaleProvider locale={AppLocale[locale.locale].antd}>
          <IntlProvider
            locale={AppLocale[locale.locale].locale}
            messages={AppLocale[locale.locale].messages}
          >
            <Routes />
            <FloatingFeedbackButton />
            <NotificationContainer enterTimeout={300} leaveTimeout={300} />
            {IS_ENABLED_MOBILE_APP_NOTIFY && <MobileAppAlert />}
            {/* <Snowfall /> */}
          </IntlProvider>
        </LocaleProvider>
      </ErrorBoundary>
    </Router>
  </Suspense>
);

export default NextApp;
