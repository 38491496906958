import React from "react";
import { auth } from "../firebase/firebase";
import { NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import history from "./../_helpers/history";
import { GetNotificationsCount } from "../firebase/functions";
import { clearHTTPClient } from "../util/HTTPClient";
import jwt_decode from "jwt-decode";
import {
  ERROR_TOASTER_TIMEOUT,
  IS_ONSHOP,
  SUCCESS_TOASTER_TIMEOUT,
} from "../constants";

export const ResetIdToken = () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      if (!user) return reject(false);
      user
        .getIdToken(true)
        .then(() => resolve(true))
        .catch((err) => reject(err));
    });
  });
};

export const GetCurrentUserID = async () => {
  return new Promise((resolve, _reject) => {
    auth.onAuthStateChanged((user) => {
      // if (!user) return resolve(null);
      // resolve(user.uid);
      if (!!user) {
        resolve(user.uid);
      } else {
        return resolve(null);
      }
    });
  });
};

export const GetCurrentUser = async () => {
  return new Promise((resolve, _reject) => {
    auth.onAuthStateChanged((user) => {
      // if (!user) return resolve(null);
      // resolve(user.uid);
      if (!!user) {
        resolve(user);
      } else {
        return resolve(null);
      }
    });
  });
};

export const GetIdToken = async () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      if (!!user) {
        user
          .getIdToken()
          .then((idToken) => {
            resolve(idToken);
          })
          .catch((error) => {
            
            reject(error);
          });
      } else {
        resolve(null);
      }
    });
  });
};

export const checkUserRecentLogged = async () => {
  return new Promise(async (resolve, reject) => {
    const idToken = await GetIdToken();
    const decordedToken = jwt_decode(idToken) || {};

    if (!decordedToken.auth_time) return resolve(false);

    const loggedTime = new Date(+`${decordedToken.auth_time}000`).getTime();
    const currentTime = new Date().getTime();
    const timeDiffMs = currentTime - loggedTime;

    if (timeDiffMs >= 0 && timeDiffMs <= 60 * 1000) return resolve(true); // 1 Minute
    return resolve(false);
  });
};

export const PasswordReset = async (new_password) => {
  return new Promise(async (resolve, reject) => {
    const user = await GetCurrentUser();
    if (!user) {
      NotificationManager.info(
        <IntlMessages id="notification.service.auth.passwordReset.content" />,
        <IntlMessages id="notification.service.auth.passwordReset.title" />,
        SUCCESS_TOASTER_TIMEOUT
      );
      // alert('Please Login');
      return resolve(user);
    }

    user
      .updatePassword(new_password)
      .then(() => {
        
        NotificationManager.success(
          <IntlMessages id="notification.service.auth.passwordReset.success.content" />,
          <IntlMessages id="notification.service.auth.passwordReset.success.title" />
        );
        return resolve();
      })
      .catch((error) => {
        if ((error.code = "auth/requires-recent-login")) {
          NotificationManager.error(
            <IntlMessages id="notification.service.auth.passwordReset.fail.content" />,
            <IntlMessages id="notification.service.auth.passwordReset.fail.title" />,
            ERROR_TOASTER_TIMEOUT
          );
          // wait 3 seconds
          setTimeout(function () {
            auth
              .signOut()
              .then(() => {
                localStorage.removeItem("user_id");
                localStorage.removeItem("user");
                history.push("/login?redirect_to=/account");
              })
              .catch((error) => console.error(error));
          }, 3000);
        } else {
          alert(error.message);
        }
      });
  });
};

//sign-out user
export const signOut = async (redirectToLoginPage = false) => {
  return new Promise((resolve, reject) => {
    auth
      .signOut()
      .then((authUser) => {
        GetNotificationsCount((counts, NotificationsListner) => {
          NotificationsListner(); // Need to call when logout
        });
        localStorage.removeItem("user_id");
        localStorage.removeItem("user");
        NotificationManager.success(
          <IntlMessages
            id={
              IS_ONSHOP
                ? "notification.service.auth.onshop.signout.success.content"
                : "notification.service.auth.signout.success.content"
            }
          />,
          <IntlMessages
            id={"notification.service.auth.signout.success.title"}
          />,
          SUCCESS_TOASTER_TIMEOUT
        );
        //crear https instance
        // history.push("/")
        window.location = redirectToLoginPage ? "/login" : "/";
        clearHTTPClient();
      })
      .catch((error) => error);
  });
};

//verify-reset-password
export const verifyResetPassword = async (code, new_password) => {
  try {
    await auth.confirmPasswordReset(code, new_password);
    return "Password Reset Success";
  } catch (err) {
    throw err.message;
  }
};

export const applyActionCode = async (code) => {
  try {
    await auth.applyActionCode(code);
    return "Success";
  } catch (err) {
    throw err.message;
  }
};

export const verifyPasswordResetCode = async (code) => {
  try {
    await auth.verifyPasswordResetCode(code);
    return "Valid Code";
  } catch (err) {
    throw err.message;
  }
};

export const checkActionCode = async (code) => {
  try {
    auth.updateCurrentUser();
    await auth.checkActionCode(code);
    return "Valid Code";
  } catch (err) {
    throw err.message;
  }
};

export const updateProfile = async (name, user_image_url) => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      user
        .updateProfile({
          displayName: name,
          photoURL: user_image_url,
        })
        .then(
          async () => {
            await ResetIdToken();
            resolve("Success");
          },
          (err) => reject(err)
        );
    });
  });
};

export const checkSession = async () => {
  return new Promise((resolve, reject) => {
    let listener = auth.onAuthStateChanged((user) => {
      if (user) {
        listener();
        // reject()
        resolve(user);
      } else {
        listener();
        reject();
      }
    });
  });
};

//sign-out user
export const signOutWithRedirect = async (redirectPath) => {
  return new Promise((resolve, reject) => {
    auth
      .signOut()
      .then(() => {
        localStorage.removeItem("user_id");
        localStorage.removeItem("user");
        history.push(redirectPath);
      })
      .catch((error) => console.error(error));
  });
};
