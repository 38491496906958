import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

const { Header } = Layout;

function CheckoutHeader() {
  return (
    <div className="bckgrnd">
      <Header className="gx-header-horizontal-main pb-3 pt-3">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <Link
              to="/"
              className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
            >
              <img
                alt="logo"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/w-logo.png`)}
                className="imgggggg"
                style={{ maxWidth: 180 }}
              />
            </Link>
            <Link
              to="/"
              className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
            >
              <img
                alt="logo"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/logo.png`)}
                style={{ maxWidth: 180 }}
              />
            </Link>
          </div>
        </div>
      </Header>
    </div>
  );
}

export default CheckoutHeader;
