import React, { Component } from "react";
import { Modal, Button } from "antd";
// import { connect, Provider } from "react-redux";
import LocationPicker from "./LocationPicker";

class LocationPickerModal extends Component {
  submitHandler = () => {
    const {
      operation,
      toggleLocationPickerModalHandler,
      item,
      productPriceObj,
      productQty,
      buyNowHandler,
      addCartHandler,
      isCart,
    } = this.props;
    // debugger;
    if (!isCart) {
      operation === "BUY_NOW"
        ? buyNowHandler(item, productPriceObj, productQty)
        : addCartHandler(item, productPriceObj, productQty);
    }
    // handler(item, productPriceObj, productQty);
    toggleLocationPickerModalHandler();
  };
  render() {
    const {
      visible,
      // loading,
      locationOnChangeHandler,
      shippingCost,
      toggleLocationPickerModalHandler,
    } = this.props;
    return (
      <Modal
        visible={visible}
        title="Please select the location"
        // onOk={toggleLocationPickerModalHandler}
        onClick={this.submitHandler}
        onCancel={toggleLocationPickerModalHandler}
        footer={[
          <Button key="back" onClick={toggleLocationPickerModalHandler}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={shippingCost.pending}
            // onClick={toggleLocationPickerModalHandler}
            onClick={this.submitHandler}
          >
            Ok
          </Button>,
        ]}
      >
        <>
          <div className="mb-2">Location :</div>
          <div>
            <LocationPicker locationOnChangeHandler={locationOnChangeHandler} />
          </div>
        </>
      </Modal>
    );
  }
}

export default LocationPickerModal;
