import React from "react";
import { Layout, Row, Col, Card } from "antd";
import HorizontalDark from "../containers/Topbar/HorizontalDark";
import MainNavBar from "../components/MainNavBar/MainNavBar";
import TopBar from "../components/TopBar/TopBar";
import Sidebar from "../containers/Sidebar";
import CustomerServiceSidebar from "../containers/Sidebar/CustomerServiceSidebar/CustomerServiceSidebar";
import SiteFooter from "../components/Buyer/FooterComponent/SiteFooter";
import { TRADESBARN } from "../constants";

const { Content } = Layout;

const CustomerServiceLayout = (ViewComponent) => {
  return class extends React.Component {
    render() {
      return (
        <Layout className="gx-app-layout">
          <Sidebar />
          <Layout>
            <TopBar />
            <HorizontalDark />
            <MainNavBar />
            <Content>
              <div className="gx-container mt-4">
                <Card
                  className="gx-card adrs-top-pdng"
                  style={{ backgroundColor: "rgb(250, 250, 250)" }}
                >
                  <Row className="mt-2">
                    { process.env.REACT_APP_BUSINESS_NAME === TRADESBARN ? 
                      <>
                        <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                          <CustomerServiceSidebar />
                        </Col>
                        <Col xl={18} lg={18} md={24} sm={24} xs={24}>
                          <ViewComponent />
                        </Col>
                      </>
                      :
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <ViewComponent />
                        </Col>
                    }
                  </Row>
                </Card>
              </div>
              <SiteFooter />
            </Content>
          </Layout>
        </Layout>
      );
    }
  };
};

export default CustomerServiceLayout;
